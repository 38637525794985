const Menu = [
  {
    path: '/admins',
    icon: 'ion-md-person',
    title: 'Admin',
    roles: ['super_admin'],
  },
  // {
  //   path: "/admin_menu",
  //   icon: "fa fa-user",
  //   title: "Admin Menu",
  //   children: [
  //     { path: "/admins", title: "Admins" },
  //     { path: "/admins/settings_app", title: "Settings App" },
  // children: [
  //   { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
  //     children: [
  //       { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
  //       { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
  //     ]
  //   },
  //   { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
  //   { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
  // ]

  // { path: '/menu/menu-1-2', title: 'Menu 1.2' },
  // { path: '/menu/menu-1-3', title: 'Menu 1.3' },
  //   ],
  // },
  // {
  //   path: '/users',
  //   icon: 'ion-md-people',
  //   title: 'Users',
  //   roles: ['super_admin', 'admin'],
  // },
  {
    path: '/loyalty',
    icon: 'ion-md-ribbon',
    title: 'Loyalty',
    roles: ['super_admin', 'admin'],
    children: [
      { path: '/loyalty/partners', title: 'Loyalty Partners' },
      { path: '/loyalty/categories', title: 'Loyalty Categories' },
      { path: '/loyalty/privileges', title: 'Loyalty Privileges' },
    ],
  },
  {
    path: '/company',
    icon: 'ion-md-business',
    title: 'Insurance Company',
    roles: ['super_admin', 'admin'],
  },
  {
    path: '/virtual_card',
    icon: 'ion-md-card',
    title: 'Virtual Card',
    roles: ['super_admin', 'admin'],
  },
  {
    path: '/app_config',
    icon: 'ion-md-construct',
    title: 'Menu Config',
    roles: ['super_admin', 'admin'],
    children: [
      { path: '/app_dashboard_config', title: 'App Dashboard Config' },
      { path: '/loyalty_config', title: 'Loyalty Config' },
      { path: '/icon_config', title: 'Icon Config' },
      { path: '/telemedicine_config', title: 'Telemedicine Config' },
      { path: '/report_problem_config', title: 'Report Problem Config' },
      {
        path: '/other_config',
        title: 'Other',
        children: [
          {
            path: '/other/auth_menu_banner_config',
            title: 'Auth banner (image)',
          },
          {
            path: '/other/success_banner_config',
            title: 'Success banner (image)',
          },
          {
            path: '/other/consent_config',
            title: 'Consent',
          },
          {
            path: '/other/term_and_condition_config',
            title: 'Term And Condition',
          },
          {
            path: '/other/claim_motor_solution',
            title: 'Claim Motor Solution',
          },
        ],
      },
    ],
  },
  {
    path: '/special_event',
    icon: 'ion-md-football',
    title: 'Special Event',
    roles: ['super_admin', 'admin'],
  },
  // {
  //   path: '/notification',
  //   icon: 'fas fa-exclamation-circle',
  //   title: 'Notification',
  //   roles: ['super_admin', 'admin'],
  //   children: [
  //     { path: '/notification/config', title: 'Console' },
  //     { path: '/notification/history', title: 'History' },
  //   ],
  // },
  {
    path: '/notification_schedule',
    icon: 'ion-md-notifications',
    title: 'Notification Schedule',
    roles: ['super_admin', 'admin'],
    children: [
      { path: '/notification_schedule/config', title: 'Console' },
      { path: '/notification_schedule/title', title: 'Add List' },
      { path: '/notification_schedule/category', title: 'Add Category' },
    ],
  },
  // {
  //   path: '/issue',
  //   icon: 'ion-md-alert',
  //   title: 'Issue',
  //   roles: ['super_admin', 'admin'],
  // },
  {
    path: '/report',
    icon: 'ion-ios-podium',
    title: 'Report',
    roles: ['super_admin', 'admin'],
    children: [
      { path: '/report/user_report', title: 'User Report' },
      { path: '/report/royalty_report', title: 'Royalty Report' },
      { path: '/report/notification_report', title: 'Notification Report' },
    ]
  },
]

export default Menu
