import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class SpecialEventStore extends ModelStore {
  constructor() {
    super('specialEventStore', {
      all: [],
      current: [],
      configs: [],
    })
  }

  fetch = async () => {
    const res = await tqm24Server.get(`/special_events`)
    this.$setState('all', res.data.data.specialEvents)
  }

  fetchById = async (id) => {
    const res = await tqm24Server.get(`/special_events/${id}`)
    this.$setState('current', res.data.data.specialEvent)
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get(`/app_configurations/configs`)
    this.$setState('configs', res.data.data)
  }

  create = async (params) => {
    await tqm24Server.post(`/special_events`, params)
  }

  update = async (id, params) => {
    const res = await tqm24Server.put(`/special_events/${id}`, params)
    this.$setState('current', res.data.data.specialEvents)
  }

  delete = async (id) => {
    await tqm24Server.delete(`/special_events/${id}`)
  }
}

export default new SpecialEventStore()
