import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Field } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { Image } from '../../../../components/index.js'
import { withHooks } from '../../../../enhancers/index.js'
import ModalShowAdsBanner from './ModalShowAdsBanner'

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

const DashboardCardAdd = styled.div`
  width: 388px;
  height: 219px;
  background: #cae6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  ${(props) =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
`

const CustomImage = styled(Image)`
  width: 388px;
  height: 219px;
  align-self: center;
  > section {
    width: 100%;
    height: 100%;
    > div {
      border: unset !important ;
      > i {
        margin: unset;
      }
      > div {
        > img {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: unset !important ;
          object-fit: cover;
        }
      }
    }
  }
`

const AdsBannerConfig = (props) => (
  <MainLayout>
    <DashboardCardAdd readOnly={props.readOnly} onClick={props.setOpenTrue}>
      <Field
        name={`${props.name}[${props.index}].imageFile`}
        component={CustomImage}
        readOnly
      />

      <ModalShowAdsBanner
        open={props.open}
        setIsOpenFalse={props.setOpenFalse}
        name={`${props.name}[${props.index}]`}
        setFieldValue={props.form.setFieldValue}
        item={props.item}
        remove={props.remove}
        index={props.index}
        configs={props.configs}
        setOpen={props.setOpen}
        move={props.move}
        optionLengthArray={props.optionLengthArray || []}
      />
    </DashboardCardAdd>
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const setOpenFalse = useCallback(() => {
      setOpen(false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrue = useCallback(() => {
      setOpen(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const optionLengthArray = useMemo(() => {
      const newOption = []
      get(props, 'form.values.dashboard.adsBanner.items', []).forEach(
        (element, index) => {
          newOption.push({ label: index + 1, value: index })
        }
      )
      return newOption
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values.dashboard.adsBanner.items])

    return {
      loading,
      setLoading,
      setOpenFalse,
      setOpenTrue,
      open,
      setOpen,
      optionLengthArray,
    }
  })
)

export default enhancer(AdsBannerConfig)
