import styled from '@emotion/styled'
import { FieldArray, Form, withFormik } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import { Button, Loading, TitlePage } from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import CategoryDetail from './category/CategoryDetail'
import ModalAddCategory from './category/ModalAddCategory'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const MainCategoryLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const SubCategoryLayout = styled.div`
  width: 414px;
  min-height: 165px;
  padding: 18px 25px 46px;
  display: grid;
  grid-template-columns: 104px 104px 104px 104px;
  grid-auto-rows: 110px 110px 110px 110px;
  /* grid-column-gap: 30px;
  grid-row-gap: 27px; */
`
const AddCircle = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00aeef;
  opacity: 0.8;
  justify-self: center;
  margin-top: 6px;
`
const IconPlusCircle = styled.i`
  font-size: 35px;
  color: white;
`

const TitleText = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const AppDashboardConfig = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage text="App Config" textSmall="Can view and edit app config." />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Loyalty Config</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <TitleText>Category (1:1)</TitleText>
          <MainCategoryLayout>
            <SubCategoryLayout>
              <FieldArray
                name="loyalties.category.items"
                render={(FieldArrayProps) => (
                  <>
                    {get(
                      FieldArrayProps,
                      'form.values.loyalties.category.items',
                      []
                    ).map((item, index) => (
                      <CategoryDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.open}
                        setOpenFalseAdd={props.setOpenFalse}
                        key={index}
                        configs={props.configs}
                      />
                    ))}
                    <ModalAddCategory
                      open={props.openMenu}
                      setIsOpenFalse={props.setOpenFalseMenu}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
              {!props.isEdit && (
                <AddCircle onClick={props.setOpenTrueMenu}>
                  <IconPlusCircle className="fas fa-plus" />
                </AddCircle>
              )}
            </SubCategoryLayout>
          </MainCategoryLayout>
        </PanelBody>
      </Panel>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
    </MainLayout>
  </Loading>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.loyaltyConfigStore.fetch,
    fetchConfig: stores.loyaltyConfigStore.fetchConfig,
    configs: stores.loyaltyConfigStore.configs,
    current: stores.loyaltyConfigStore.current,
    update: stores.loyaltyConfigStore.update,
  })),
  withState('loading', 'setLoading', true),
  withFormik({
    displayName: 'editloyaltyConfig',
    validateOnBlur: false,
    validateOnChange: false,

    handleSubmit: async (values, props) => {
      props.props.setLoading(true)

      const newMenus = []
      get(values, 'loyalties.category.items', []).forEach((item) => {
        if (item.iconUrl) {
          newMenus.push(item)
        } else {
          newMenus.push({ ...item, iconUrl: undefined })
        }
      })

      await props.props.update({
        loyalties: {
          category: { items: newMenus },
        },
      })
      props.props.setLoading(false)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    // const [loading, setLoading] = useState(true)
    const [openMenu, setOpenMenu] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      const promiseMenus = []

      const getIamge = async () => {
        await get(props, 'current.loyalties.category.items', []).forEach(
          (item, index) => {
            promiseMenus.push(
              fetch(item.iconUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue(
                    `loyalties.category.items[${index}].iconFile`,
                    result
                  )
                })
            )
          }
        )

        await Promise.all(promiseMenus)

        props.setLoading(false)
      }
      getIamge()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current])

    useEffect(() => {
      props.setLoading(true)
      props.fetch()
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseMenu = useCallback(() => {
      setOpenMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueMenu = useCallback(() => {
      setOpenMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return {
      setOpenFalseMenu,
      setOpenTrueMenu,
      openMenu,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
    }
  })
)

export default enhancer(AppDashboardConfig)
