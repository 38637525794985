import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import Dropzone from 'react-dropzone'
import { compose } from 'recompose'
import { withHooks } from '../../../enhancers'

const MainLayout = styled.div`
  position: relative;
  ${(props) =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
`
const LayoutImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
  :hover {
    opacity: 0.4;
  }
`

const Drop = styled.div`
  border-color: #6c757d !important;
  ${(props) =>
    props.valid &&
    css`
      border: 1px solid #dc3545 !important;
    `}
`

const LayoutIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Image = (props) => (
  <MainLayout readOnly={props.readOnly} className={props.className}>
    {/* {props.field.value && !props.readOnly && (
      <button
        onClick={props.onClear}
        className="btn btn-danger btn-icon btn-circle btn-sm m-r-2 "
        style={{ position: 'absolute', left: '115px', top: '0px' }}
      >
        <i className="fa fa-times" />
      </button>
    )} */}

    <Dropzone onDrop={props.onDrop} id="drop-zone">
      {({ getRootProps, getInputProps }) => (
        <section>
          <Drop
            style={{ cursor: 'pointer' }}
            className="w-100 h-100 rounded border border-secondary bg-grey-transparent-2 text-center p-auto"
            data-cy="drag-and-drop"
            valid={props.valid}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <LayoutIcon>
              <i className="fa fa-upload fa-3x m-t-10" />
              <div className="m-t-15">Choose File</div>
            </LayoutIcon>
            {props.field.value && (
              <LayoutImage>
                <img
                  src={props.value}
                  className="w-100 h-100 rounded border border-secondary bg-grey-transparent-2 text-center p-auto"
                  alt=""
                />
              </LayoutImage>
            )}
          </Drop>
        </section>
      )}
    </Dropzone>
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    let testValue
    const onDrop = (acceptedFiles) => {
      testValue = acceptedFiles
      props.form.setFieldValue(
        props.field.name,
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      )

      if (!props.field.value) {
        props.push()
        props.form.setFieldValue(`${props.nameFromIndex}.action`, 'create')
      } else {
        props.form.setFieldValue(`${props.nameFromIndex}.action`, 'update')
      }
      props.handleSubmit()
    }

    const onClear = () => {
      props.form.setFieldValue(props.field.name, '')
    }

    let value = useMemo(() => {
      if (props.field.value) {
        if (props.field.value.preview) {
          value = props.field.value.preview
        } else {
          value = props.field.value
          props.form.setFieldValue(props.field.name, props.field.value)
        }
      }
      return value
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.field.value])

    return {
      onDrop,
      onClear,
      value,
      testValue,
      valid:
        get(props.form.errors, props.field.name) && props.form.submitCount > 0,
    }
  })
)

export default enhancer(Image)
