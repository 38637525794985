import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Button } from 'reactstrap'
import { compose } from 'recompose'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../../enhancers/index.js'

const HeaderMainLayout = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`

const ButtonNew = styled(Button)`
  width: 60px;
`

const Notification = (props) => (
  <Panel>
    <PanelHeader noButton={true}>
      <HeaderMainLayout>
        <ButtonNew color="info" onClick={props.goToNewPage}>
          New
        </ButtonNew>
        <TextHeader>Notification</TextHeader>
      </HeaderMainLayout>
    </PanelHeader>
    <ReactTable
      filterable
      data={props.all}
      columns={props.tableColumns}
      defaultPageSize={10}
      className="-highlight"
      getTdProps={props.onRowClick}
    />
  </Panel>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.notificationStore.fetch,
    all: stores.notificationStore.all,
  })),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const tableColumns = [
      {
        Header: 'Message',
        accessor: 'message',
      },
    ]

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToNewPage = useCallback(() => {
      props.history.push('/notification/config/new')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: (e) => {
          if (rowInfo) {
            props.history.push(`/notification/config/${rowInfo.original.id}`)
          }
        },
      }
    }
    return {
      loading,
      setLoading,
      onRowClick,
      tableColumns,
      goToNewPage,
    }
  })
)

export default enhancer(Notification)
