import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { compose } from 'recompose'
// import { ValidateSchema } from '../show/index'
import * as yup from 'yup'
import {
  Button,
  DateTime,
  ErrorMessageAlert,
  Image,
  Input,
  Textarea
} from '../../components/index.js'
import { withHooks, withStores } from '../../enhancers/index.js'

const CustomImage = styled(Image)`
  width: 138px;
  height: 106px;
  margin-bottom: 10px;
  align-self: center;
  > section {
    width: 100% !important;
    height: 100% !important;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > i {
        margin: unset;
      }

      > img {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`
const ImageLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`

const InputLayout = styled.div`
  display: flex;
  justify-items: center;
`
const InputTextAreaLayout = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`

const Caption = styled.div`
  min-width: 120px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`
const RequiredStar = styled.div`
  color: red;
`

const CustomButton = styled(Button)`
  width: 64px;
`

export const SpecialEventModal = (props) => (
  <Modal isOpen={props.open}>
    <ModalHeader toggle={props.setIsOpenFalse}>Special Event</ModalHeader>
    <Form onSubmit={props.handleSubmit}>
      <ModalBody>
        <ImageLayout>
          <Field name="bannerFile" component={CustomImage} />
          (4:3)
        </ImageLayout>
        <InputLayout>
          <Caption>
            Title
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="title" component={Input} required />
        </InputLayout>
        <InputTextAreaLayout>
          <Caption>
            Description
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="description" component={Textarea} />
        </InputTextAreaLayout>
        <InputLayout>
          <Caption>
            Url
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="url" component={Input} required />
        </InputLayout>
        <InputLayout>
          <Caption>
            Expire Date
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="expiredAt" component={DateTime} />
        </InputLayout>
        <InputLayout>
          <Caption>
            Start Publish
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="startPublishAt" component={DateTime} />
        </InputLayout>
        <InputLayout>
          <Caption>
            End Publish
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="endPublishAt" component={DateTime} />
        </InputLayout>
      </ModalBody>
      <ModalFooter>
        {props.isEdit && (
          <>
            <CustomButton
              outline
              color="danger"
              type="button"
              onClick={props.setConfirmDeleteToCon}
              loading={props.isSubmitting}
            >
              Delete
            </CustomButton>
            <CustomButton
              color="primary"
              type="button"
              onClick={props.setConfirmUpdateToCon}
              loading={props.isSubmitting}
            >
              Save
            </CustomButton>
          </>
        )}
        {!props.isEdit && (
          <CustomButton
            color="primary"
            type="submit"
            loading={props.isSubmitting}
          >
            Save
          </CustomButton>
        )}
        <CustomButton color="secondary" onClick={props.setIsOpenFalse}>
          Cancel
        </CustomButton>
      </ModalFooter>
    </Form>
    <ErrorMessageAlert
      open={
        !props.isSubmitting &&
        !props.isValid &&
        !isEmpty(props.errors) &&
        !props.isValidating
      }
      message={props.errors}
    />
    {props.confirmUpdate && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการบันทึกใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmUpdate}
        onCancel={props.setConfirmUpdateToCon}
      />
    )}
    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการลบใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmDelete}
        onCancel={props.setConfirmDeleteToCon}
      />
    )}
  </Modal>
)

export const ValidateSchema = yup.object().shape({
  bannerFile: yup.mixed().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  url: yup.string().required(),
  expiredAt: yup.date().required(),
  startPublishAt: yup.date().required(),
  endPublishAt: yup.date().required(),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    create: stores.specialEventStore.create,
    fetch: stores.specialEventStore.fetch,
  })),
  withFormik({
    displayName: 'newSpecialEvent',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.create({ specialEvent: values })
      await props.props.fetch()
      await props.props.setIsOpenFalse()
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(SpecialEventModal)
