import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Field } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { compose } from 'recompose'
import { Button, Image, Input } from '../../../components/index.js'
import { withHooks } from '../../../enhancers/index.js'

const MainLayout = styled.div`
  display: flex;
  padding: 6px;
  ${(props) =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
  align-items: center;

  > div:nth-of-type(2) {
    margin: unset;
  }
`

const CustomImage = styled(Image)`
  width: 64px;
  height: 64px;
  margin-right: 6px;

  align-self: center;
  > section {
    width: 100%;
    height: 100%;
    > div {
      border-radius: 10px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: unset !important ;
      > div {
        > div {
          display: none;
        }
        > i {
          margin: unset;
        }
        > img {
          border-radius: 10px !important;
          width: 100% !important;
          height: 100% !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: white !important;
          border: unset !important ;
        }
      }
    }
  }
`
const InputLayout = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`
const Caption = styled.div`
  min-width: 120px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 3px;
`
const RequiredStar = styled.div`
  color: red;
`
const CustomInput = styled(Input)`
  margin-bottom: unset;
  margin-right: 6px;
`
const CustomButton = styled(Button)`
  margin-right: 6px;
  margin-top: 12px;
`
const IconButton = styled.i``

const AppDashboardConfig = (props) => (
  <MainLayout>
    <Field
      name={`${props.name}[${props.index}].imageFile`}
      component={CustomImage}
    />
    <InputLayout>
      <Caption>
        Symbol
        {props.caption}
        <RequiredStar>*</RequiredStar>
      </Caption>
      <Field
        name={`${props.name}[${props.index}].symbol`}
        component={CustomInput}
      />
    </InputLayout>
    {get(props, 'form.values.iconCars', []).length === props.index + 1 && (
      <CustomButton
        color="success"
        type="button"
        onClick={() => {
          props.push({})
        }}
      >
        <IconButton className="ion-md-add" />
      </CustomButton>
    )}
    {get(props, 'form.values.iconCars', []).length !== 1 && (
      <CustomButton
        color="danger"
        type="button"
        onClick={props.setConfirmDeleteToCon}
      >
        <IconButton className="ion-md-trash" />
      </CustomButton>
    )}
    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการลบใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmDelete}
        onCancel={props.setConfirmDeleteToCon}
      />
    )}
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const setConfirmDeleteToCon = useCallback(() => {
      setConfirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onClickconfirmDelete = useCallback(async () => {
      props.remove(props.index)
      setConfirmDelete(false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.companyId])

    return {
      loading,
      setLoading,
      confirmDelete,
      setConfirmDeleteToCon,
      onClickconfirmDelete,
    }
  })
)

export default enhancer(AppDashboardConfig)
