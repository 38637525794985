import deepmerge from 'deepmerge'
import { get, set } from 'lodash'

export const SET_BASE_STORE = '@@SET_BASE_STORE@@'

export const setStores = (updatedStates) => {
  const stores = require('./index.js').default
  stores.dispatch({
    type: SET_BASE_STORE,
    payload: updatedStates,
  })
}

export const getStores = (name) => {
  const stores = require('./index.js').default
  const states = stores.getState()
  return states.model
}

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

export const reducer = (states = {}, action) => {
  switch (action.type) {
    case SET_BASE_STORE:
      return deepmerge(states, action.payload, { arrayMerge: overwriteMerge })
    default:
      return states
  }
}

class ModelStore {
  constructor(storeName, initialState = {}) {
    this.storeName = storeName
    this.initialState = initialState

    this.$setStates({
      ...this.initialState,
    })
  }

  $setStores = setStores
  $getStores = getStores

  $setStates = (values) => {
    this.$setStores({
      [this.storeName]: values,
    })
  }

  $setState = (fieldName, value) => {
    this.$setStates({
      [fieldName]: value,
    })
  }

  $getStates = (defaultValue) => {
    return get(this.$getStores(), this.storeName, defaultValue)
  }

  $getState = (fieldName, defaultValue) => {
    return get(this.$getStates(), fieldName, defaultValue)
  }

  $set = set
  $get = get
}

export default ModelStore
