import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import {
  Checkbox,
  Input,
  MaterialTable,
  TitlePage
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
// import DeviceDetail from './DeviceDetail'

const MainLayout = styled.form``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
`

const CustomInput = styled(Input)`
  width: 200px;
`
// const DetailLayout = styled.div`
//   display: flex;
//   margin-top: 16px;
// `
// const Caption = styled.div`
//   min-width: 150px;
//   font-size: 16px;
//   color: #666666;
//   font-weight: bold;
// `
// const DetailSubLayout = styled.div`
//   margin-top: 4px;
// `

const CustomCheckBox = styled(Checkbox)`
  width: 60%;
  > div {
    display: flex;
    align-items: center;
    padding-top: 0px !important;
  }
`
const RowLayout = styled.div`
  display: flex;
`
const User = (props) => (
  <>
    <TitlePage text="User" textSmall="Can view user details" />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>User</TextHeader>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <Field
            name="citizenId"
            component={CustomInput}
            caption="Citizen Id"
            readOnly
          />
          <RowLayout>
            <Field
              name="firstName"
              component={CustomInput}
              caption="Name"
              readOnly
            />
            <Field name="lastName" component={CustomInput} readOnly />
          </RowLayout>
          <RowLayout>
            <Field
              name="phoneNumber"
              component={CustomInput}
              caption="Phone Number"
              readOnly
            />
            <Field
              name="email"
              component={CustomInput}
              caption="Email"
              readOnly
            />
          </RowLayout>
          <Field
            name="address"
            component={CustomInput}
            caption="Address"
            readOnly
          />
          <RowLayout>
            <Field
              name="socialName"
              component={CustomInput}
              caption="Social Name"
              readOnly
            />
            <Field
              name="tqmUserId"
              component={CustomInput}
              caption="Tqm User Id"
              readOnly
            />
          </RowLayout>
          <Field
            name="isCustomerVerify"
            component={CustomCheckBox}
            caption="Is Verify"
            label={`${get(props, 'values.isCustomerVerify', '')}`}
            readOnly
          />
          {/* <DetailLayout>
          <Caption>Devices</Caption>
          <DetailSubLayout>
            {get(props, 'values.devises', []).map((item, index) => (
              <DeviceDetail item={item} index={index} />
            ))}
          </DetailSubLayout>
        </DetailLayout> */}

          <MaterialTable
            title="Devices"
            columns={[{ title: 'User Agent', field: 'userAgent' }, { title: 'Mobile Version', field: 'mobileVersion' }, { title: 'App Version', field: 'appVersion' }, { title: 'App Build Number', field: 'appBuildNumber' }]}
            data={get(props, 'values.devises', [])}
            options={{
              headerStyle: {
                backgroundColor: '#cae6ff',
              },
            }}
          />
        </PanelBody>
      </Panel>
    </MainLayout>
  </>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchById: stores.userStore.fetchById,
    current: stores.userStore.current,
  })),
  withFormik({
    displayName: 'editUser',
    validateOnBlur: false,
    validateOnChange: false,

    handleSubmit: async (values, props) => {},
    mapPropsToValues: (props) => {
      return { ...props.current }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(true)

    useEffect(() => {
      props.fetchById(props.match.params.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIsEditFalse = useCallback(() => {
      setIsEdit(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit])

    const setIsEditTrue = useCallback(() => {
      setIsEdit(true)
      props.resetForm()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit])

    return {
      loading,
      setLoading,
      isEdit,
      setIsEdit,
      setIsEditFalse,
      setIsEditTrue,
    }
  })
)

export default enhancer(User)
