import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { compose } from 'recompose'
import { withHooks } from '../../../enhancers'

const ButtonCustom = styled(Button)`
  transition: background-color 1s;
`

const ButtonStatus = (props) => (
  <ButtonCustom
    onMouseOver={props.over}
    onMouseOut={props.out}
    onClick={props.changeValue}
    color={props.status ? 'success' : 'secondary'}
  >
    {props.status ? 'online' : 'offline'}
  </ButtonCustom>
)
const enhancer = compose(
  withHooks((props) => {
    const [status, setStatus] = useState(false)

    useEffect(() => {
      setStatus(props.field.value)
    }, [props.field.value])

    const over = useCallback(() => {
      setStatus(!status)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    const out = useCallback(() => {
      setStatus(props.field.value)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.field.value])

    const changeValue = useCallback(async () => {
      await props.form.setFieldValue(props.field.name, status)
      await props.form.handleSubmit()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, props])

    return { status, out, over, changeValue }
  })
)

export default enhancer(ButtonStatus)
