import { withFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../enhancers/index.js'
import { SpecialEventModal, ValidateSchema } from './ModalAdd'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.specialEventStore.fetch,
    fetchById: stores.specialEventStore.fetchById,
    update: stores.specialEventStore.update,
    current: stores.specialEventStore.current,
    delete: stores.specialEventStore.delete,
  })),
  withFormik({
    displayName: 'editSpecialEvent',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.specialEventId, {
        specialEvent: values,
      })
      await props.props.fetch()
      await props.props.setIsOpenFalse()
    },
    mapPropsToValues: (props) => {
      return { ...props.current }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    useEffect(() => {
      const promise = []
      if (props.open && props.current) {
        const getIamge = async () => {
          promise.push(
            fetch(props.current.bannerUrl)
              .then((r) => r.blob())
              .then(
                (blobFile) =>
                  new File([blobFile], 'fileFromServer', {
                    type: 'image/png',
                  })
              )
              .then((result) =>
                Object.assign(result, {
                  preview: URL.createObjectURL(result),
                })
              )
              .then((result) => {
                props.setFieldValue('bannerFile', result)
              })
          )

          await Promise.all(promise)
        }
        getIamge()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current, props.open])

    useEffect(() => {
      if (props.open) {
        props.fetchById(props.specialEventId)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.specialEventId, props.open])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    const setConfirmDeleteToCon = useCallback(() => {
      setConfirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onClickconfirmDelete = useCallback(async () => {
      await props.delete(props.specialEventId)
      await props.fetch()
      await props.setIsOpenFalse()
      setConfirmDelete(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.specialEventId])

    return {
      loading,
      setLoading,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      isEdit: true,
      setConfirmDeleteToCon,
      onClickconfirmDelete,
      confirmDelete,
    }
  })
)

export default enhancer(SpecialEventModal)
