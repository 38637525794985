import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { compose } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  ErrorMessageAlert,
  Image,
  Input
} from '../../../../components/index.js'
import { withHooks } from '../../../../enhancers/index.js'
import Action from '../../Action'
import { CustomSelect, HeaderLayout } from '../adsBanner/ModalAddAdsBanner.js'

const CustomInput = styled(Input)``

const CustomImage = styled(Image)`
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
  align-self: center;
  > section {
    width: 64px;
    height: 64px;
    > div {
      border-radius: 100px !important;
      border: unset !important ;

      > div {
        top: 0;
        left: 0;
        > div {
          display: none;
        }
        > i {
          margin: unset;
          font-size: 25px;
        }
        > img {
          border-radius: 100px !important;
          width: 64px !important;
          height: 64px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #00aeef !important;
          object-fit: none;
          border: unset !important ;
        }
      }
    }
  }
`
const ImageLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`
const InputLayout = styled.div`
  display: flex;
  justify-items: center;
`
const Caption = styled.div`
  min-width: 120px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`
const RequiredStar = styled.div`
  color: red;
`
const CustomButtom = styled(Button)`
  width: 62px;
`

export const ModalAdd = (props) => (
  <Modal isOpen={props.open}>
    <ModalHeader toggle={props.setIsOpenFalse}>
      <HeaderLayout>
        {props.isEdit && (
          <CustomSelect
            name="move"
            options={props.optionLengthArray}
            onChange={props.changeIndexArray}
            styles={{
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
              }),
            }}
            value={get(props, 'optionLengthArray', []).find(
              (x) => x.value === props.index
            )}
          />
        )}
        Menu
      </HeaderLayout>
    </ModalHeader>
    <Form onSubmit={props.handleSubmit}>
      <ModalBody>
        <ImageLayout>
          <Field name={`iconFile`} component={CustomImage} />
        </ImageLayout>
        <InputLayout>
          <Caption>
            Name
            {props.caption}
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name={`name`} component={CustomInput} required />
        </InputLayout>
        <Field name="action" component={Action} configs={props.configs} />
      </ModalBody>
      <ModalFooter>
        <CustomButtom color="primary" type="submit">
          Save
        </CustomButtom>
        {!props.isEdit && (
          <CustomButtom color="secondary" onClick={props.setIsOpenFalse}>
            Cancel
          </CustomButtom>
        )}
        {props.isEdit && (
          <CustomButtom color="outline-danger" onClick={props.onClickDelete}>
            Delete
          </CustomButtom>
        )}
      </ModalFooter>
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={props.onDelete}
          onCancel={props.onClickDelete}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Form>
  </Modal>
)

export const ValidateSchema = yup.object().shape({
  iconFile: yup.mixed().required(),
  name: yup.string().required(),
  action: yup
    .object()
    .shape({
      type: yup.string().required(),
      authorizedLevel: yup.string().required(),
      visibilityLevels: yup.array().min(1).required(),
      payload: yup
        .mixed()
        .when('type', {
          is: (value) => value === 'navigate',
          then: yup
            .object()
            .shape({
              page: yup.string().required(),
              url: yup.mixed().when('page', {
                is: (value) => value === 'web_view',
                then: yup.string().required(),
              }),
              id: yup.mixed().when('page', {
                is: (value) => value === 'loyalty_detail',
                then: yup.string().required(),
              }),
              referenceId: yup.mixed().when('page', {
                is: (value) => value === 'claim_info',
                then: yup.string().required(),
              })
            })
            .required(),
        })
        .when('type', {
          is: (value) => value === 'open_app',
          then: yup
            .object()
            .shape({
              deeplink: yup.string().required(),
              appName: yup.string().required(),
              appStoreId: yup.string().required(),
              playStoreId: yup.string().required(),
            })
            .required(),
        })
        .when('type', {
          is: (value) => value === 'open_external_url',
          then: yup
            .object()
            .shape({
              externalUrl: yup.string().required(),
            })
            .required(),
        })
    })
    .required(),
})

const enhancer = compose(
  withFormik({
    displayName: 'addTopMenu',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.push({ ...values })
      props.props.setIsOpenFalse()
      props.resetForm()
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    useEffect(() => {
      props.resetForm()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])
    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(ModalAdd)
