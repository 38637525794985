import humps from 'humps'

export const isServer = typeof window === 'undefined'
export const isClient = !isServer

export const call = (func) => func()

export const safe = (block, safeValue) => {
  try {
    return block()
  } catch (e) {
    return safeValue
  }
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const transformKeysToCamelCase = (object) => {
  if (object instanceof ArrayBuffer) {
    return object
  }
  return humps.camelizeKeys(object)
}

export const transformKeysToSnakeCase = (object) => {
  if (object instanceof File) {
    return object
  }
  if (object instanceof Array) {
    return object.map((obj) => transformKeysToSnakeCase(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object)
      .map(([key, value]) => [
        humps.decamelize(key),
        transformKeysToSnakeCase(value),
      ])
      .reduce(
        (memo, [key, value]) => ({
          ...memo,
          [key]: transformKeysToSnakeCase(value),
        }),
        {}
      )
  }

  return object
}

export const between = (number1, number2, number3) => {
  return (
    number1 >= Math.min(number2, number3) &&
    number1 <= Math.max(number2, number3)
  )
}

export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    var x = parseInt(a[key])
    var y = parseInt(b[key])
    return x < y ? 1 : x > y ? -1 : 0
  })
}

export const prepareOptions = (options) => {
  if (options) {
    if (typeof options[0] === 'string') {
      return options.map((label) => ({ label, value: label }))
    } else {
      return options
    }
  } else {
    return []
  }
}

export const getOptionLabel = (options, targetValue) => {
  options = prepareOptions(options)
  const option = options.find(({ value }) => value === targetValue)
  return option ? option.label : null
}
