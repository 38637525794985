import styled from '@emotion/styled'
import React from 'react'
import { compose, lifecycle, withState } from 'recompose'
import { IFrame } from '../../components/index'
import { withStores } from '../../enhancers/index'

const Layout = styled.div`
  width: 100%;
  height: auto;
`

const extendBodyEnhancer = compose(
  withState('backupStyles', 'setBackupStyles', (props) => ({})),
  lifecycle({
    componentDidMount() {
      try {
        const $mainPage = document.getElementById('main_page')
        const $mainBody = document.getElementById('main_body')

        this.props.setBackupStyles({
          mainPageStyles: {
            background: $mainPage.style.background,
          },
          mainBodyStyles: {
            maxWidth: $mainBody.style.maxWidth,
          },
        })

        $mainPage.style.background = 'white'
        $mainBody.style.maxWidth = 'unset'
      } catch (e) {}
    },
    componentWillUnmount() {
      try {
        const $mainPage = document.getElementById('main_page')
        const $mainBody = document.getElementById('main_body')

        $mainPage.style.background = this.props.backupStyles.mainPageStyles.background
        $mainBody.style.maxWidth = this.props.backupStyles.mainBodyStyles.maxWidth
      } catch (e) {}
    },
  })
)

export const FullBodyLayout = extendBodyEnhancer(Layout)

const OldSiteIFrame = styled(IFrame.OldSite)`
  width: 100%;
  height: 100vh;
  border: none !important;
`

const LifePolicy = (props) => (
  <FullBodyLayout>
    <OldSiteIFrame
      style={{ width: '1px', minWidth: '100%' }}
      src={props.src}
      log
      autoResize
    />
  </FullBodyLayout>
)

const enhancer = compose(
  withStores((stores) => ({
    currentMemberId: stores.appStore.currentMemberId,
  }))
)

export default enhancer(LifePolicy)
