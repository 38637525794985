import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class ConsentStore extends ModelStore {
  constructor() {
    super('consentStore', {
      all: [],
      current: [],
      configs: {},
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get(`/consents`, params)
    this.$setState('all', res.data.data)
  }

  fetchById = async (id) => {
    const res = await tqm24Server.get(`/consents/${id}`)
    this.$setState('current', res.data.data)
  }

  create = async (params) => {
    await tqm24Server.post(`/consents`, params)
  }

  update = async (id, params, callback = () => {}) => {
    const res = await tqm24Server.put(`/consents/${id}`, params)
    this.$setState('current', res.data.data)
    callback()
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get(`/consents/configs`)
    this.$setState('configs', res.data.data)
  }

  clearCurrent = () => {
    this.$setState('current', [])
  }
}

export default new ConsentStore()
