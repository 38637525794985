import styled from '@emotion/styled'
import React from 'react'
import { Button, Spinner } from 'reactstrap'

const CustomSpinner = styled(Spinner)`
  margin-right: 5px;
`

const LoadingButton = ({ loading, ...props }) => (
  <Button {...props} disabled={loading}>
    {loading && (
      <CustomSpinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )}
    {!loading && props.children}
  </Button>
)

export default LoadingButton
