import { branch, compose, renderNothing, withProps } from 'recompose'
import omitProps from './omitProps'
import withStores from './withStores'

export const authorize = (props) => {
  let authorized = false
  // if (props.authorize) {
  //   const roles = props.authorize.role ? [props.authorize.role] : props.authorize.roles || []
  //   const notRoles = props.authorize.notRole
  //     ? [props.authorize.notRole]
  //     : props.authorize.notRoles || []

  //   if (roles.length > 0) {
  //     authorized =
  //       (props.serverRoles.includes(props.currentRole) && roles.includes(props.currentRole)) ||
  //       roles.includes("*")
  //   }

  //   if (notRoles.length > 0) {
  //     authorized = !notRoles.includes(props.currentRole)
  //   }
  // } else {
  //   authorized = true
  // }
  if (props.token) {
    authorized = true
  }

  return authorized
}

const withAuthorized = () =>
  compose(
    withStores((stores) => ({
      serverRoles: stores.appStore.roles,
      currentRole: stores.appStore.currentRole,
    })),
    withProps((props) => ({ authorized: authorize(props) })),
    branch(
      (props) => !(props.children instanceof Function) && !props.authorized,
      renderNothing
    ),
    omitProps('serverRoles', 'currentRole', 'isGod')
  )

export default withAuthorized
