import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 500px;
  overflow: auto;
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cae6ff;
    border-radius: 10px;
  }
`
const Title = styled.div`
  font-weight: 500;
  margin-left: ${(props) => `${props.margin}px`};
`

const TextAlert = styled.div`
  margin-left: ${(props) => `${props.margin}px`};
`

const ErrorDetail = ({ values, tab, ...props }) => {
  if (Array.isArray(values)) {
    return values.map((item, index) => (
      <React.Fragment key={index}>
        {item && (Array.isArray(item) || typeof item === 'object') ? (
          <>
            <Title margin={tab}>
              {tab !== 0 && '- index '}
              {/* {item} */}
              {index}
            </Title>
            <ErrorDetail values={item} tab={tab + 10} />
          </>
        ) : (
          <>
            {/* <Title margin={tab}>
              {tab !== 0 && '- '} {item}
            </Title>
            <TextAlert margin={tab + 8}>{item}</TextAlert> */}
          </>
        )}
      </React.Fragment>
    ))
  } else if (typeof values === 'object') {
    return Object.keys(values).map((key) => (
      <React.Fragment key={key}>
        {Array.isArray(values[key]) || typeof values[key] === 'object' ? (
          <>
            <Title margin={tab}>
              {tab !== 0 && '- '}
              {key}
            </Title>
            <ErrorDetail values={values[key]} tab={tab + 10} />
          </>
        ) : (
          <>
            <Title margin={tab}>
              {tab !== 0 && '- '} {key}
            </Title>
            <TextAlert margin={tab + 8}>{values[key]}</TextAlert>
          </>
        )}
      </React.Fragment>
    ))
  } else {
    return <div> {values}</div>
  }
}

const ErrorMessageAlert = (props) => (
  <>
    {props.isOpen && (
      <SweetAlert
        danger
        style={{top: "30%"}}
        showCancel={false}
        confirmBtnText="Yes, got it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Incorrect information"
        onConfirm={props.closeAlert}
      >
        <MainLayout>
          {props.message && <ErrorDetail values={props.message} tab={0} />}
        </MainLayout>
      </SweetAlert>
    )}
  </>
)

const enhancer = compose(
  withHooks((props) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      if (props.open) {
        setIsOpen(true)
      }
    }, [props.open])

    const closeAlert = useCallback(() => {
      setIsOpen(false)
    }, [])

    return { isOpen, closeAlert }
  })
)

export default enhancer(ErrorMessageAlert)
