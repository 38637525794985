import { withFormik } from 'formik'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers/index.js'
import TermAndConditionForm from '../components/form.js'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.termAndConditionStore.fetchById,
    current: stores.termAndConditionStore.current,
    update: stores.termAndConditionStore.update,
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'editTermAndCondition',
  }),
  withHooks((props) => {
    useEffect(() => {
      props.fetch(props.match.params.id)
    }, [])

    return {}
  })
)

export default enhancer(TermAndConditionForm)
