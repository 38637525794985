import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Field } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { Image } from '../../../../components/index.js'
import { withHooks } from '../../../../enhancers/index.js'
import ModalShowMenu from './ModalShowMenu'

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
`

const CustomImage = styled(Image)`
  width: 64px;
  height: 64px;

  align-self: center;
  > section {
    width: 64px;
    height: 64px;
    > div {
      border-radius: 100px !important;
      border: unset !important ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      > div {
        > div {
          display: none;
        }
        > i {
          margin: unset;
        }
        > img {
          border-radius: 100px !important;
          width: 64px !important;
          height: 64px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #00aeef !important;
          object-fit: none;
          border: unset !important ;
        }
      }
    }
  }
`

const TextName = styled.div`
  width: 71px;
  color: #ffffff;
  font-style: Bold;
  text-align: center;
`

const AddCircle = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`

const AppDashboardConfig = (props) => (
  <MainLayout readOnly={props.readOnly}>
    <AddCircle onClick={props.setOpenTrue}>
      <Field
        name={`${props.name}[${props.index}].iconFile`}
        component={CustomImage}
        readOnly
      />
    </AddCircle>
    <TextName>{props.item.name}</TextName>{' '}
    <ModalShowMenu
      values={props.item}
      open={props.open}
      setIsOpenFalse={props.setOpenFalse}
      name={`${props.name}[${props.index}]`}
      item={props.item}
      setFieldValue={props.form.setFieldValue}
      remove={props.remove}
      index={props.index}
      configs={props.configs}
      setOpen={props.setOpen}
      move={props.move}
      optionLengthArray={props.optionLengthArray || []}
    />
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const setOpenFalse = useCallback(() => {
      setOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrue = useCallback(() => {
      setOpen(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const optionLengthArray = useMemo(() => {
      const newOption = []
      get(props, 'form.values.dashboard.topMenu.items', []).forEach(
        (element, index) => {
          newOption.push({ label: index + 1, value: index })
        }
      )
      return newOption
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values.dashboard.topMenu.items])

    return {
      loading,
      setLoading,
      setOpenFalse,
      setOpenTrue,
      open,
      setOpen,
      optionLengthArray,
    }
  })
)

export default enhancer(AppDashboardConfig)
