import { withFormik } from 'formik'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers/index.js'
import TermAndConditionForm from '../components/form.js'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    create: stores.termAndConditionStore.create,
    clear: stores.termAndConditionStore.clearCurrent
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'newTermAndCondition',
  }),
  withHooks((props) => {
    useEffect(() => {
      props.clear()
    }, [])

    return {}
  })
)

export default enhancer(TermAndConditionForm)
