import { withFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../enhancers/index.js'
import { NotificationCategoryModal, ValidateSchema } from './ModalAdd'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.notificationScheduleStore.fetchCategory,
    fetchById: stores.notificationScheduleStore.fetchCategoryById,
    update: stores.notificationScheduleStore.updateCategory,
    current: stores.notificationScheduleStore.category,
    delete: stores.notificationScheduleStore.deleteCategory,
  })),
  withFormik({
    displayName: 'editNotificationCategory',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.categoryId, values)
      await props.props.fetch({
        ...props.props.values,
        sort: 'created_at DESC',
      })
      props.resetForm()
      await props.props.onClose()
    },
    mapPropsToValues: (props) => {
      return { ...props.current }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
      if (props.open) {
        props.fetchById(props.categoryId)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categoryId, props.open])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    const setConfirmDeleteToCon = useCallback(() => {
      setConfirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onClickconfirmDelete = useCallback(async () => {
      await props.delete(props.categoryId)
      await props.props.fetch({ ...props.values, sort: 'created_at DESC' })
      await props.setIsOpenFalse()
      setConfirmDelete(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categoryId])

    return {
      loading,
      setLoading,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      isEdit: true,
      setConfirmDeleteToCon,
      onClickconfirmDelete,
      confirmDelete,
      textHeader: 'แก้ไขหมวดหมู่',
    }
  })
)

export default enhancer(NotificationCategoryModal)
