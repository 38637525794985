import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { Button, TitlePage } from '../../../components/index.js'
import { Panel, PanelHeader } from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import ModalNew from '../new/index'

const HeaderMainLayout = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`

const ButtonNew = styled(Button)`
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VirtualCard = (props) => (
  <>
    <TitlePage
      text="Virtual Card"
      textSmall="Can create, view and edit virtual card."
    />
    <Panel>
      <PanelHeader noButton={true}>
        <HeaderMainLayout>
          <ButtonNew onClick={props.setIsOpenTrue} color="info">
            New
          </ButtonNew>
          <TextHeader>Virtual Card</TextHeader>
        </HeaderMainLayout>
      </PanelHeader>
      <ReactTable
        filterable
        data={props.all}
        columns={props.tableColumns}
        defaultPageSize={10}
        className="-highlight"
        getTdProps={props.onRowClick}
      />
      <ModalNew open={props.open} setIsOpenFalse={props.setIsOpenFalse} />
    </Panel>
  </>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.virtualCardStore.fetch,
    all: stores.virtualCardStore.all,
  })),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const tableColumns = [
      {
        Header: 'Front Card Template',
        width: 250,
        Cell: (row) => (
          <div
            style={{ zoom: 0.3 }}
            dangerouslySetInnerHTML={{
              __html: `${row.row._original.templateHtmlFront}
          `,
            }}
          />
        ),
      },
      {
        Header: 'Back Card Template',
        width: 250,
        Cell: (row) => (
          <div
            style={{ zoom: 0.3 }}
            dangerouslySetInnerHTML={{
              __html: `${row.row._original.templateHtmlBack}
        `,
            }}
          />
        ),
      },
      {
        Header: 'Insurance Company Name',
        accessor: 'insuranceCompanyName',
      },
      {
        Header: 'Type',
        accessor: 'productType',
      },
      {
        Header: 'Card Name',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        Cell: (row) => <>{row.row._original.isActive ? 'online' : 'offline'}</>,
      },
    ]

    const setIsOpenFalse = useCallback(() => {
      setOpen(false)
    }, [])

    const setIsOpenTrue = useCallback(() => {
      setOpen(true)
    }, [])

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: (e) => {
          if (rowInfo) {
            props.history.push(`/virtual_card/${rowInfo.original.id}`)
          }
        },
      }
    }
    return {
      loading,
      setLoading,
      onRowClick,
      tableColumns,
      setIsOpenTrue,
      setIsOpenFalse,
      open,
    }
  })
)

export default enhancer(VirtualCard)
