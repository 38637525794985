import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class OtherStore extends ModelStore {
  constructor() {
    super('otherStore', {
      currentAuthMenu: '',
      currentSuccess: '',
      currentClaimMotorSolution: '',
    })
  }

  fetchAuthMenu = async () => {
    const res = await tqm24Server.get(`/app_configurations/auth_menu_banner`)
    this.$setState('currentAuthMenu', res.data.data)
  }

  updateAuthMenu = async (params) => {
    const res = await tqm24Server.put(
      `/app_configurations/auth_menu_banner`,
      params
    )
    this.$setState('currentAuthMenu', res.data.data)
  }

  fetchSuccess = async () => {
    const res = await tqm24Server.get(`/app_configurations/success_banner`)
    this.$setState('currentSuccess', res.data.data)
  }

  updateSuccess = async (params) => {
    const res = await tqm24Server.put(
      `/app_configurations/success_banner`,
      params
    )
    this.$setState('currentSuccess', res.data.data)
  }

  fetchClaimMotorSolution = async () => {
    const res = await tqm24Server.get(
      `/app_configurations/claim_motor_solution`
    )
    this.$setState('currentClaimMotorSolution', res.data.data)
  }

  updateClaimMotorSolution = async (params) => {
    const res = await tqm24Server.put(
      `/app_configurations/claim_motor_solution`,
      params
    )
    this.$setState('currentClaimMotorSolution', res.data.data)
  }
}

export default new OtherStore()
