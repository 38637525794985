import styled from '@emotion/styled'
import { FieldArray, Form, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  ErrorMessageAlert,
  Loading,
  TitlePage
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
// import ModalAddCategory from './category/ModalAddCategory'
import ReportProbelmconfigDetail from './ReportProbelmconfigDetail'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const MiddleLayout = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const MainCategoryLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
`

const TitleText = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const IconButton = styled.i``

const IconCarConfig = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage text="App Config" textSmall="Can view and edit app config." />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Report Problem Config</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <TitleText>Report Problem</TitleText>
          <MainCategoryLayout>
            <FieldArray
              name="reportProblems"
              render={(FieldArrayProps) => (
                <MiddleLayout>
                  {get(FieldArrayProps, 'form.values.reportProblems', []).map(
                    (item, index) => (
                      <ReportProbelmconfigDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.open}
                        setOpenFalseAdd={props.setOpenFalse}
                        key={index}
                        configs={props.configs}
                      />
                    )
                  )}

                  {get(FieldArrayProps, 'form.values.reportProblems', []).length ===
                    0 && (
                    <Button
                      color="success"
                      onClick={() => {
                        FieldArrayProps.push({})
                      }}
                    >
                      <IconButton className="ion-md-add" />{' '}
                    </Button>
                  )}
                </MiddleLayout>
              )}
            />
          </MainCategoryLayout>
        </PanelBody>
      </Panel>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
    </MainLayout>
  </Loading>
)

export const ValidateSchema = yup.object().shape({
  reportProblems: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .required(),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.reportProblemConfigStore.fetch,
    current: stores.reportProblemConfigStore.current,
    update: stores.reportProblemConfigStore.update,
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'editReportProblemConfig',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
        await props.props.update(values)
    },
    mapPropsToValues: (props) => {
      if (isEmpty(get(props, 'current.reportProblems', []))) {
        return {
          reportProblems: [{}],
        }
      }
      return {
        ...props.current,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {

    const [openMenu, setOpenMenu] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseMenu = useCallback(() => {
      setOpenMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueMenu = useCallback(() => {
      setOpenMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return {
      setOpenFalseMenu,
      setOpenTrueMenu,
      openMenu,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
    }
  })
)

export default enhancer(IconCarConfig)
