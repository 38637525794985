import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class VirtualCardStore extends ModelStore {
  constructor() {
    super('virtualCardStore', {
      all: [],
      current: [],
      configs: {},
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get('/virtual_card_template')
    this.$setState('all', res.data.data.virtualCard)
  }

  fetchById = async (params) => {
    const res = await tqm24Server.get(`/virtual_card_template/${params}`)
    this.$setState('current', res.data.data.virtualCard)
  }

  update = async (id, params) => {
    const res = await tqm24Server.put(`/virtual_card_template/${id}`, params)
    this.$setState('current', res.data.data.virtualCard)
  }

  create = async (params) => {
    await tqm24Server.post(`/virtual_card_template`, params)
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get('/virtual_card_template/configs')
    this.$setState('configs', res.data.data.configs.attributes)
  }

  updateImage = async (id, params) => {
    const res = await tqm24Server.put(
      `/virtual_card_template/${id}/image`,
      params
    )
    this.$setState('current', res.data.data.virtualCard)
  }

  delete = async (id) => {
    await tqm24Server.delete(`/virtual_card_template/${id}`)
  }
}

export default new VirtualCardStore()
