import styled from '@emotion/styled'
import React from 'react'
import { Caption, RequiredStar } from './Input.jsx'
const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`
const Checkbox = (props) => (
  <MainLayout className={props.className}>
    {props.caption && (
      <Caption>
        {props.caption}
        {props.required && <RequiredStar>*</RequiredStar>}
      </Caption>
    )}
    <div className="checkbox checkbox-css height-40">
      <input
        {...props.field}
        type="checkbox"
        id={`${props.field.name}_checkbox`}
        checked={Boolean(props.field.value)}
        disabled={props.readOnly}
      />
      <label htmlFor={`${props.field.name}_checkbox`} className="f-s-16">
        {props.label}
      </label>
    </div>
  </MainLayout>
)

export default Checkbox
