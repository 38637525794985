import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Field } from 'formik'
import React, { useCallback, useState } from 'react'
import { compose } from 'recompose'
import { Image } from '../../../../components/index.js'
import { withHooks } from '../../../../enhancers/index.js'
import ModalShowAdsBanner from './ModalShowTelemediciner'

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

const TelemedicineCardAdd = styled.div`
  width: 388px;
  height: 219px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 6px;

  ${(props) =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
`

const CustomImage = styled(Image)`
  width: 388px;
  height: 219px;
  align-self: center;
  > section {
    width: 100%;
    height: 100%;
    > div {
      border: unset !important ;
      > i {
        margin: unset;
      }
      > div {
        > img {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: unset !important ;
          object-fit: cover;
        }
      }
    }
  }
`

const AdsBannerConfig = (props) => (
  <MainLayout>
    <TelemedicineCardAdd readOnly={props.readOnly} onClick={props.setOpenTrue}>
      <Field
        name={`${props.name}[${props.index}].imageFile`}
        component={CustomImage}
        readOnly
      />

      <ModalShowAdsBanner
        open={props.open}
        setIsOpenFalse={props.setOpenFalse}
        name={`${props.name}[${props.index}]`}
        setFieldValue={props.form.setFieldValue}
        item={props.item}
        remove={props.remove}
        index={props.index}
        configs={props.configs}
        setOpen={props.setOpen}
      />
    </TelemedicineCardAdd>
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const setOpenFalse = useCallback(() => {
      setOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrue = useCallback(() => {
      setOpen(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      setOpenFalse,
      setOpenTrue,
      open,
      setOpen,
    }
  })
)

export default enhancer(AdsBannerConfig)
