import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class TermAndConditionStore extends ModelStore {
  constructor() {
    super('termAndConditionStore', {
      all: [],
      current: [],
      configs: {},
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get(`/term_and_conditions`, params)
    this.$setState('all', res.data.data)
  }

  fetchById = async (id) => {
    const res = await tqm24Server.get(`/term_and_conditions/${id}`)
    this.$setState('current', res.data.data)
  }

  create = async (params) => {
    await tqm24Server.post(`/term_and_conditions`, params)
  }

  update = async (id, params, callback = () => {}) => {
    const res = await tqm24Server.put(`/term_and_conditions/${id}`, params)
    this.$setState('current', res.data.data)
    callback()
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get(`/term_and_conditions/configs`)
    this.$setState('configs', res.data.data)
  }

  clearCurrent = () => {
    this.$setState('current', [])
  }
}

export default new TermAndConditionStore()
