import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { compose } from 'recompose'
// import { ValidateSchema } from '../show/index'
import * as yup from 'yup'
import {
  Button,
  ErrorMessageAlert,
  Input,
  Select,
  Textarea,
} from '../../../components/index.js'
import { withHooks, withStores } from '../../../enhancers/index.js'

const BodyLayout = styled.div`
  padding: 40px 24px;
`

const InputLayout = styled.div`
  display: flex;
  justify-items: center;
`

const CustomInput = styled(Input)`
  height: 56px;
`

const Caption = styled.div`
  min-width: 120px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`
const RequiredStar = styled.div`
  color: red;
`

const TextHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
`

const CustomButton = styled(Button)`
  width: 150px;
  height: 56px;
  border-radius: 4px;
`

const CancelButton = styled(CustomButton)`
  color: #007bff !important;
  border-color: #007bff !important;
  :hover {
    color: #fff !important;
    background-color: #2196f3 !important;
  }
`

const CustomSelect = styled(Select)`
  border-radius: 4px;
  width: 100%;

  > div:first-of-type {
    height: 56px;
    > div:last-of-type {
      > span {
        /* display: none; */
      }
    }
  }

  > :last-of-type {
    z-index: 2;
  }
`

const TextAreaLayout = styled.div`
  display: flex;
  flex-direction: column;
`

const CustomTextArea = styled(Textarea)`
  overflow: hidden;
`

export const NotificationTitleModal = (props) => (
  <Modal isOpen={props.open}>
    <ModalHeader toggle={props.onClose}>
      <TextHeader>{props.textHeader}</TextHeader>
    </ModalHeader>
    <Form onSubmit={props.handleSubmit}>
      <ModalBody>
        <BodyLayout>
          <InputLayout>
            <Caption>
              Category
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field
              name="notificationCategoryId"
              component={CustomSelect}
              options={props.categoryOptions}
              required
            />
          </InputLayout>
          <InputLayout>
            <Caption>
              Title
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field name="title" component={CustomInput} required />
          </InputLayout>
          <TextAreaLayout>
            <Caption>
              Description (ไม่เกิน 1000 ตัวอักษร)
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field
              name="description"
              component={CustomTextArea}
              autoHeigth
              maxlength="1000"
            />
          </TextAreaLayout>
        </BodyLayout>
      </ModalBody>
      <ModalFooter>
        <CancelButton outline color="primary" onClick={props.onClose}>
          Cancel
        </CancelButton>
        {props.isEdit && (
          <>
            {/* <CustomButton
              outline
              color="danger"
              type="button"
              onClick={props.setConfirmDeleteToCon}
              loading={props.isSubmitting}
            >
              Delete
            </CustomButton> */}
            <CustomButton
              color="primary"
              type="button"
              onClick={props.setConfirmUpdateToCon}
              loading={props.isSubmitting}
            >
              Save
            </CustomButton>
          </>
        )}
        {!props.isEdit && (
          <CustomButton
            color="primary"
            type="submit"
            loading={props.isSubmitting}
          >
            Save
          </CustomButton>
        )}
      </ModalFooter>
    </Form>
    <ErrorMessageAlert
      open={
        !props.isSubmitting &&
        !props.isValid &&
        !isEmpty(props.errors) &&
        !props.isValidating
      }
      message={props.errors}
    />
    {props.confirmUpdate && (
      <SweetAlert
        showCancel
        confirmBtnText="Confirm"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการบันทึกใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmUpdate}
        onCancel={props.setConfirmUpdateToCon}
      />
    )}
    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Confirm"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการลบใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmDelete}
        onCancel={props.setConfirmDeleteToCon}
      />
    )}
  </Modal>
)

export const ValidateSchema = yup.object().shape({
  notificationCategoryId: yup.number().required(),
  title: yup.string().required(),
  description: yup.string().required(),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    create: stores.notificationScheduleStore.createTitle,
    fetch: stores.notificationScheduleStore.fetchTitle,
    fetchConfig: stores.notificationScheduleStore.fetchTitleConfig,
  })),
  withFormik({
    displayName: 'newNotificationTitle',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.create(values)
      await props.props.fetch({
        ...props.props.values,
        sort: 'created_at DESC',
      })
      await props.props.fetchConfig()
      props.resetForm()
      await props.props.onClose()
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
      textHeader: 'เพิ่มหมวดหมู่',
    }
  })
)

export default enhancer(NotificationTitleModal)
