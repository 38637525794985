import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import { get } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import Table from 'react-table-6'
import { compose, withState } from 'recompose'
import { getOptionLabel } from '../../../../common/helper'
import { Button, Select, TitlePage } from '../../../../components'
import { Loading } from '../../../../components/index.js'
import Pagination from '../../../../components/Pagination'
import { withHooks, withStores } from '../../../../enhancers/index.js'

const Container = styled.div`
  background-color: white;
  padding: 32px;
`
const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
const ButtonNew = styled(Button)`
  border-radius: 4px;
`
const PaginateLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`
const PageNumberLayout = styled.div`
  display: flex;
  align-items: center;
`
const PerPageNumberLayout = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
`
const PerPageText = styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  width: 120px;
  margin-bottom: 10px;
`
const CustomSelect = styled(Select)`
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  height: 36px;
  > div:first-of-type {
    width: 76px;
    height: 36px;
    > div:last-of-type {
      > span {
        /* display: none; */
      }
    }
  }

  > div:last-of-type {
    width: 76px;
  }
  margin: unset;
`
const CustomTable = styled(Table)`
  border-color: white !important;
  border-bottom: 1px solid #e6e8ec !important;

  .rt-table {
    ::-webkit-scrollbar-thumb {
      background: #b5b6bf;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #d8d9e0;
    }

    ::-webkit-scrollbar {
      height: 8px;
    }
  }

  .rt-resizer {
    display: none;
  }

  .rt-thead {
    border-top: 1px solid #e6e8ec;
    border-bottom: 1px solid #e6e8ec;
    > div {
      > div {
        display: flex;
        justify-content: flex-start;
        padding-right: 20px !important;
        ::after {
          display: none !important;
        }
        /* :nth-of-type(2)::after {
          display: flex !important;
        } */
        :nth-of-type(7)::after {
          display: flex !important;
        }
        color: #0d75d1;
        border: unset;
        border-color: white !important;
      }
    }
  }
  .rt-tbody {
    > div:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
    > :nth-of-type(odd) {
      background-color: #f2f2f2;
    }
    > :nth-of-type(even) {
      background-color: #ffffff;
    }
  }
  .rt-tr-group {
    border: hidden !important;
  }
  .rt-td {
    border: hidden !important;
  }
`

const TermAndCondition = (props) => (
  <Container>
    <Loading isLoading={props.loading}>
      <TitlePage
        text="Term & Condition"
        textSmall="Can create, view and edit term & condition."
      />
      <ButtonLayout>
        <ButtonNew
          color="primary"
          type="button"
          onClick={() => {
            props.setIsNew(true)
            props.history.push('/other/term_and_condition_config/new')
          }}
        >
          New
        </ButtonNew>
      </ButtonLayout>
      <PaginateLayout>
        <PageNumberLayout>
          <Field
            name="page"
            component={Pagination}
            size=""
            pages={get(props, 'pagination.allPages', 0)}
            onClick={props.onPageChange}
            currentPage={props.values?.page || 1}
          />
          <PerPageText>
            {get(props, 'pagination.allRecords', 0)} items found
          </PerPageText>
        </PageNumberLayout>
        <PerPageNumberLayout>
          <Field
            name="pageSize"
            component={CustomSelect}
            isClearable={false}
            resizable={false}
            menuPlacement="auto"
            onKeyDown={props.selectOnKeyDown}
            onChange={props.onChangePageSize}
            options={[
              { label: '25', value: '25' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
          <PerPageText>items per page</PerPageText>
        </PerPageNumberLayout>
      </PaginateLayout>

      <CustomTable
        data={get(props, 'data', [])}
        columns={props.columns}
        showPagination={false}
        pageSize={get(props, 'data', []).length || 10}
        sorted={props.sort}
        loading={props.loading}
        getTdProps={props.onRowClick}
      />

      <PaginateLayout>
        <PageNumberLayout>
          <Field
            name="page"
            component={Pagination}
            size=""
            pages={get(props, 'pagination.allPages', 0)}
            onClick={props.onPageChange}
            currentPage={props.values?.page || 1}
          />
        </PageNumberLayout>
        <PerPageNumberLayout>
          <Field
            name="pageSize"
            component={CustomSelect}
            isClearable={false}
            resizable={false}
            menuPlacement="auto"
            onKeyDown={props.selectOnKeyDown}
            onChange={props.onChangePageSize}
            options={[
              { label: '25', value: '25' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
          <PerPageText>items per page</PerPageText>
        </PerPageNumberLayout>
      </PaginateLayout>
    </Loading>
  </Container>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.termAndConditionStore.fetch,
    fetchConfig: stores.termAndConditionStore.fetchConfig,
    all: stores.termAndConditionStore.all,
    configs: stores.termAndConditionStore.configs,
    create: stores.termAndConditionStore.create,
  })),
  withFormik({
    displayName: 'termAndCondition',
    mapPropsToValues: () => {
      return { pageSize: '25', page: 1 }
    },
  }),
  withState('loading', 'setLoading', false),
  withState('isNew', 'setIsNew', false),
  withHooks((props) => {
    useEffect(() => {
      props.setLoading(true)
      props.fetch({ pageSize: '25', page: '1' })
      props.fetchConfig()
    }, [])

    const data = useMemo(() => {
      const newData = get(props, 'all.termAndConditions', []).map(
        (item, index) => {
          return {
            ...item,
            id: get(item, 'id', ''),
            version: get(item, 'version', ''),
            tqmCode: get(item, 'tqmCode', ''),
            status: get(item, 'status', ''),
            activatedAt: item.activatedAt ? moment(item.activatedAt).format('DD/MM/YYYY') : '',
            createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
            updatedAt: moment(item.updatedAt).format('DD/MM/YYYY'),
          }
        }
      )
      props.setLoading(false)
      return newData
    }, [props.all])

    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 50,
      },
      {
        Header: 'Version',
        accessor: 'version',
        sortable: false,
        minWidth: 80,
      },
      {
        Header: 'TQM Code',
        accessor: 'tqmCode',
        sortable: false,
        minWidth: 140,
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        minWidth: 120,
        Cell: (row) => (
          <div>
            {getOptionLabel(
              get(props, 'configs.configs.attributes.status.options', []),
              row.row._original.status
            )}
          </div>
        ),
      },
      {
        Header: 'Activated At',
        accessor: 'activatedAt',
        sortable: false,
        minWidth: 120,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        sortable: false,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        sortable: false,
        minWidth: 120,
      },
    ]

    const onPageChange = useCallback(
      (val) => {
        props.setLoading(true)
        props.fetch({
          ...props.values,
          pageSize: props.values.pageSize || 25,
          page: val,
        })
        props.setFieldValue('page', val)
      },
      [props]
    )

    const selectOnKeyDown = useCallback((e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        return false
      }
    }, [])

    const onChangePageSize = useCallback(
      (value) => {
        props.setLoading(true)
        if (value) {
          props.setFieldValue('pageSize', value.value)
          props.fetch({ ...props.values, pageSize: value.value, page: '1' })
        } else {
          props.setFieldValue('pageSize', value)
          props.fetch({ ...props.values, pageSize: value, page: '1' })
        }
        props.setFieldValue('page', '1')
      },
      [props]
    )

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (rowInfo) {
            props.history.push(
              `/other/term_and_condition_config/${rowInfo.original.id}`
            )
          }
        },
      }
    }

    return {
      data,
      pagination: get(props, 'all.pagination', ''),
      onPageChange,
      selectOnKeyDown,
      onChangePageSize,
      onRowClick,
      columns,
    }
  })
)

export default enhancer(TermAndCondition)
