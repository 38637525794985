import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class TelemedicineStore extends ModelStore {
  constructor() {
    super('telemedicineStore', {
      all: [],
      current: [],
      configs: [],
    })
  }

  fetch = async () => {
    const res = await tqm24Server.get(`/app_configurations/telemedicine`)
    this.$setState('current', res.data.data)
  }

  update = async (params) => {
    const res = await tqm24Server.put(
      `/app_configurations/telemedicine`,
      params
    )
    this.$setState('current', res.data.data)
  }
}

export default new TelemedicineStore()
