import { Field, Form, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { compose } from 'recompose'
import { ErrorMessageAlert, Input, Select } from '../../../components/index.js'
import { withHooks, withStores } from '../../../enhancers/index.js'
import { ValidateSchema } from '../show/index'

const VirtualCardModal = (props) => (
  <Modal isOpen={props.open}>
    <ModalHeader toggle={props.setIsOpenFalse}>Virtual Card New</ModalHeader>
    <Form onSubmit={props.handleSubmit}>
      <ModalBody>
        <Field
          name="insuranceCompanyId"
          component={Select}
          caption="Company Name"
          options={get(props, 'configs.companies.options', [])}
        />
        <Field
          name="productType"
          component={Select}
          caption="Type"
          options={get(props, 'configs.type.options', [])}
        />
        <Field name="name" component={Input} caption="Card Name" />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit">
          Submit
        </Button>{' '}
        <Button color="secondary" onClick={props.setIsOpenFalse}>
          Cancel
        </Button>
      </ModalFooter>
    </Form>
    <ErrorMessageAlert
      open={
        !props.isSubmitting &&
        !props.isValid &&
        !isEmpty(props.errors) &&
        !props.isValidating
      }
      message={props.errors}
    />
  </Modal>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.virtualCardStore.fetch,
    fetchConfig: stores.virtualCardStore.fetchConfig,
    configs: stores.virtualCardStore.configs,
    create: stores.virtualCardStore.create,
  })),
  withFormik({
    displayName: 'newVirtualCard',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.create(values)
      await props.props.fetch()
      await props.props.setIsOpenFalse()
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(VirtualCardModal)
