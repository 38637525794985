import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class NotificationStore extends ModelStore {
  constructor() {
    super('notificationStore', {
      all: [],
      current: [],
      configs: [],
      histories: [],
      history: [],
    })
  }

  fetchConfig = async (params) => {
    const res = await tqm24Server.get('/notification/config/configs')
    this.$setState('configs', res.data.data.configs)
  }

  fetch = async (params) => {
    const res = await tqm24Server.get('/notification/config')
    this.$setState('all', res.data.data.notification)
  }

  fetchById = async (params) => {
    const res = await tqm24Server.get(`/notification/config/${params}`)
    this.$setState('current', res.data.data.notification)
  }

  update = async (id, params) => {
    const res = await tqm24Server.put(`/notification/config/${id}`, params)
    this.$setState('current', res.data.data.notification)
  }

  create = async (params) => {
    await tqm24Server.post(`/notification/config`, params)
  }

  send = async (id, params) => {
    await tqm24Server.post(`/notification/config/${id}/send`, params)
  }
  fetchHistory = async () => {
    const res = await tqm24Server.get(`/notification/history`)
    this.$setState('histories', res.data.data.notification)
  }
  fetchHistoryById = async (id) => {
    const res = await tqm24Server.get(`/notification/history/${id}`)
    this.$setState('history', res.data.data.notification)
  }
}

export default new NotificationStore()
