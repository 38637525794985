import { createStore, combineReducers } from 'redux'
import { reducer as modelReducer } from './ModelStore'

const rootReducer = combineReducers({
  model: modelReducer,
})

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
