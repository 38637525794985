import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { get, uniq } from 'lodash'
import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { compose } from 'recompose'
import { withHooks } from '../enhancers/index.js'
import { RequiredStar } from './Input.jsx'

const MainLayout = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`
export const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  display: flex;
`

const CustomSelect = styled(CreatableSelect)`
  width: 100%;
  ${(props) =>
    props.valid &&
    css`
      > div:nth-of-type(1) {
        border: 1px solid #dc3545 !important;
      }
    `}
`

const components = {
  DropdownIndicator: null,
}

const RenderSelectInput = (props) => {
  const { field } = props

  return (
    <MainLayout>
      {props.caption && (
        <Caption className="readOnly">
          {props.caption} {props.required && <RequiredStar>*</RequiredStar>}
        </Caption>
      )}
      {!props.readOnly && (
        <CustomSelect
          {...field}
          name={field.name}
          id={field.name}
          components={components}
          inputValue={props.inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(e) => {
            e = e.map((item) => item.value)
            props.form.setFieldValue(field.name, e)
          }}
          onInputChange={(e) => {
            props.setInputValue(e)
          }}
          onKeyDown={(event) => {
            if (!props.inputValue) return
            switch (event.key) {
              case 'Enter':
              case 'Tab':
                const value = uniq([...props.value, props.inputValue])
                props.form.setFieldValue(field.name, value)
                props.setInputValue('')
                event.preventDefault()
                break
              default:
            }
          }}
          placeholder="Type something and press enter..."
          {...props}
          value={props.showValue || ''}
        />
      )}
    </MainLayout>
  )
}

const enhancer = compose(
  withHooks((props) => {
    const [inputValue, setInputValue] = useState('')

    const value = props.field.value || []
    const showValue = (props.field.value || []).map((item) => ({
      label: item,
      value: item,
    }))

    return {
      valid:
        get(props.form.errors, props.field.name) && props.form.submitCount > 0,
      inputValue,
      setInputValue,
      value,
      showValue,
    }
  })
)

export default enhancer(RenderSelectInput)
