import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../enhancers'

class DropdownProfile extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="dropdown navbar-user"
        tag="li"
      >
        <DropdownToggle tag="a">
          <div className="image image-icon bg-black text-grey-darker">
            <i className="fa fa-user"></i>
          </div>
          <span className="d-none d-md-inline">
            {get(this.props, 'info.email', '')}
          </span>{' '}
          <b className="caret"></b>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          <DropdownItem onClick={this.props.onClickEditProfile}>
            Edit Profile
          </DropdownItem>
          {/* <DropdownItem>
            <span className="badge badge-danger pull-right">2</span> Inbox
          </DropdownItem>
          <DropdownItem>Calendar</DropdownItem>
          <DropdownItem>Setting</DropdownItem> */}
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={this.props.onClickLogout}>
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    current: stores.appStore.current,
    logout: stores.appStore.logout,
    fetchInfo: stores.adminStore.fetchInfo,
    info: stores.adminStore.info,
    checkAdminEmpty: stores.appStore.checkAdminEmpty,
  })),

  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (
        props.location.pathname !== '/login' &&
        // props.location.pathname !== '/register' &&
        props.location.pathname !== '/forgot' &&
        props.location.pathname !== '/reset_password' &&
        !props.checkAdminEmpty
      ) {
        props.fetchInfo()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickLogout = async () => {
      await props.logout()
      props.history.push('/login')
      // window.location.reload()
    }

    const onClickEditProfile = () => {
      props.history.push(`/admins/${props.info.id}`)
    }

    return { loading, setLoading, onClickLogout, onClickEditProfile }
  })
)

export default enhancer(DropdownProfile)
