import styled from '@emotion/styled'
import React from 'react'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'
import { Caption, RequiredStar } from './Input.jsx'

const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const MainLayoutCheckBox = styled.div`
  display: flex;
  height: fit-content !important;
  align-items: flex-start !important;
`

const SubLayout = styled.div`
  margin-right: 20px;
`

const Label = styled.label`
  margin: 0px 5px;
`
const CustomCaption = styled(Caption)``

const CheckboxGroup = (props) => {
  return (
    <MainLayout>
      {props.caption && (
        <CustomCaption>
          {props.caption}
          {props.required && <RequiredStar>*</RequiredStar>}
        </CustomCaption>
      )}
      <MainLayoutCheckBox>
        {props.options.map(({ label, value }) => (
          <SubLayout className="checkbox checkbox-css" key={value}>
            <input
              type="checkbox"
              id={'CHECKBOX-GROUP' + value}
              checked={(props.field.value || []).includes(value)}
              onChange={(checked) => {
                props.onChange(value, checked.target.checked)
              }}
              disabled={props.readOnly}
            />
            <Label htmlFor={'CHECKBOX-GROUP' + value}>{label}</Label>
          </SubLayout>
        ))}
        {/* {props.meta.submitFailed && props.meta.error && (
        <div style={{ color: 'red' }}>* {props.meta.error}</div>
      )} */}
      </MainLayoutCheckBox>
    </MainLayout>
  )
}

const enhancer = compose(
  withHooks((props) => {
    const onChange = (value, checked) => {
      const data = (props.field.value || []).filter((v) => v !== value) || ''

      if (checked) {
        data.push(value)
      }

      props.form.setFieldValue(props.field.name, data)
    }

    return {
      onChange,
    }
  })
)

export default enhancer(CheckboxGroup)
