import { css } from '@emotion/core'
import styled from '@emotion/styled'
import classnames from 'classnames'
import { get } from 'lodash'
import React from 'react'
import InputMask from 'react-input-mask'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'

const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`
export const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const RequiredStar = styled.div`
  color: red;
`

const Input = (props) => (
  <MainLayout>
    {props.caption && (
      <Caption>
        {props.caption}
        {props.required && <RequiredStar>*</RequiredStar>}
      </Caption>
    )}
    <InputMask
      {...props.field}
      {...props}
      className={classnames({
        'form-control': !props.readOnly,
        [props.className]: props.className,
        'form-control-plaintext': props.readOnly,
      })}
      style={{ ...props.style }}
    />
  </MainLayout>
)

const CustomInput = styled(Input)`
  ${(props) =>
    props.valid &&
    css`
      border-radius: 4px;
      border: 1px solid #dc3545 !important;
    `}
`

const enhancer = compose(
  withHooks((props) => ({
    valid:
      get(props.form.errors, props.field.name) && props.form.submitCount > 0,
  }))
)

export default enhancer(CustomInput)
