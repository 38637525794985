import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { compose } from 'recompose'
// import { ValidateSchema } from '../show/index'
import * as yup from 'yup'
import {
  Button,
  ErrorMessageAlert,
  Image,
  Input,
} from '../../../components/index.js'
import { withHooks, withStores } from '../../../enhancers/index.js'

const CustomImage = styled(Image)`
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
  align-self: center;
  > section {
    width: 100% !important;
    height: 100% !important;
    background-color: darkgray;
    border-radius: 4px !important;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: none !important;
      > i {
        margin: unset;
      }

      > img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: none !important;
      }
    }
  }
`
const ImageLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`

const InputLayout = styled.div`
  display: flex;
  justify-items: center;
`

const Caption = styled.div`
  min-width: 185px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`
const RequiredStar = styled.div`
  color: red;
`

const CustomButton = styled(Button)`
  width: 64px;
`

export const Company = (props) => (
  <Modal isOpen={props.open} modalClassName="modal-message">
    <ModalHeader toggle={props.setIsOpenFalse}>Company</ModalHeader>
    <Form onSubmit={props.handleSubmit}>
      <ModalBody>
        <ImageLayout>
          <Field name="logoFile" component={CustomImage} />
        </ImageLayout>
        <InputLayout>
          <Caption>
            Name
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="name" component={Input} required />
        </InputLayout>
        <InputLayout>
          <Caption>
            Claim Phone Number
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field
            name="claimPhoneNumber"
            component={Input}
            required
            mask="(999) 999-9999"
          />
        </InputLayout>
        <InputLayout>
          <Caption>
            Tqm Reference Name
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name="tqmReferenceName" component={Input} />
        </InputLayout>
      </ModalBody>
      <ModalFooter>
        {props.isEdit && (
          <>
            <CustomButton
              outline
              color="danger"
              type="button"
              onClick={props.setConfirmDeleteToCon}
            >
              Delete
            </CustomButton>
            <CustomButton
              color="primary"
              type="button"
              onClick={props.setConfirmUpdateToCon}
              loading={props.isSubmitting}
            >
              Save
            </CustomButton>
          </>
        )}
        {!props.isEdit && (
          <CustomButton
            color="primary"
            type="submit"
            loading={props.isSubmitting}
          >
            Save
          </CustomButton>
        )}
        <CustomButton color="secondary" onClick={props.setIsOpenFalse}>
          Cancel
        </CustomButton>
      </ModalFooter>
    </Form>
    <ErrorMessageAlert
      open={
        !props.isSubmitting &&
        !props.isValid &&
        !isEmpty(props.errors) &&
        !props.isValidating
      }
      message={props.errors}
    />
    {props.confirmUpdate && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการบันทึกใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmUpdate}
        onCancel={props.setConfirmUpdateToCon}
      />
    )}
    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="ต้องการลบใช่หรือไม่ ?"
        onConfirm={props.onClickconfirmDelete}
        onCancel={props.setConfirmDeleteToCon}
      />
    )}
  </Modal>
)

export const ValidateSchema = yup.object().shape({
  name: yup.string().required('Name is Required'),
  claimPhoneNumber: yup.string().min(4).max(10).required(),
  tqmReferenceName: yup.string().required(),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchById: stores.companyStore.fetchById,
    current: stores.companyStore.current,
    update: stores.companyStore.update,
    fetch: stores.companyStore.fetch,
    delete: stores.companyStore.delete,
  })),
  withFormik({
    displayName: 'editCompany',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.companyId, values)
      await props.props.fetch()
      await props.props.setIsOpenFalse()
    },
    mapPropsToValues: (props) => {
      return { ...props.current }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    useEffect(() => {
      const promise = []
      if (props.open && props.current) {
        const getIamge = async () => {
          promise.push(
            fetch(props.current.logoUrl)
              .then((r) => r.blob())
              .then(
                (blobFile) =>
                  new File([blobFile], 'fileFromServer', {
                    type: 'image/png',
                  })
              )
              .then((result) =>
                Object.assign(result, {
                  preview: URL.createObjectURL(result),
                })
              )
              .then((result) => {
                props.setFieldValue('logoFile', result)
              })
          )

          await Promise.all(promise)
        }
        getIamge()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current, props.open])

    useEffect(() => {
      if (props.open) {
        props.fetchById(props.companyId)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.companyId, props.open])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    const setConfirmDeleteToCon = useCallback(() => {
      setConfirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onClickconfirmDelete = useCallback(async () => {
      await props.delete(props.companyId)
      await props.fetch()
      await props.setIsOpenFalse()
      setConfirmDelete(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.companyId])

    return {
      loading,
      setLoading,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      isEdit: true,
      setConfirmDeleteToCon,
      onClickconfirmDelete,
      confirmDelete,
    }
  })
)

export default enhancer(Company)
