import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class CompanyStore extends ModelStore {
  constructor() {
    super('companyStore', {
      all: [],
      current: [],
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get('/insurance_company')
    this.$setState('all', res.data.data.insuranceCompanies)
  }

  fetchById = async (params) => {
    const res = await tqm24Server.get(`/insurance_company/${params}`)
    this.$setState('current', res.data.data.insuranceCompany)
  }

  update = async (id, params) => {
    const res = await tqm24Server.put(`/insurance_company/${id}`, params)
    this.$setState('current', res.data.data.insuranceCompany)
  }

  create = async (params) => {
    await tqm24Server.post(`/insurance_company`, params)
  }

  delete = async (id) => {
    await tqm24Server.delete(`/insurance_company/${id}`)
  }
}

export default new CompanyStore()
