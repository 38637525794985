import React, { useCallback } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authorize as authorizeMethod } from '../enhancers/withAuthorized'

const PrivateRoute = ({ children, component: Component, ...rest }) => {
  const Children = useCallback(
    (props) => {
      const appStore = require('../stores/appStore').default
      const adminStore = require('../stores/adminStore').default
      const authorized = authorizeMethod({
        token: appStore.$getState('token'),
        adminRoles: adminStore.$getState('info'),
        roles: rest.roles,
      })

      return authorized ? (
        Component ? (
          <Component {...props} />
        ) : (
          children
        )
      ) : (
        <Redirect push to="/login" />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Component, children]
  )

  return <Route {...rest} component={Children} />
}

export default PrivateRoute
