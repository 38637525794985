import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { TitlePage } from '../../../components/index.js'
import { Panel, PanelHeader } from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'

const HeaderMainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`

const User = (props) => (
  <>
    <TitlePage text="User" textSmall="Can view user details" />
    <Panel>
      <PanelHeader noButton={true}>
        <HeaderMainLayout>Users</HeaderMainLayout>
      </PanelHeader>
      <ReactTable
        filterable
        data={props.all}
        columns={props.tableColumns}
        defaultPageSize={10}
        className="-highlight"
        getTdProps={props.onRowClick}
      />
    </Panel>
  </>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.userStore.fetch,
    all: stores.userStore.all,
  })),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const tableColumns = [
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Status',
        accessor: 'isCustomerVerify',
        Cell: (row) => (
          <>{row.row._original.isCustomerVerify ? 'verify' : 'Not verify'}</>
        ),
      },
      {
        Header: 'Last Active',
        accessor: 'lastActive',
      },
      {
        Header: 'Last Active Platform',
        accessor: 'lastActivePlatform',
      },
    ]

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: (e) => {
          if (rowInfo) {
            props.history.push(`/users/${rowInfo.original.id}`)
          }
        },
      }
    }
    return {
      loading,
      setLoading,
      onRowClick,
      tableColumns,
      textHeader: 'Life Product',
    }
  })
)

export default enhancer(User)
