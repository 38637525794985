import React from 'react'
import Admins from '../pages/admin/index'
import Admin from '../pages/admin/show'
import AppDashboardConfig from '../pages/AppConfig/appDashboardConfig'
import IconCarConfig from '../pages/AppConfig/IconCarConfig/index.js'
import LoyaltyConfig from '../pages/AppConfig/LoyaltyConfig'
import AuthMenuBanner from '../pages/AppConfig/otherConfig/AuthMenuBanner/index.js'
import ClaimMotorSolution from '../pages/AppConfig/otherConfig/ClaimMotorSolution'
import Consents from '../pages/AppConfig/otherConfig/Consent'
import NewConsent from '../pages/AppConfig/otherConfig/Consent/new'
import Consent from '../pages/AppConfig/otherConfig/Consent/show'
import SuccessBanner from '../pages/AppConfig/otherConfig/SuccessBanner/index.js'
import TermAndConditions from '../pages/AppConfig/otherConfig/TermAndCondition'
import NewTermAndCondition from '../pages/AppConfig/otherConfig/TermAndCondition/new'
import TermAndCondition from '../pages/AppConfig/otherConfig/TermAndCondition/show'
import ReportProblemConfig from '../pages/AppConfig/ReportProblemConfig/index.js'
import TelemedicineConfig from '../pages/AppConfig/TelemedicineConfig/index.js'
import Forgot from '../pages/auth/forgot'
import Login from '../pages/auth/login'
import Register from '../pages/auth/register'
import ResetPassword from '../pages/auth/resetPassword.js'
import Companies from '../pages/company/index'
import NewCompany from '../pages/company/new'
import Company from '../pages/company/show'
import Issue from '../pages/issue/index'
import Loyalty from '../pages/loyalty'
import NotificationHistories from '../pages/notification/history/index'
import NotificationHistory from '../pages/notification/history/show/index'
import Notifications from '../pages/notification/notification/index'
import NewNotification from '../pages/notification/notification/new'
import Notification from '../pages/notification/notification/show'
import NotificationScheduleTitle from '../pages/notificationSchedule/AddList'
import NotificationScheduleCategory from '../pages/notificationSchedule/Category'
import NotificationScheduleConsole from '../pages/notificationSchedule/Console/index.js'
import NotificationScheduleHistory from '../pages/report/notificationReport/index/index.js'
import RoyaltyReport from '../pages/report/royaltyReport'
import UserReport from '../pages/report/userReport'
import SpecialEvents from '../pages/specialEvent/index.js'
import Users from '../pages/user/index'
import User from '../pages/user/show'
import VirtualCards from '../pages/virtualCard/index'
import VirtualCard from '../pages/virtualCard/show'

const routes = [
  {
    path: '/register',
    exact: true,
    title: 'Register',
    roles: ['super_admin'],
    component: () => <Register />,
  },
  {
    path: '/login',
    exact: true,
    title: 'Login',
    roles: [],
    component: () => <Login />,
  },
  {
    path: '/forgot',
    exact: true,
    title: 'Forgot',
    roles: [],
    component: () => <Forgot />,
  },

  {
    path: '/reset_password',
    exact: true,
    title: 'Reset Password',
    roles: [],
    component: () => <ResetPassword />,
  },
  {
    path: '/admins',
    exact: true,
    title: 'Admins',
    roles: ['super_admin'],
    component: () => <Admins />,
  },
  {
    path: '/admins/:id',
    exact: true,
    title: 'Admin',
    roles: ['super_admin', 'admin'],
    component: () => <Admin />,
  },
  {
    path: '/users',
    exact: true,
    title: 'Users',
    roles: ['super_admin', 'admin'],
    component: () => <Users />,
  },
  {
    path: '/users/:id',
    exact: true,
    title: 'Users',
    roles: ['super_admin', 'admin'],
    component: () => <User />,
  },
  {
    path: '/loyalty/partners',
    exact: true,
    title: 'Loyalty Partners',
    roles: ['super_admin', 'admin'],
    component: () => <Loyalty src="partners" />,
  },
  {
    path: '/loyalty/categories',
    exact: true,
    title: 'Loyalty Categories',
    roles: ['super_admin', 'admin'],
    component: () => <Loyalty src="categories" />,
  },
  {
    path: '/loyalty/privileges',
    exact: true,
    title: 'Loyalty Privileges',
    roles: ['super_admin', 'admin'],
    component: () => <Loyalty src="privileges" />,
  },
  {
    path: '/virtual_card',
    exact: true,
    title: 'Virtual Card',
    roles: ['super_admin', 'admin'],
    component: () => <VirtualCards />,
  },
  {
    path: '/virtual_card/:id',
    exact: true,
    title: 'Virtual Card',
    roles: ['super_admin', 'admin'],
    component: () => <VirtualCard />,
  },
  {
    path: '/company',
    exact: true,
    title: 'Company',
    roles: ['super_admin', 'admin'],
    component: () => <Companies />,
  },
  {
    path: '/company/new',
    exact: true,
    title: 'Company',
    roles: ['super_admin', 'admin'],
    component: () => <NewCompany />,
  },
  {
    path: '/company/:id',
    title: 'Company',
    roles: ['super_admin', 'admin'],
    component: () => <Company />,
  },
  {
    path: '/app_dashboard_config',
    exact: true,
    title: 'App Dashboard Config',
    roles: ['super_admin', 'admin'],
    component: () => <AppDashboardConfig />,
  },
  {
    path: '/icon_config',
    exact: true,
    title: 'Icon Config',
    roles: ['super_admin', 'admin'],
    component: () => <IconCarConfig />,
  },
  {
    path: '/telemedicine_config',
    exact: true,
    title: 'Telemedicine Config',
    roles: ['super_admin', 'admin'],
    component: () => <TelemedicineConfig />,
  },
  {
    path: '/report_problem_config',
    exact: true,
    title: 'Report Problem Config',
    roles: ['super_admin', 'admin'],
    component: () => <ReportProblemConfig />,
  },
  {
    path: '/loyalty_config',
    exact: true,
    title: 'Loyalty Config',
    roles: ['super_admin', 'admin'],
    component: () => <LoyaltyConfig />,
  },
  {
    path: '/notification/config',
    exact: true,
    title: 'Notification',
    roles: ['super_admin', 'admin'],
    component: () => <Notifications />,
  },
  {
    path: '/notification/config/new',
    exact: true,
    title: 'Notification',
    roles: ['super_admin', 'admin'],
    component: () => <NewNotification />,
  },
  {
    path: '/notification/config/:id',
    title: 'Notification',
    roles: ['super_admin', 'admin'],
    component: () => <Notification />,
  },
  {
    path: '/notification/history',
    exact: true,
    title: 'Notification History',
    roles: ['super_admin', 'admin'],
    component: () => <NotificationHistories />,
  },
  {
    path: '/notification/history/:id',
    exact: true,
    title: 'Notification History',
    roles: ['super_admin', 'admin'],
    component: () => <NotificationHistory />,
  },
  {
    path: '/special_event',
    exact: true,
    title: 'Special Event',
    roles: ['super_admin', 'admin'],
    component: () => <SpecialEvents />,
  },
  {
    path: '/other/auth_menu_banner_config',
    exact: true,
    title: 'Auth Menu Banner',
    roles: ['super_admin', 'admin'],
    component: () => <AuthMenuBanner />,
  },
  {
    path: '/other/success_banner_config',
    exact: true,
    title: 'Success Banner Banner',
    roles: ['super_admin', 'admin'],
    component: () => <SuccessBanner />,
  },
  {
    path: '/other/consent_config',
    exact: true,
    title: 'Consent',
    roles: ['super_admin', 'admin'],
    component: () => <Consents />,
  },
  {
    path: '/other/consent_config/new',
    exact: true,
    title: 'Consent',
    roles: ['super_admin', 'admin'],
    component: () => <NewConsent isNew={true} />,
  },
  {
    path: '/other/consent_config/:id',
    exact: true,
    title: 'Consent',
    roles: ['super_admin', 'admin'],
    component: () => <Consent />,
  },
  {
    path: '/other/term_and_condition_config',
    exact: true,
    title: 'Term And Condition',
    roles: ['super_admin', 'admin'],
    component: () => <TermAndConditions />,
  },
  {
    path: '/other/term_and_condition_config/new',
    exact: true,
    title: 'Term And Condition',
    roles: ['super_admin', 'admin'],
    component: () => <NewTermAndCondition isNew={true} />,
  },
  {
    path: '/other/term_and_condition_config/:id',
    exact: true,
    title: 'Term And Condition',
    roles: ['super_admin', 'admin'],
    component: () => <TermAndCondition />,
  },
  {
    path: '/other/claim_motor_solution',
    exact: true,
    title: 'Emergency Call',
    roles: ['super_admin', 'admin'],
    component: () => <ClaimMotorSolution />,
  },
  {
    path: '/notification_schedule/config',
    exact: true,
    title: 'Notification Schedule Console',
    roles: ['super_admin', 'admin'],
    component: () => <NotificationScheduleConsole />,
  },
  {
    path: '/report/notification_report',
    exact: true,
    title: 'Notification Schedule History',
    roles: ['super_admin', 'admin'],
    component: () => <NotificationScheduleHistory />,
  },
  {
    path: '/notification_schedule/category',
    exact: true,
    title: 'Notification Schedule History',
    roles: ['super_admin', 'admin'],
    component: () => <NotificationScheduleCategory />,
  },
  {
    path: '/notification_schedule/title',
    exact: true,
    title: 'Notification Schedule History',
    roles: ['super_admin', 'admin'],
    component: () => <NotificationScheduleTitle />,
  },
  {
    path: '/issue',
    exact: true,
    title: 'Issue',
    roles: ['super_admin', 'admin'],
    component: () => <Issue />,
  },
  {
    path: '/report/user_report',
    exact: true,
    title: 'report',
    roles: ['super_admin', 'admin'],
    component: () => <UserReport />,
  },
  {
    path: '/report/royalty_report',
    exact: true,
    title: 'report',
    roles: ['super_admin', 'admin'],
    component: () => <RoyaltyReport />,
  },
]

export default routes
