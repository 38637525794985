import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class LoyaltyReportStore extends ModelStore {
  constructor() {
    super('loyaltyReportStore', {
      all: [],
      current: [],
      configs: [],
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get(`/loyalty_report`, params)
    this.$setState('all', res.data.data)
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get(`/loyalty_report/configs`)
    this.$setState('configs', res.data.data.configs.attributes)
  }

  exportExcel = async (params) => {
    await tqm24Server.download(`/loyalty_report/excel`, params)
  }
}

export default new LoyaltyReportStore()
