import styled from '@emotion/styled'
import moment from 'moment'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime/css/react-datetime.css'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'
const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`
export const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const RequiredStar = styled.div`
  color: red;
`

const CustomDateTime = styled(DateTimePicker)`
  width: 100%;
  > input {
    height: 40px !important;
  }
`

const Date = (props) => (
  <MainLayout>
    {props.caption && (
      <Caption>
        {props.caption}
        {props.required && <RequiredStar>*</RequiredStar>}
      </Caption>
    )}
    <CustomDateTime
      {...props}
      value={props.field.value || moment()}
      onChange={(e) => {
        props.form.setFieldValue(props.field.name, e)
      }}
      onBlur={(e) => {
        props.form.setFieldValue(props.field.name, e)
      }}
      minDate
      closeOnSelect={true}
    />
  </MainLayout>
)

const enhancer = compose(withHooks((props) => ({})))

export default enhancer(Date)
