import CKEditor from 'ckeditor4-react'
import React, { useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../enhancers/index.js'

const CKEditorInput = (props) => (
  <CKEditor
    {...props.field}
    {...props}
    data={props.field.value}
    config={{
      fullPage: true,
      allowedContent: true,
      width: props.width || '100%',
      height: props.height || '100%',
      resize_dir: 'both',
      removeButtons:
        'Paste,PasteText,PasteFromWord,Cut,Copy,Undo,Redo,Link,Unlink,Anchor,Table,InsertPre,Bold,Italic,Underline,Strike,Subscript,Superscript,RemoveFormat,Format,Styles,FontSize,Font,NumberedList,BulletedList,Outdent,Indent,Blockquote,About,Checkbox,SpecialChar,HorizontalRule,Scayt',
    }}
    disabled
    onChange={(e) => {
      props.form.setFieldValue(props.field.name, e.editor.getData())
    }}
    onBlur={(e) => {
      props.form.setFieldValue(props.field.name, e.editor.getData())
    }}
  />
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(CKEditorInput)
