import styled from '@emotion/styled'
import moment from 'moment'
import React, { useCallback } from 'react'
import DateTime from 'react-datetime'
import { compose } from 'recompose'
import { Button } from '../../../../components'
import { withHooks } from '../../../../enhancers'

const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`
export const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const RequiredStar = styled.div`
  color: red;
`

const CustomDateTime = styled(DateTime)`
  width: 100%;
  > input {
    height: 40px !important;
    border-radius: 4px;
  }
`
const ButtonNow = styled(Button)`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InputLayout = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  border: unset;
  pointer-events: none;
`
const IconLayout = styled.div`
  margin-right: 13px;
`
const Icon = styled.i`
  color: #acb1bf;
`

const RenderView = ({ mode, renderDefault, ...props }) => {
  return (
    <div className="wrapper">
      <ButtonNow
        onClick={() => props.setFieldValue(props.name, moment()._d)}
        color="outline-primary"
      >
        Now
      </ButtonNow>
    </div>
  )
}

const Date = (props) => (
  <MainLayout>
    {props.caption && (
      <Caption>
        {props.caption}
        {props.required && <RequiredStar>*</RequiredStar>}
      </Caption>
    )}
    <CustomDateTime
      // {...props}
      className={props.className}
      name={props.field.name}
      onChange={(e) => {
        props.form.setFieldValue(props.field.name, moment(e._d))
      }}
      renderInput={props.renderInput}
      renderView={(mode, renderDefault) => (
        <>
          {renderDefault()}
          <RenderView
            setFieldValue={props.form.setFieldValue}
            name={props.field.name}
          />
        </>
      )}
    />
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    const renderInput = useCallback(
      (inputProp, openCalendar, closeCalendar) => (
        <InputLayout onClick={openCalendar}>
          <Input
            {...inputProp}
            value={
              props.field.value
                ? moment(props.field.value).format('Do/MM/YYYY HH:mm')
                : ''
            }
          />
          <IconLayout>
            <Icon className="far fa-calendar" />
          </IconLayout>
        </InputLayout>
      ), // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.field.value]
    )
    // useEffect(() => {
    //   if (!props.field.value) {
    //     props.form.setFieldValue(props.field.name, moment())
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    return { renderInput }
  })
)

export default enhancer(Date)
