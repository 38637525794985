import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class IssueStore extends ModelStore {
  constructor() {
    super('issueStore', {
      all: [],
      current: [],
      configs: [],
    })
  }

  fetch = async () => {
    const res = await tqm24Server.get(`/issue`)
    this.$setState('all', res.data.data.issue)
  }

  fetchById = async (id) => {
    const res = await tqm24Server.get(`/issue/${id}`)
    this.$setState('current', res.data.data.issue)
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get(`/issue/configs`)
    this.$setState('configs', res.data.data.issue)
  }

  // create = async (params) => {
  //   await tqm24Server.post(`/special_events`, params)
  // }

  update = async (id, params) => {
    const res = await tqm24Server.put(`/special_events/${id}`, params)
    this.$setState('current', res.data.data.specialEvents)
  }

  delete = async (id) => {
    await tqm24Server.delete(`/special_events/${id}`)
  }
}

export default new IssueStore()
