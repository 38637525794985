import styled from '@emotion/styled'
import IframeResizer from 'iframe-resizer-react'
import { compose, withProps, withState } from 'recompose'
import { omitProps } from '../enhancers/index'
import env from '../env'

const IFrame = styled(IframeResizer)``

IFrame.OldSite = compose(
  withState(
    'id',
    'setId',
    (props) => props.id || `iframe_${parseInt(Math.random() * 10000)}`
  ),
  withProps((props) => ({
    src: `${env.oldSiteRoot}/${props.src}?mode=content-only`,
  })),
  omitProps('setId'),
  withProps((props) => console.log('IFrame.OldSite:props', props))
)(IFrame)

export default IFrame
