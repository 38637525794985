import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as yup from 'yup'
import { Checkbox, Input } from '../../components/index.js'
import { PageSettings } from '../../config/page-settings.js'
import { withHooks, withStores } from '../../enhancers/index.js'

const RegisterSchema = yup.object().shape({
  email: yup.string().required('Email is Required'),
  password: yup.string().required('Password is Required'),
})

const CustomCheckBox = styled(Checkbox)`
  width: 60%;
  > div {
    > label {
      font-size: 0.875rem !important;
    }
  }
`

const Login = (props) => (
  <div className="login login-v1">
    <div className="login-container">
      <div className="login-header">
        <div className="brand">
          {/* <span className="logo"></span> */}
          <b>TQM - 24</b> Login
          <small>the most complete app management system</small>
        </div>
        <div className="icon">
          <i className="fa fa-lock"></i>
        </div>
      </div>
      <div className="login-body">
        <div className="login-content">
          <form className="margin-bottom-0" onSubmit={props.handleSubmit}>
            <div className="form-group m-b-20">
              <Field
                name="email"
                component={Input}
                type="text"
                className="form-control form-control-lg inverse-mode"
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group m-b-20">
              <Field
                name="password"
                component={Input}
                type="password"
                className="form-control form-control-lg inverse-mode"
                placeholder="Password"
                required
              />
            </div>
            {props.submitFailed && (
              <div style={{ color: 'red' }}>email หรือ password ไม่ถูกต้อง</div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Field
                name="agree"
                component={CustomCheckBox}
                label="Remember Me"
              />
              <Link to="/forgot" style={{ paddingTop: '5px' }}>
                Forgotten password?
              </Link>
            </div>
            <div className="login-buttons">
              <button
                type="submit"
                className="btn btn-success btn-block btn-lg"
                disabled={props.loading}
              >
                Login
              </button>
            </div>
            {/* <div className="m-t-20">
              Not a member yet? Click <Link to="/register">here</Link> to
              register.
            </div> */}
          </form>
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    login: stores.appStore.login,
    token: stores.appStore.token,
    initialValues: {
      email: localStorage.getItem('email'),
      agree: localStorage.getItem('agree'),
    },
  })),
  withFormik({
    displayName: 'login',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: RegisterSchema,
    handleSubmit: async (values, props) => {
      await props.props.login(values)
      if (values.agree) {
        localStorage.setItem('email', values.username)
        localStorage.setItem('agree', true)
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('agree')
      }
      await props.props.history.push('/admins')
      // window.location.reload()
    },
    mapPropsToValues: (props) => {
      const username = localStorage.getItem('email')
      const agree = localStorage.getItem('agree')
      return { username, agree }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const context = useContext(PageSettings)

    useEffect(() => {
      context.handleSetPageSidebar(false)
      context.handleSetPageHeader(false)
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageSidebar(true)
        context.handleSetPageHeader(true)
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading }
  })
)

export default enhancer(Login)
