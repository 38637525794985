import { withFormik } from 'formik'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers/index.js'
import ConsentForm from '../components/form.js'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    create: stores.consentStore.create,
    clear: stores.consentStore.clearCurrent
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'newConsent',
  }),
  withHooks((props) => {
    useEffect(() => {
      props.clear()
    }, [])

    return {}
  })
)

export default enhancer(ConsentForm)
