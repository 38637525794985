import styled from '@emotion/styled'
import { Field } from 'formik'
import React, { useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { Button, Select } from '../../../components/index'
import { withHooks, withStores } from '../../../enhancers/index.js'

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  border: 1px solid #eff1f5;
`

const CustomSelect = styled(Select)`
  border-radius: 4px;
  width: 100%;

  > div:first-of-type {
    height: 56px;
    > div:last-of-type {
      > span {
        /* display: none; */
      }
    }
  }

  > :last-of-type {
    z-index: 2;
  }
`

const InputLayout = styled.div`
  width: 24%;
  margin-right: 1%;
`

const ButtonLayout = styled.div`
  width: 49%;
  margin-right: 1%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const CustomButton = styled(Button)`
  margin-left: 8px;
`

const Caption = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 8px;
`

const Filter = (props) => (
  <MainLayout>
    <InputLayout>
      <Caption>Category</Caption>
      <Field
        name="category"
        component={CustomSelect}
        options={props.categoryOptions}
        placeholder="Search"
        onKeyDown={props.selectOnKeyDown}
        onChange={props.onChangeCategory}
      />
    </InputLayout>
    <InputLayout>
      <Caption>Title</Caption>
      <Field
        name="title"
        component={CustomSelect}
        options={props.titleOptions}
        placeholder="Search"
        onKeyDown={props.selectOnKeyDown}
      />
    </InputLayout>

    <ButtonLayout>
      <CustomButton color="primary" type="submit">
        <i className="fas fa-check" /> Submit
      </CustomButton>
      <CustomButton color="secondary" type="button" onClick={props.resetFilter}>
        <i className="fas fa-sync" /> Clear
      </CustomButton>
      <CustomButton color="success" type="button" onClick={props.exportExcel}>
        <i className="far fa-file-excel" />
      </CustomButton>
    </ButtonLayout>
  </MainLayout>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.notificationScheduleStore.fetchTitle,
    exportExcel: stores.notificationScheduleStore.exportExcelTitle,
  })),
  withHooks((props) => {
    const [titleOptions, setTitleOptions] = useState([])
    const resetFilter = useCallback(async () => {
      await props.setLoading(true)
      await props.setSort([])
      await props.fetch({ pageSize: '25', page: '1', sort: 'created_at DESC' })
      await props.resetForm()
      await props.setLoading(false)
      setTitleOptions([])
    }, [props])

    const exportExcel = useCallback(() => {
      props.exportExcel({
        ...props.values,
      })
    }, [props])

    const selectOnKeyDown = useCallback((e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        return false
      }
    }, [])

    const onChangeCategory = useCallback(
      (value) => {
        props.setFieldValue('title', undefined)
        if (value) {
          props.setFieldValue('category', value.value)
          const titleOptions = props.categoryOptions.find(
            (item) => item.value === value.value
          ).titleOptions
          setTitleOptions(titleOptions)
        } else {
          setTitleOptions([])
          props.setFieldValue('category', value)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.categoryOptions]
    )

    return {
      resetFilter,
      exportExcel,
      selectOnKeyDown,
      onChangeCategory,
      titleOptions,
    }
  })
)

export default enhancer(Filter)
