import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import humps from 'humps'
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as yup from 'yup'
import { ErrorMessageAlert, Input } from '../../components/index.js'
import { PageSettings } from '../../config/page-settings.js'
import { withHooks, withStores } from '../../enhancers/index.js'

const CustomInput = styled(Input)`
  height: 43px;
  font-size: 0.875rem;
`

const ButtonGroup = styled.div`
  display: flex;
`

const ButtonConfirm = styled.button`
  height: 43px;
  width: 100%;
`

const Forgot = (props) => (
  <div className="login login-v1">
    <div className="login-container">
      <div className="login-header">
        <div className="brand">
          {/* <span className="logo"></span>  */}
          <b>TQM - 24</b> Reset Password
          <small>the most complete app management system</small>
        </div>
        <div className="icon">
          <i className="fa fa-lock"></i>
        </div>
      </div>
      <div className="login-body">
        <div className="login-content">
          <form className="margin-bottom-0" onSubmit={props.handleSubmit}>
            <div className="form-group m-b-20">
              <Field
                name="password"
                component={CustomInput}
                type="password"
                placeholder="Password"
                required
              />
              <Field
                name="confirmPassword"
                component={CustomInput}
                type="password"
                placeholder="Confirm Password"
                required
              />
            </div>
            <ButtonGroup>
              <ButtonConfirm
                type="submit"
                className="btn btn-success"
                disabled={props.loading}
              >
                Confirm
              </ButtonConfirm>
            </ButtonGroup>
            <ErrorMessageAlert
              open={
                !props.isSubmitting &&
                !props.isValid &&
                !isEmpty(props.errors) &&
                !props.isValidating
              }
              message={props.errors}
            />
          </form>
        </div>
      </div>
    </div>
  </div>
)

const ResetSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Please Enter less then 8 letters')
    .required('Password is Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    resetPassword: stores.appStore.resetPassword,
    // token: stores.appStore.token,
  })),
  withFormik({
    displayName: 'resetPassword',
    validationSchema: ResetSchema,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      await props.props.resetPassword(values)
      props.props.history.push('/login')
    },
    mapPropsToValues: (props) => {
      return { ...humps.camelizeKeys(queryString.parse(props.location.search)) }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const context = useContext(PageSettings)

    useEffect(() => {
      context.handleSetPageSidebar(false)
      context.handleSetPageHeader(false)
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageSidebar(true)
        context.handleSetPageHeader(true)
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToLoginPage = useCallback(() => {
      props.history.push('/login')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading, goToLoginPage }
  })
)

export default enhancer(Forgot)
