import styled from '@emotion/styled'
import { FieldArray, Form, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  ErrorMessageAlert,
  Loading,
  TitlePage
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import ModalAddTelemedicine from './telemedicineDetail/ModalAddTelemedicine'
import TelemedicineDetail from './telemedicineDetail/TelemedicineDetail'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const SubDashboard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const IconPlus = styled.i`
  font-size: 70px;
  color: #00aeef;
`

const TitleText = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const MainTelemedicine = styled.div``

const TelemedicineAdd = styled.div`
  width: 388px;
  height: 219px;
  margin-bottom: 6px;
  background: #cae6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
`

const TelemedicineConfig = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage text="App Config" textSmall="Can view and edit app config." />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Telemedicine Config</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <MainTelemedicine>
            <TitleText>Telemedicine (16:9)</TitleText>
            <SubDashboard>
              {!props.isEdit && (
                <TelemedicineAdd onClick={props.setOpenTrueAds}>
                  <IconPlus className="fas fa-plus" />
                </TelemedicineAdd>
              )}
              <FieldArray
                name="telemedicines"
                render={(FieldArrayProps) => (
                  <>
                    {get(FieldArrayProps, 'form.values.telemedicines', []).map(
                      (item, index) => (
                        <TelemedicineDetail
                          {...FieldArrayProps}
                          item={item}
                          index={index}
                          openAdd={props.openAds}
                          setOpenFalseAdd={props.setOpenFalseAds}
                          key={index}
                          configs={props.configs}
                        />
                      )
                    )}
                    <ModalAddTelemedicine
                      open={props.openAds}
                      setIsOpenFalse={props.setOpenFalseAds}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
            </SubDashboard>
          </MainTelemedicine>
        </PanelBody>
      </Panel>
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
    </MainLayout>
  </Loading>
)

export const ValidateSchema = yup.object().shape({
  telemedicines: yup.object(),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.telemedicineStore.fetch,
    fetchConfig: stores.appConfigStore.fetchConfig,
    configs: stores.appConfigStore.configs,
    current: stores.telemedicineStore.current,
    update: stores.telemedicineStore.update,
  })),
  withState('loading', 'setLoading', true),
  withFormik({
    displayName: 'editTelemedicineConfig',
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      props.props.setLoading(true)
      await props.props.update(values)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    // const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [openAds, setOpenAds] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      const promiseAds = []
      props.setLoading(true)
      const getIamge = async () => {
        await get(props, 'current.telemedicines', []).forEach((item, index) => {
          promiseAds.push(
            fetch(item.imageUrl)
              .then((r) => r.blob())
              .then(
                (blobFile) =>
                  new File([blobFile], 'fileFromServer', {
                    type: 'image/png',
                  })
              )
              .then((result) =>
                Object.assign(result, {
                  preview: URL.createObjectURL(result),
                })
              )
              .then((result) => {
                props.setFieldValue(`telemedicines[${index}].imageFile`, result)
              })
          )
        })

        await Promise.all(promiseAds)
        props.setLoading(false)
      }
      getIamge()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current])

    useEffect(() => {
      const getData = async () => {
        await props.fetch()
        await props.fetchConfig()
        props.setLoading(false)
      }
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalse = useCallback(() => {
      setOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrue = useCallback(() => {
      setOpen(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseAds = useCallback(() => {
      setOpenAds(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueAds = useCallback(() => {
      setOpenAds(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return {
      open,
      setOpenFalse,
      setOpenTrue,
      setOpenFalseAds,
      setOpenTrueAds,
      openAds,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
    }
  })
)

export default enhancer(TelemedicineConfig)
