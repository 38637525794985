import styled from '@emotion/styled'
import { get } from 'lodash'
import React from 'react'

const PageTitle = styled.h1``
const PageTitleSmall = styled.small``

const Title = (props) => (
  <>
    <PageTitle className="page-header">
      {get(props, 'text', '')}{' '}
      <PageTitleSmall>{get(props, 'textSmall', '')}</PageTitleSmall>
    </PageTitle>
  </>
)

export default Title
