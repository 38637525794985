import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { compose, withState } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  CKEditor, ErrorMessageAlert, Input,
  Loading, TitlePage
} from '../../../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../../../components/panel/panel.jsx'
import withHooks from '../../../../../enhancers/withHooks.js'
import Datetime from '../../../../report/notificationReport/index/Datetime.js'

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGroup = styled.div`
  display: flex;
`
const ButtonGroupHeader = styled.div``
const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`
const ImageLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`
const InputLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const CustomField = styled(Field)`
  width: 200px;
`
const FieldLayout = styled.div`
  margin-right: 15px;
`
const Caption = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 15px;
`

const ConsentForm = (props) => (
  <Loading>
    <TitlePage text="Consent" textSmall="Can create, view and edit consent." />
    <Form onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Consent</TextHeader>
            <HeaderRightGroup>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGroup>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <InputLayout>
            <FieldLayout>
              <CustomField
                name="version"
                component={Input}
                caption="Version"
                required
              />
            </FieldLayout>
            <FieldLayout>
              <CustomField
                name="tqmCode"
                component={Input}
                caption="TQM Code"
                required
              />
            </FieldLayout>
            <FieldLayout>
              <CustomField
                name="activatedAt"
                component={Datetime}
                caption="Activated At"
                // required
              />
            </FieldLayout>
          </InputLayout>
          <ImageLayout>
            <Field name="detail" component={CKEditor} />
          </ImageLayout>
        </PanelBody>
      </Panel>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickConfirm}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
      <ErrorMessageAlert
        open={
          props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
    </Form>
  </Loading>
)

export const ValidateSchema = yup.object().shape({
  version: yup.string().required('version is a required'),
  tqmCode: yup.string().required('TQM Code is a required'),
})

const enhancer = compose(
  withState('loading', 'setLoading', false),
  withState('isSubmitting', 'setSubmitting', false),
  withFormik({
    displayName: 'consentForm',
    enableReinitialize: true,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      props.props.setLoading(true)
      if (props.props.isNew) {
        await props.props.create({
          ...values,
        })
        props.props.history.push(`/other/consent_config`)
      } else {
        await props.props.update(
          props.props.match.params.id,
          {
            ...values,
          }
        )
        props.props.history.push(`/other/consent_config`)
      }
      props.props.setLoading(false)
      props.props.setSubmitting(false)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current?.consent,
      }
    },
  }),
  withHooks((props) => {
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickConfirm = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
    }, [props.handleSubmit])

    return {
      setConfirmUpdateToCon,
      onClickConfirm,
      confirmUpdate,
    }
  })
)

export default enhancer(ConsentForm)
