import styled from '@emotion/styled'
import { FieldArray, Form, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  ErrorMessageAlert,
  Loading,
  TitlePage
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import AdsBannerDetail from './adsBanner/AdsBannerDetail'
import ModalAddAdsBanner from './adsBanner/ModalAddAdsBanner'
import FirstPopupDetail from './firstPopup/FirstPopupDetail'
import ModalAddFirstPopup from './firstPopup/ModalAddFirstPopup'
import ModalAddOtherMenu from './otherMenu/ModalAddOtherMenu'
import OtherMenuDetail from './otherMenu/OtherMenuDetail'
import ModalAddPromotion from './promotion/ModalAddPromotion'
import PromotionDetail from './promotion/PromotionDetail'
import MenuDetail from './topMenu/MenuDetail'
import ModalAddMenu from './topMenu/ModalAddMenu'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const MainDashboard = styled.div`
  width: 100%;
  height: 415px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cae6ff;
    border-radius: 10px;
  }
`

const SubDashboard = styled.div`
  width: fit-content;
  display: flex;
`

const DashboardCardAdd = styled.div`
  width: 350px;
  height: 350px;
  background: #cae6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
`

const IconPlus = styled.i`
  font-size: 70px;
  color: #00aeef;
`

const MainMenuLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const SubMenuLayout = styled.div`
  width: 414px;
  min-height: 165px;
  background: #00aeef;
  border-radius: 0 0 220px 52%/70px;
  padding: 18px 25px 46px;
  display: grid;
  grid-template-columns: 71px 71px 71px 71px;
  grid-column-gap: 30px;
  grid-row-gap: 27px;
`
const AddCircle = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 0.8;
`
const IconPlusCircle = styled.i`
  font-size: 35px;
  color: #00aeef;
`

const TitleText = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: gray;
  opacity: 0.7;
  margin-top: 20px;
  margin-bottom: 20px;
`

const MainAdsBanner = styled.div`
  width: 100%;
  height: 284px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cae6ff;
    border-radius: 10px;
  }
`

const AdsBannerCardAdd = styled.div`
  width: 388px;
  height: 219px;
  background: #cae6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
`

const MainPromotion = styled.div`
  width: 100%;
  height: 206px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cae6ff;
    border-radius: 10px;
  }
`
const PromotionCardAdd = styled.div`
  width: 271px;
  height: 141px;
  background: #cae6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
`
const MainOtherMenuLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const SubOtherMenuLayout = styled.div`
  width: 414px;
  min-height: 165px;
  background: #00aeef;
  padding: 18px 25px 46px;
  display: grid;
  grid-template-columns: 71px 71px 71px 71px;
  grid-column-gap: 30px;
  grid-row-gap: 27px;
`

const AppDashboardConfig = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage text="App Config" textSmall="Can view and edit app config." />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>App Dashboard Config</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <MainDashboard>
            <TitleText>First Popup (352x424)</TitleText>
            <SubDashboard>
              <FieldArray
                name="dashboard.firstPopup.items"
                render={(FieldArrayProps) => (
                  <>
                    {get(
                      FieldArrayProps,
                      'form.values.dashboard.firstPopup.items',
                      []
                    ).map((item, index) => (
                      <FirstPopupDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.open}
                        setOpenFalseAdd={props.setOpenFalse}
                        key={index}
                        configs={props.configs}
                      />
                    ))}
                    <ModalAddFirstPopup
                      open={props.open}
                      setIsOpenFalse={props.setOpenFalse}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
              {!props.isEdit && (
                <DashboardCardAdd onClick={props.setOpenTrue}>
                  <IconPlus className="fas fa-plus" />
                </DashboardCardAdd>
              )}
            </SubDashboard>
          </MainDashboard>
          <Line />
          <TitleText>Menu (1:1)</TitleText>
          <MainMenuLayout>
            <SubMenuLayout>
              <FieldArray
                name="dashboard.topMenu.items"
                render={(FieldArrayProps) => (
                  <>
                    {get(
                      FieldArrayProps,
                      'form.values.dashboard.topMenu.items',
                      []
                    ).map((item, index) => (
                      <MenuDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.open}
                        setOpenFalseAdd={props.setOpenFalse}
                        key={index}
                        configs={props.configs}
                      />
                    ))}
                    <ModalAddMenu
                      open={props.openMenu}
                      setIsOpenFalse={props.setOpenFalseMenu}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
              {!props.isEdit && (
                <AddCircle onClick={props.setOpenTrueMenu}>
                  <IconPlusCircle className="fas fa-plus" />
                </AddCircle>
              )}
            </SubMenuLayout>
          </MainMenuLayout>
          <Line />
          <MainAdsBanner>
            <TitleText>Ads Banner (16:9)</TitleText>
            <SubDashboard>
              <FieldArray
                name="dashboard.adsBanner.items"
                render={(FieldArrayProps) => (
                  <>
                    {get(
                      FieldArrayProps,
                      'form.values.dashboard.adsBanner.items',
                      []
                    ).map((item, index) => (
                      <AdsBannerDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.openAds}
                        setOpenFalseAdd={props.setOpenFalseAds}
                        key={index}
                        configs={props.configs}
                      />
                    ))}
                    <ModalAddAdsBanner
                      open={props.openAds}
                      setIsOpenFalse={props.setOpenFalseAds}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
              {!props.isEdit && (
                <AdsBannerCardAdd onClick={props.setOpenTrueAds}>
                  <IconPlus className="fas fa-plus" />
                </AdsBannerCardAdd>
              )}
            </SubDashboard>
          </MainAdsBanner>
          <Line />
          <MainPromotion>
            <TitleText>Promotion (16:9)</TitleText>
            <SubDashboard>
              <FieldArray
                name="dashboard.promotion.items"
                render={(FieldArrayProps) => (
                  <>
                    {get(
                      FieldArrayProps,
                      'form.values.dashboard.promotion.items',
                      []
                    ).map((item, index) => (
                      <PromotionDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.openPromotion}
                        setOpenFalseAdd={props.setOpenFalsePromotion}
                        key={index}
                        configs={props.configs}
                      />
                    ))}
                    <ModalAddPromotion
                      open={props.openPromotion}
                      setIsOpenFalse={props.setOpenFalsePromotion}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
              {!props.isEdit && (
                <PromotionCardAdd onClick={props.setOpenTruePromotion}>
                  <IconPlus className="fas fa-plus" />
                </PromotionCardAdd>
              )}
            </SubDashboard>
          </MainPromotion>
          <Line />
          <TitleText>Other Menu (1:1)</TitleText>
          <MainOtherMenuLayout>
            <SubOtherMenuLayout>
              <FieldArray
                name="dashboard.otherMenu.items"
                render={(FieldArrayProps) => (
                  <>
                    {get(
                      FieldArrayProps,
                      'form.values.dashboard.otherMenu.items',
                      []
                    ).map((item, index) => (
                      <OtherMenuDetail
                        {...FieldArrayProps}
                        item={item}
                        index={index}
                        openAdd={props.openOtherMenu}
                        setOpenFalseAdd={props.setOpenFalseOtherMenu}
                        key={index}
                        configs={props.configs}
                      />
                    ))}
                    <ModalAddOtherMenu
                      open={props.openOtherMenu}
                      setIsOpenFalse={props.setOpenFalseOtherMenu}
                      push={FieldArrayProps.push}
                      configs={props.configs}
                    />
                  </>
                )}
              />
              {!props.isEdit && (
                <AddCircle onClick={props.setOpenTrueOtherMenu}>
                  <IconPlusCircle className="fas fa-plus" />
                </AddCircle>
              )}
            </SubOtherMenuLayout>
          </MainOtherMenuLayout>
        </PanelBody>
      </Panel>
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating &&
          props.submitCount > 0
        }
        message={props.errors}
      />
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
    </MainLayout>
  </Loading>
)

export const ValidateSchema = yup.object().shape({
  dashboard: yup.object().shape({
    adsBanner: yup.object().shape({ items: yup.array().required() }).required(),
    firstPopup: yup
      .object()
      .shape({ items: yup.array().required() })
      .required(),
    otherMenu: yup.object().shape({ items: yup.array().required() }).required(),
    promotion: yup.object().shape({ items: yup.array().required() }).required(),
    topMenu: yup.object().shape({ items: yup.array().required() }).required(),
  }),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.appConfigStore.fetch,
    fetchConfig: stores.appConfigStore.fetchConfig,
    configs: stores.appConfigStore.configs,
    current: stores.appConfigStore.current,
    update: stores.appConfigStore.update,
  })),
  withState('loading', 'setLoading', true),
  withFormik({
    displayName: 'editAppDashboardConfig',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      // props.props.setLoading(true)
      const newFirstPopups = []
      get(values, 'dashboard.firstPopup.items', []).forEach((item) => {
        if (item.imageUrl) {
          newFirstPopups.push(item)
        } else {
          newFirstPopups.push({ ...item, imageUrl: undefined })
        }
      })

      const newAds = []
      get(values, 'dashboard.adsBanner.items', []).forEach((item) => {
        if (item.imageUrl) {
          newAds.push(item)
        } else {
          newAds.push({ ...item, imageUrl: undefined })
        }
      })

      const newMenus = []
      get(values, 'dashboard.topMenu.items', []).forEach((item) => {
        if (item.iconUrl) {
          newMenus.push(item)
        } else {
          newMenus.push({ ...item, iconUrl: undefined })
        }
      })

      const newPromotion = []
      get(values, 'dashboard.promotion.items', []).forEach((item) => {
        if (item.imageUrl) {
          newPromotion.push(item)
        } else {
          newPromotion.push({ ...item, imageUrl: undefined })
        }
      })

      const otherMenus = []
      get(values, 'dashboard.otherMenu.items', []).forEach((item) => {
        if (item.iconUrl) {
          otherMenus.push(item)
        } else {
          otherMenus.push({ ...item, iconUrl: undefined })
        }
      })

      await props.props.update({
        dashboard: {
          firstPopup: { items: newFirstPopups },
          topMenu: { items: newMenus },
          adsBanner: { items: newAds },
          promotion: { items: newPromotion },
          otherMenu: { items: otherMenus },
        },
      })
      props.props.setLoading(false)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    // const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [openAds, setOpenAds] = useState(false)
    const [openPromotion, setOpenPromotion] = useState(false)
    const [openOtherMenu, setOpenOtherMenu] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      const promiseFirstPopup = []
      const promiseMenus = []
      const promiseAds = []
      const promisePromotion = []
      const promiseOtherMenu = []

      const getIamge = async () => {
        await get(props, 'current.dashboard.firstPopup.items', []).forEach(
          (item, index) => {
            promiseFirstPopup.push(
              fetch(item.imageUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue(
                    `dashboard.firstPopup.items[${index}].imageFile`,
                    result
                  )
                })
            )
          }
        )

        await get(props, 'current.dashboard.topMenu.items', []).forEach(
          (item, index) => {
            promiseMenus.push(
              fetch(item.iconUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue(
                    `dashboard.topMenu.items[${index}].iconFile`,
                    result
                  )
                })
            )
          }
        )

        await get(props, 'current.dashboard.adsBanner.items', []).forEach(
          (item, index) => {
            promiseAds.push(
              fetch(item.imageUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue(
                    `dashboard.adsBanner.items[${index}].imageFile`,
                    result
                  )
                })
            )
          }
        )

        await get(props, 'current.dashboard.promotion.items', []).forEach(
          (item, index) => {
            promisePromotion.push(
              fetch(item.imageUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue(
                    `dashboard.promotion.items[${index}].imageFile`,
                    result
                  )
                })
            )
          }
        )

        await get(props, 'current.dashboard.otherMenu.items', []).forEach(
          (item, index) => {
            promiseOtherMenu.push(
              fetch(item.iconUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue(
                    `dashboard.otherMenu.items[${index}].iconFile`,
                    result
                  )
                })
            )
          }
        )

        await Promise.all(promiseFirstPopup)
        await Promise.all(promiseMenus)
        await Promise.all(promiseAds)
        await Promise.all(promisePromotion)
        await Promise.all(promiseOtherMenu)
        props.setLoading(false)
      }
      getIamge()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current])

    useEffect(() => {
      props.setLoading(true)
      props.fetch()
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalse = useCallback(() => {
      setOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrue = useCallback(() => {
      setOpen(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseMenu = useCallback(() => {
      setOpenMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueMenu = useCallback(() => {
      setOpenMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseAds = useCallback(() => {
      setOpenAds(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueAds = useCallback(() => {
      setOpenAds(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalsePromotion = useCallback(() => {
      setOpenPromotion(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTruePromotion = useCallback(() => {
      setOpenPromotion(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseOtherMenu = useCallback(() => {
      setOpenOtherMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueOtherMenu = useCallback(() => {
      setOpenOtherMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return {
      open,
      setOpenFalse,
      setOpenTrue,
      setOpenFalseMenu,
      setOpenTrueMenu,
      openMenu,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      setOpenFalseAds,
      setOpenTrueAds,
      setOpenFalsePromotion,
      setOpenTruePromotion,
      openAds,
      openPromotion,
      setOpenFalseOtherMenu,
      setOpenTrueOtherMenu,
      openOtherMenu,
    }
  })
)

export default enhancer(AppDashboardConfig)
