import { get } from 'lodash'
import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../enhancers/index.js'
import { PageSettings } from './../../config/page-settings.js'

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profileActive: 0,
    }
    this.handleProfileExpand = this.handleProfileExpand.bind(this)
  }

  handleProfileExpand(e) {
    e.preventDefault()
    this.setState((state) => ({
      profileActive: !this.state.profileActive,
    }))
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li
              className={
                'nav-profile ' + (this.state.profileActive ? 'expand ' : '')
              }
            >
              <Link to="/" onClick={this.handleProfileExpand}>
                <div className="cover with-shadow"></div>
                <div className="image">
                  <img src="/assets/img/user/user-13.jpg" alt="" />
                </div>
                <div className="info">
                  {/* <b className="caret pull-right"></b> */}
                  {get(this.props, 'info.roles[0]', 0) === 'super_admin' &&
                    'Super admin'}
                  {get(this.props, 'info.roles[0]', 0) === 'admin' && 'Admin'}
                  <small>
                    {get(this.props, 'info.roles[0]', 0) === 'super_admin' &&
                      'Super admin can do all features and manage admin'}
                    {get(this.props, 'info.roles[0]', 0) === 'admin' &&
                      'Admin can do all features'}
                  </small>
                </div>
              </Link>
            </li>
            {/* <li>
							<ul className={"nav nav-profile " + (this.state.profileActive && !pageSidebarMinify ? "d-block " : "")}>
								<li><Link to="/"><i className="fa fa-cog"></i> Settings</Link></li>
								<li><Link to="/"><i className="fa fa-pencil-alt"></i> Send Feedback</Link></li>
								<li><Link to="/"><i className="fa fa-question-circle"></i> Helps</Link></li>
							</ul>
						</li> */}
          </ul>
        )}
      </PageSettings.Consumer>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    info: stores.adminStore.info,
  })),

  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    return { loading, setLoading }
  })
)

export default enhancer(SidebarProfile)
