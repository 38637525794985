import { AUTH_TOKEN_KEY, tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class AppStore extends ModelStore {
  constructor() {
    super('appStore', {
      token: localStorage.getItem(AUTH_TOKEN_KEY) || '',
      current: [],
      checkAdminEmpty: false,
    })
  }

  login = async (params) => {
    const response = await tqm24Server.post('/auth/sign_in', params)

    this.$setState('token', response.data.data.token)

    localStorage.setItem(AUTH_TOKEN_KEY, response.data.data.token)
  }

  logout = async (params) => {
    await localStorage.removeItem(AUTH_TOKEN_KEY)
  }

  register = async (params) => {
    const response = await tqm24Server.post('/auth/sign_up', params)

    this.$setState('token', response.data.data.token)

    localStorage.setItem(AUTH_TOKEN_KEY, response.data.data.token)
  }

  checkAdminEmptyApi = async () => {
    const response = await tqm24Server.get('/auth/check_empty_admin')
    this.$setState('checkAdminEmpty', response.data.data.empty)
  }

  resetPassword = async (params) => {
    await tqm24Server.post('/auth/reset_password', params)
  }

  forgot = async (params) => {
    await tqm24Server.put('/auth/forgot_password', params)
  }
}

export default new AppStore()
