import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import swal from 'sweetalert'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { Button, ErrorMessageAlert, Input } from '../../components/index.js'
import { PageSettings } from '../../config/page-settings.js'
import { withHooks, withStores } from '../../enhancers/index.js'

const RegisterSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is Required'),
  // password: yup
  //   .string()
  //   .min(3, 'Please Enter less then 3 letters')
  //   .required('Password is Required'),
})
const CustomInput = styled(Input)`
  height: 43px;
  font-size: 0.875rem;
`

const ButtonGroup = styled.div`
  display: flex;
`

const ButtonRegister = styled(Button)`
  height: 43px;
  width: 100%;
`
const Register = (props) => (
  <div className="login login-v1">
    <div className="login-container">
      <div className="login-header">
        <div className="brand">
          {/* <span className="logo"></span>  */}
          <b>TQM - 24</b> Register
          <small>the most complete app management system</small>
        </div>
        <div className="icon">
          <i className="fa fa-lock"></i>
        </div>
      </div>
      <div className="login-body">
        <div className="login-content">
          <form className="margin-bottom-0" onSubmit={props.handleSubmit}>
            <div className="form-group m-b-20">
              <Field
                name="email"
                component={CustomInput}
                type="text"
                placeholder="Email Address"
              />
            </div>

            {/* <div className="form-group m-b-20">
              <Field
                name="password"
                component={CustomInput}
                type="password"
                placeholder="Password"
              />
            </div> */}

            {/* <div className="form-group m-b-20">
                <Field
                  name="nameInfo.firstName"
                  component={CustomInput}
                  type="text"
                  placeholder="First Name"
                  required
                  readOnly={props.isEdit}
                />
              </div>

              <div className="form-group m-b-20">
                <Field
                  name="nameInfo.lastName"
                  component={CustomInput}
                  type="text"
                  placeholder="Last Name"
                  required
                  readOnly={props.isEdit}
                />
              </div> */}

            <ButtonGroup>
              <ButtonRegister
                type="submit"
                className="btn btn-success"
                disabled={props.loading}
              >
                Register
              </ButtonRegister>
            </ButtonGroup>
          </form>
        </div>
      </div>
    </div>
    <ErrorMessageAlert
      open={
        !props.isSubmitting &&
        !props.isValid &&
        !isEmpty(props.errors) &&
        !props.isValidating
      }
      message={props.errors}
    />
  </div>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    register: stores.appStore.register,
    token: stores.appStore.token,
    checkAdminEmpty: stores.appStore.checkAdminEmpty,
  })),
  withFormik({
    displayName: 'register',
    validationSchema: RegisterSchema,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      values = { ...values, referenceKey: uuidv4() }

      try {
        await props.props.register(values)
        swal(
          'Success',
          'Please check your email for setting a password.',
          'success'
        ).then((value) => {
          if (props.props.checkAdminEmpty) {
            window.location.href = '/login'
            props.props.history.push('/login')
          } else {
            props.props.history.push('/users')
          }
        })
      } catch (error) {
        swal('Fail', error, 'error')
      }
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const context = useContext(PageSettings)

    useEffect(() => {
      context.handleSetPageSidebar(false)
      context.handleSetPageHeader(false)
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageSidebar(true)
        context.handleSetPageHeader(true)
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToLoginPage = useCallback(() => {
      props.history.push('/login')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading, goToLoginPage }
  })
)

export default enhancer(Register)
