import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class UserStore extends ModelStore {
  constructor() {
    super('userStore', {
      all: [],
      current: [],
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get('/users')
    this.$setState('all', res.data.data.users)
  }

  fetchById = async (params) => {
    const res = await tqm24Server.get(`/users/${params}`)
    this.$setState('current', res.data.data.user)
  }
}

export default new UserStore()
