import styled from '@emotion/styled'
import React from 'react'

const MainLayout = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  height: 19px;
  display: flex;
  align-items: center;
  width: 40px;
  margin-left: 5px;
`

const Switch = (props) => {
  return (
    <MainLayout className="switcher switcher-success">
      <input
        id={props.field.name}
        checked={props.field.value}
        value={props.field.value}
        disabled={props.readOnly}
        {...props}
        {...props.field}
        type="checkbox"
      />
      <label htmlFor={props.field.name}></label>
      {props.label && (
        <>
          {props.field.value ? (
            <Label className="label label-info">{props.label[1]}</Label>
          ) : (
            <Label className="label label-danger">{props.label[0]}</Label>
          )}
        </>
      )}
    </MainLayout>
  )
}

export default Switch
