import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import {
  Button,
  CKEditor,
  Loading,
  TitlePage
} from '../../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../../enhancers/index.js'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const ImageLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`

const TermAndCondition = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage text="App Config" textSmall="Can view and edit app config." />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Claim Motor Solution</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <ImageLayout>
            <Field name="claimMotorSolution" component={CKEditor} />
          </ImageLayout>
        </PanelBody>
      </Panel>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
    </MainLayout>
  </Loading>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.otherStore.fetchClaimMotorSolution,
    current: stores.otherStore.currentClaimMotorSolution,
    update: stores.otherStore.updateClaimMotorSolution,
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'editClaimMotorSolution',
    validateOnBlur: false,
    validateOnChange: false,

    handleSubmit: async (values, props) => {
      props.props.setLoading(true)

      await props.props.update({
        others: {
          ...values,
        },
      })
      props.props.setLoading(false)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [openMenu, setOpenMenu] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetch()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseMenu = useCallback(() => {
      setOpenMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueMenu = useCallback(() => {
      setOpenMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return {
      setOpenFalseMenu,
      setOpenTrueMenu,
      openMenu,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
    }
  })
)

export default enhancer(TermAndCondition)
