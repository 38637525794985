import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { PrivateRoute } from '../../components/index.js'
import { withHooks, withStores } from '../../enhancers/index.js'
import routes from './../../config/page-route.jsx'
import { PageSettings } from './../../config/page-settings.js'

function setTitle(path, routeArray) {
  var pageTitle
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = 'TQM - 24 | ' + routeArray[i].title
    }
  }
  document.title = pageTitle ? pageTitle : 'TQM - 24 | React App'
}

class Content extends React.Component {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes)
  }
  UNSAFE_componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, routes)
    })
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div
            className={
              'content ' +
              (pageContentFullWidth ? 'content-full-width ' : '') +
              (pageContentInverseMode ? 'content-inverse-mode ' : '') +
              pageContentClass
            }
          >
            <Switch>
              {routes.map((route, index) => {
                const checkRoles =
                  this.props.info.roles &&
                  this.props.info.roles.some((roles) =>
                    route.roles.some((item) => roles === item)
                  )

                if (this.props.checkAdminEmpty && route.path === '/register') {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                } else if (!this.props.checkAdminEmpty) {
                  if (checkRoles || route.roles.length === 0) {
                    if (
                      route.path === '/login' ||
                      route.path === '/register' ||
                      route.path === '/forgot' ||
                      route.path === '/reset_password'
                    ) {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      )
                    } else {
                      return (
                        <PrivateRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          roles={route.roles}
                          info={route.info}
                          component={route.component}
                        />
                      )
                    }
                  }
                }

                return null
              })}
              {this.props.checkAdminEmpty && <Redirect push to="/register" />}
              {/* <Redirect push to="/users" /> */}
            </Switch>
          </div>
        )}
      </PageSettings.Consumer>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    token: stores.appStore.token,
    info: stores.adminStore.info,
    checkAdminEmptyApi: stores.appStore.checkAdminEmptyApi,
    checkAdminEmpty: stores.appStore.checkAdminEmpty,
  })),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      props.checkAdminEmptyApi()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading }
  })
)

export default enhancer(Content)
