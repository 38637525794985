import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../../enhancers/index.js'

const HeaderMainLayout = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`

const Notification = (props) => (
  <Panel>
    <PanelHeader noButton={true}>
      <HeaderMainLayout>
        <TextHeader>Notification</TextHeader>
      </HeaderMainLayout>
    </PanelHeader>
    <ReactTable
      filterable
      data={props.all}
      columns={props.tableColumns}
      defaultPageSize={10}
      className="-highlight"
      getTdProps={props.onRowClick}
    />
  </Panel>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchHistory: stores.notificationStore.fetchHistory,
    all: stores.notificationStore.histories,
  })),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const tableColumns = [
      {
        Header: 'Send Time',
        accessor: 'sendTime',
      },
      {
        Header: 'Send Status',
        accessor: 'sendStatus',
      },
      {
        Header: 'Send Type',
        accessor: 'sendType',
      },
      {
        Header: 'Remark',
        accessor: 'remake',
      },
    ]

    useEffect(() => {
      props.fetchHistory()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToNewPage = useCallback(() => {
      props.history.push('notification/new')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: (e) => {
          if (rowInfo) {
            props.history.push(`/notification/history/${rowInfo.original.id}`)
          }
        },
      }
    }
    return {
      loading,
      setLoading,
      onRowClick,
      tableColumns,
      goToNewPage,
    }
  })
)

export default enhancer(Notification)
