import styled from '@emotion/styled'
import { get } from 'lodash'
import moment from 'moment'
import 'moment/locale/th'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { compose } from 'recompose'
import { Button } from '../../../components/index.js'
import { withHooks, withStores } from '../../../enhancers/index.js'

const MainBody = styled.div`
  display: flex;
  flex-direction: column;
`
const BodySubLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`
const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
`
const TitleCustomer = styled.div`
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  color: #00aeef;
`
const Line = styled.div`
  border: 1px solid #eff1f5;
`
const Detail = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  margin-top: 8px;
`
const ImageLayout = styled.div`
  margin-top: 8px;
  display: flex;
`
const Img = styled.img`
  width: 100px;
  height: 100px;
`

const CustomButton = styled(Button)`
  width: 64px;
`
const DetailMini = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  margin-top: 8px;
  margin-left: 4px;
  color: #acb1bf;
`
const DetailLayout = styled.div`
  display: flex;
  align-items: baseline;
`

export const IssueModal = (props) => (
  <Modal isOpen={props.open}>
    <ModalHeader toggle={props.setIsOpenFalse}>Issue Detail</ModalHeader>

    <ModalBody>
      <MainBody>
        <BodySubLayout>
          <Title>เลขที่การแจ้งปัญหา</Title>
          <Line />
          <Detail>
            {get(props.current, 'issueNo', 'ไม่มี') || 'ไม่มีข้อมูล'}
          </Detail>
        </BodySubLayout>
        <BodySubLayout>
          <Title>ประเภทปัญหา</Title>
          <Line />
          <Detail>
            {
              (
                get(props.configs, 'attributes.issueType.options', []).find(
                  (item) =>
                    item.value === get(props.current, 'issueType', 'ไม่มี')
                ) || {}
              ).label
            }
          </Detail>
        </BodySubLayout>
        <BodySubLayout>
          <Title>สถานะของปัญหา</Title>
          <Line />
          <Detail>
            {get(props.current, 'status', 'ไม่มี') || 'ไม่มีข้อมูล'}
          </Detail>
        </BodySubLayout>
        <BodySubLayout>
          <Title>อัพเดทล่าสุด</Title>
          <Line />
          <DetailLayout>
            <Detail>
              {moment(get(props.current, 'updatedAt', ''))
                .locale('th')
                .format('LL') || 'ไม่มีข้อมูล'}
            </Detail>
            <DetailMini>
              (
              {moment(get(props.current, 'updatedAt', ''))
                .locale('th')
                .startOf('minute')
                .fromNow()}
              )
            </DetailMini>
          </DetailLayout>
        </BodySubLayout>
        <BodySubLayout>
          <Title>รายละเอียดของปัญหา</Title>
          <Line />
          <Detail>
            {get(props.current, 'description', 'ไม่มี') || 'ไม่มีข้อมูล'}
          </Detail>
        </BodySubLayout>
        <BodySubLayout>
          <Title>รูปภาพปัญหา</Title>
          <Line />
          <ImageLayout>
            {props.current.imageUrls
              ? props.current.imageUrls.map((item) => <Img src={item} alt="" />)
              : 'ไม่มีรูภาพ'}
          </ImageLayout>
        </BodySubLayout>
        <BodySubLayout>
          <TitleCustomer>ข้อความตอบกลับจากทีมงาน</TitleCustomer>
          <Line />
          <Detail>
            {get(props.current, 'answer', 'ไม่มี') || 'ไม่มีข้อมูล'}
          </Detail>
        </BodySubLayout>
      </MainBody>
    </ModalBody>
    <ModalFooter>
      <CustomButton color="secondary" onClick={props.setIsOpenFalse}>
        Cancel
      </CustomButton>
    </ModalFooter>
  </Modal>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchById: stores.issueStore.fetchById,
    current: stores.issueStore.current,
    configs: stores.issueStore.configs,
  })),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (props.open) {
        props.fetchById(props.issueId)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.issueId, props.open])

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(IssueModal)
