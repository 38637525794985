import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { Button, TitlePage } from '../../../components/index.js'
import { Panel, PanelHeader } from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import ModalAdd from '../new'
import ModalDetail from '../show'

const ButtonNew = styled(Button)`
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderMainLayout = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const NameLayout = styled.div`
  display: flex;
  align-items: center;
`
const CompanyLogo = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 4px;
`
const CompanyNameText = styled.div`
  margin-left: 6px;
`

const Company = (props) => (
  <>
    <TitlePage text="Company" textSmall="Can create, view and edit company." />
    <Panel>
      <PanelHeader noButton={true}>
        <HeaderMainLayout>
          <ButtonNew color="info" onClick={props.setIsOpenTrue}>
            New
          </ButtonNew>
          <TextHeader>Companies</TextHeader>
        </HeaderMainLayout>
      </PanelHeader>
      <ReactTable
        filterable
        data={props.all}
        columns={props.tableColumns}
        defaultPageSize={10}
        className="-highlight"
        getTdProps={props.onRowClick}
      />
      <ModalAdd
        open={props.openModalAdd}
        setIsOpenFalse={props.setIsOpenFalse}
      />
      <ModalDetail
        open={props.openModalDetail}
        setIsOpenFalse={props.setIsOpenModalDetailFalse}
        companyId={props.companyId}
      />
    </Panel>
  </>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.companyStore.fetch,
    all: stores.companyStore.all,
  })),
  withHooks((props) => {
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [openModalDetail, setOpenModalDetail] = useState(false)
    const [companyId, setCompanyId] = useState('')
    const tableColumns = [
      {
        Header: 'Company Name',
        accessor: 'name',
        Cell: (row) => (
          <NameLayout>
            <CompanyLogo src={row.row._original.logoUrl} alt="" />
            <CompanyNameText>{row.row._original.name} </CompanyNameText>
          </NameLayout>
        ),
      },
      {
        Header: 'Tqm Reference Name',
        accessor: 'tqmReferenceName',
      },
      {
        Header: 'Claim Phone Number',
        accessor: 'claimPhoneNumber',
      },
    ]

    useEffect(() => {
      props.fetch()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIsOpenFalse = useCallback(() => {
      setOpenModalAdd(false)
    }, [])

    const setIsOpenTrue = useCallback(() => {
      setOpenModalAdd(true)
    }, [])

    const setIsOpenModalDetailFalse = useCallback(() => {
      setOpenModalDetail(false)
    }, [])

    const setIsOpenModalDetailTrue = useCallback(() => {
      setOpenModalDetail(true)
    }, [])

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: (e) => {
          if (rowInfo) {
            setOpenModalDetail(true)
            setCompanyId(rowInfo.original.id)
          }
        },
      }
    }
    return {
      setIsOpenFalse,
      setIsOpenTrue,
      openModalAdd,
      onRowClick,
      tableColumns,
      openModalDetail,
      setIsOpenModalDetailFalse,
      setIsOpenModalDetailTrue,
      companyId,
    }
  })
)

export default enhancer(Company)
