import styled from '@emotion/styled'
import React from 'react'

const ContainerLoading = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background: rgba(255, 255, 255, 0.8);
`
const LoadingIcon = styled.div`
  font-size: 100px;
`
const Loading = ({ className, children, isLoading }) => (
  <>
    {isLoading && (
      <ContainerLoading className="loading-container">
        <LoadingIcon className="fas fa-spinner fa-pulse text-grey-darker" />
      </ContainerLoading>
    )}
    {children}
  </>
)

export default Loading
