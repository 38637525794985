import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import {
  Button,
  Image,
  Loading,
  TitlePage
} from '../../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../../enhancers/index.js'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const ImageLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`

const CustomImage = styled(Image)`
  width: 414px;
  height: 281.39px;
  margin-bottom: 10px;
  align-self: center;
  > section {
    width: 100% !important;
    height: 100% !important;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 0 0 220px 52%/70px !important;
      > i {
        margin: unset;
      }
      > div {
        > img {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 0 0 220px 52%/70px !important;
        }
      }
    }
  }
`

const AuthMenuBanner = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage text="App Config" textSmall="Can view and edit app config." />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Auth Menu Banner (2048x1200) (พยายามให้รูปพี่หมีอยู่กลางภาพจะได้ไม่โดน crop ออกถ้าดูในมือถือ)</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <ImageLayout>
            <Field name="bannerFile" component={CustomImage} />
          </ImageLayout>
        </PanelBody>
      </Panel>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
    </MainLayout>
  </Loading>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.otherStore.fetchAuthMenu,
    current: stores.otherStore.currentAuthMenu,
    update: stores.otherStore.updateAuthMenu,
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'editAuthMenuBannerConfig',
    validateOnBlur: false,
    validateOnChange: false,

    handleSubmit: async (values, props) => {
      props.props.setLoading(true)
      let newValue = { ...values }

      if (!values.bannerUrl) {
        newValue = { ...values, bannerUrl: undefined }
      }

      await props.props.update({
        others: {
          authMenuBanner: newValue,
        },
      })
      props.props.setLoading(false)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current.others,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    // const [loading, setLoading] = useState(true)
    const [openMenu, setOpenMenu] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      const promise = []

      const getIamge = async () => {
        if (props.current.others) {
          promise.push(
            fetch(props.current.others.bannerUrl)
              .then((r) => r.blob())
              .then(
                (blobFile) =>
                  new File([blobFile], 'fileFromServer', {
                    type: 'image/png',
                  })
              )
              .then((result) =>
                Object.assign(result, {
                  preview: URL.createObjectURL(result),
                })
              )
              .then((result) => {
                props.setFieldValue(`bannerFile`, result)
              })
          )

          await Promise.all(promise)
        }
        props.setLoading(false)
      }
      getIamge()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current])

    useEffect(() => {
      props.setLoading(true)
      props.fetch()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseMenu = useCallback(() => {
      setOpenMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueMenu = useCallback(() => {
      setOpenMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return {
      setOpenFalseMenu,
      setOpenTrueMenu,
      openMenu,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
    }
  })
)

export default enhancer(AuthMenuBanner)
