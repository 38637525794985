import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { get } from 'lodash'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { withRouter } from 'react-router-dom'
import Table from 'react-table-6'
import 'react-table/react-table.css'
import { compose, withState } from 'recompose'
import { Select } from '../../../components'
import Pagination from '../../../components/Pagination'
import { PageSettings } from '../../../config/page-settings'
import { withHooks, withStores } from '../../../enhancers/index.js'
import Filter from './Filter'

const MainLayout = styled(Form)`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 24px;
`

const TextHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
`

const PaginateLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`

const PageNumberLayout = styled.div`
  display: flex;
  align-items: center;
`
const PerPageNumberLayout = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
`
const PerPageText = styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  width: 120px;
  margin-bottom: 10px;
`

const CustomSelect = styled(Select)`
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  height: 36px;
  > div:first-of-type {
    width: 76px;
    height: 36px;
    > div:last-of-type {
      > span {
        /* display: none; */
      }
    }
  }

  > div:last-of-type {
    width: 76px;
  }
  margin: unset;
`

const CustomTable = styled(Table)`
  border-color: white !important;
  border-bottom: 1px solid #e6e8ec !important;

  .rt-table {
    ::-webkit-scrollbar-thumb {
      background: #b5b6bf;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #d8d9e0;
    }

    ::-webkit-scrollbar {
      height: 8px;
    }
  }

  .rt-resizer {
    display: none;
  }

  .rt-thead {
    border-top: 1px solid #e6e8ec;
    border-bottom: 1px solid #e6e8ec;
    > div {
      > div {
        display: flex;
        justify-content: flex-start;
        padding-right: 20px !important;
        ::after {
          display: none !important;
        }
        /* :nth-of-type(2)::after {
          display: flex !important;
        } */
        :nth-of-type(7)::after {
          display: flex !important;
        }
        color: #0d75d1;
        border: unset;
        border-color: white !important;
      }
    }
  }
  .rt-tbody {
    > div:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
    > :nth-of-type(odd) {
      background-color: #f2f2f2;
    }
    > :nth-of-type(even) {
      background-color: #ffffff;
    }
  }
  .rt-tr-group {
    border: hidden !important;
  }
  .rt-td {
    border: hidden !important;
  }
`

const TextVerified = styled.div`
  color: #9bcc55;
`

const TextNotVerified = styled.div`
  color: #f45a49;
`

const columns = [
  {
    Header: 'ID',
    accessor: 'id', // String-based value accessors!
    sortable: false,
    minWidth: 30,
    width: 50
  },
  // {
  //   Header: 'Code',
  //   accessor: 'code',
  // },
  // {
  //   Header: 'Category',
  //   accessor: 'category',
  //   sortable: false,
  // },
  // {
  //   Header: 'Privileges',
  //   accessor: 'privilege',
  //   sortable: false,
  //   width: 150,
  // },
  {
    Header: 'TQM USER ID',
    accessor: 'tqmUserId',
    sortable: false,
    width: 120,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    sortable: false,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    sortable: false,
  },
  {
    Header: 'Tel.',
    accessor: 'phoneNumber',
    sortable: false,
  },
  {
    Header: 'Segment',
    accessor: 'customerSegment',
    sortable: false,
  },
  {
    Header: 'Status',
    accessor: 'isCustomerVerify',
    sortable: false,
    Cell: (props) =>
      get(props, 'value', '') ? (
        <TextVerified>Verified</TextVerified>
      ) : (
        <TextNotVerified>Not Verified</TextNotVerified>
      ),
  },
  {
    Header: 'Consent Version',
    accessor: 'consentVersion',
    sortable: false,
    width: 140,
  },
  {
    Header: 'T&C Version',
    accessor: 'tAndCVersion',
    sortable: false,
  },
  {
    Header: 'Last Active Date',
    accessor: 'lastActiveAt',
    width: 150,
    sortMethod: (a) => a,
  },
  {
    Header: 'Created Date',
    accessor: 'createdAt',
    width: 150,
    sortMethod: (a) => a,
  },
  // {
  //   Header: 'Last Active',
  //   accessor: 'startAt',
  //   sortable: false,
  // },
  // {
  //   Header: 'IP Address',
  //   accessor: 'endAt',
  //   sortable: false,
  // },
]

const camelToSnake = (string) => {
  return string
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1]
    })
    .toLowerCase()
}

const UserReport = (props) => (
  <MainLayout onSubmit={props.handleSubmit}>
    <TextHeader>User Report</TextHeader>
    <Filter
      resetForm={props.resetForm}
      handleSubmit={props.handleSubmit}
      statusOptions={get(props, 'configs.status.options', [])}
      customerSegmentOptions={get(props, 'configs.customerSegment.options', [])}
      values={props.values}
      setSort={props.setSort}
      setLoading={props.setLoading}
      configs={props.configs}
    />
    <PaginateLayout>
      <PageNumberLayout>
        <Field
          name="page"
          component={Pagination}
          size=""
          pages={get(props, 'pagination.allPages', 0)}
          onClick={props.onPageChange}
          currentPage={props.values.page || 1}
        />
        <PerPageText>
          {get(props, 'pagination.allRecords', 0)} items found
        </PerPageText>
      </PageNumberLayout>
      <PerPageNumberLayout>
        <Field
          name="pageSize"
          component={CustomSelect}
          isClearable={false}
          resizable={false}
          menuPlacement="auto"
          onKeyDown={props.selectOnKeyDown}
          onChange={props.onChangePageSize}
          options={[
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
        />
        <PerPageText>items per page</PerPageText>
      </PerPageNumberLayout>
    </PaginateLayout>

    <CustomTable
      data={get(props, 'data', [])}
      columns={columns}
      showPagination={false}
      pageSize={get(props, 'data', []).length || 10}
      sorted={props.sort}
      loading={props.loading}
      onSortedChange={props.onSortedChange}
    />

    <PaginateLayout>
      <PageNumberLayout>
        <Field
          name="page"
          component={Pagination}
          size=""
          pages={get(props, 'pagination.allPages', 0)}
          onClick={props.onPageChange}
          currentPage={props.values.page || 1}
        />
      </PageNumberLayout>
      <PerPageNumberLayout>
        <Field
          name="pageSize"
          component={CustomSelect}
          isClearable={false}
          resizable={false}
          menuPlacement="auto"
          onKeyDown={props.selectOnKeyDown}
          onChange={props.onChangePageSize}
          options={[
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
        />
        <PerPageText>items per page</PerPageText>
      </PerPageNumberLayout>
    </PaginateLayout>
  </MainLayout>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.userReportStore.fetch,
    fetchConfig: stores.userReportStore.fetchConfig,
    configs: stores.userReportStore.configs,
    all: stores.userReportStore.all,
  })),
  withState('loading', 'setLoading', false),
  withFormik({
    displayName: 'filterUserReport',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      await props.props.setLoading(true)
      values.page = '1'
      await props.props.fetch({ ...values, page: '1' })
      await props.props.setLoading(false)
    },
    mapPropsToValues: (props) => {
      return { pageSize: '25', page: 1 }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const context = useContext(PageSettings)
    const [sort, setSort] = useState([])

    useEffect(() => {
      props.setLoading(true)
      props.fetch({ pageSize: '25', page: '1', sort: 'created_at DESC' })
      props.fetchConfig()
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const data = useMemo(() => {
      const newData = get(props, 'all.users', []).map((item, index) => {
        return {
          ...item,
          id:
            (props.values.page - 1 || 0) * (props.values.pageSize || 25) +
            (index + 1),
          // isCustomerVerify: get(item, 'isCustomerVerify', '')
          //   ? 'Verified'
          //   : 'Not Verified',
          tqmUserId: get(item, 'tqmUserId', ''),
          firstName: get(item, 'firstName', ''),
          lastName: get(item, 'lastName', ''),
          phoneNumber: get(item, 'phoneNumber', ''),
          email: get(item, 'email', ''),
          createdAt: moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
          lastActiveAt: item.lastActiveAt ? moment(item.lastActiveAt).format('DD/MM/YYYY HH:mm:ss') : null,
        }
      })

      props.setLoading(false)
      return newData
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.all])

    const selectOnKeyDown = useCallback((e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        return false
      }
    }, [])

    const onSortedChange = useCallback(
      async (item) => {
        setSort(item)
        await props.setLoading(true)

        if (item[0].id === 'createdAt') {
          await props.fetch({
            ...props.values,
            sort: `${camelToSnake(item[0].id)} ${
              item[0].desc ? 'DESC' : 'ASC'
            }`,
          })

          await props.setFieldValue(
            'sort',
            `${camelToSnake(item[0].id)} ${item[0].desc ? 'DESC' : 'ASC'}`
          )
        }

        await props.setLoading(false)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.values]
    )

    const onPageChange = useCallback(
      (val) => {
        props.setLoading(true)
        props.fetch({
          ...props.values,
          pageSize: props.values.pageSize || 25,
          page: val,
        })
        props.setFieldValue('page', val)
      },
      [props]
    )

    const onChangePageSize = useCallback(
      (value) => {
        props.setLoading(true)
        if (value) {
          props.setFieldValue('pageSize', value.value)
          props.fetch({ ...props.values, pageSize: value.value, page: '1' })
        } else {
          props.setFieldValue('pageSize', value)
          props.fetch({ ...props.values, pageSize: value, page: '1' })
        }
        props.setFieldValue('page', '1')
      },
      [props]
    )

    return {
      data,
      pagination: get(props, 'all.pagination', ''),
      sort,
      setSort,
      selectOnKeyDown,
      onSortedChange,
      onPageChange,
      onChangePageSize,
    }
  })
)

export default enhancer(UserReport)
