import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose, withState } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  CKEditor as Cke,
  ErrorMessageAlert, Image, Input,
  Loading,
  Select,
  TitlePage
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import ButtonStatus from './ButtonStatus'
import UploadImage from './UploadImage'

const MainLayout = styled.form``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const CustomInput = styled(Input)`
  width: 200px;
`

const CustomSelect = styled(Select)`
  width: 200px;
`

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`
const Caption = styled.div`
  min-width: 180px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`
const RowLayout = styled.div`
  display: flex;
`
const CkEgitorGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-right: 6px;
`
const CustomImage = styled(Image)`
  width: 70px;
  height: 50px;
  align-self: center;
  > section {
    width: 100% !important;
    height: 100% !important;
    background-color: gray;
    border-radius: 3px;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: unset !important;
      > div {
        > div {
          display: none;
        }
        > i {
          margin: unset;
          font-size: 25px !important;
        }
      }

      > img {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`
const BackgroundImgContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`
const BackgroundImgCaption = styled.label`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
`

const VirtualCard = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage
      text="Virtual Card"
      textSmall="Can create, view and edit virtual card."
    />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Virtual Card</TextHeader>
            <HeaderRightGruop>
              <Field name="isActive" component={ButtonStatus} />
              <ButtonGroupHeader>
                <ButtonHeader
                  color="primary"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Save
                </ButtonHeader>
                {!props.isNew && (
                  <ButtonHeader
                    color="danger"
                    type="button"
                    onClick={props.setConfirmDeleteToCon}
                  >
                    Delete
                  </ButtonHeader>
                )}
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <Field
            name="insuranceCompanyId"
            component={CustomSelect}
            caption="Company Name"
            options={get(props, 'configs.companies.options', [])}
          />
          <Field
            name="productType"
            component={CustomSelect}
            caption="Type"
            options={get(props, 'configs.type.options', [])}
          />
          <Field
            name="name"
            component={CustomInput}
            caption="Card Name"
            required
          />
          <BackgroundImgContainer>
            <BackgroundImgCaption>Front Card</BackgroundImgCaption>
            <Field name="frontCard" component={CustomImage} />
          </BackgroundImgContainer>
          <BackgroundImgContainer>
            <BackgroundImgCaption>Back Card</BackgroundImgCaption>
            <Field name="backCard" component={CustomImage} />
          </BackgroundImgContainer>
          <UploadImage
            setLoading={props.setLoading}
            setValue={props.setFieldValue}
          />
          <RowLayout>
            <CkEgitorGroup>
              <Caption>Front Card Template (630x400)</Caption>
              <Field name="templateHtmlFront" component={Cke} height={440} />
            </CkEgitorGroup>
            <CkEgitorGroup>
              <Caption>Back Card Template (630x400)</Caption>
              <Field name="templateHtmlBack" component={Cke} height={440} />
            </CkEgitorGroup>
          </RowLayout>
        </PanelBody>
      </Panel>
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการลบใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmDelete}
          onCancel={props.setConfirmDeleteToCon}
        />
      )}
    </MainLayout>
  </Loading>
)
export const ValidateSchema = yup.object().shape({
  name: yup.string().required('Name is Required'),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchById: stores.virtualCardStore.fetchById,
    current: stores.virtualCardStore.current,
    update: stores.virtualCardStore.update,
    fetchConfig: stores.virtualCardStore.fetchConfig,
    configs: stores.virtualCardStore.configs,
    delete: stores.virtualCardStore.delete,
  })),
  withState('loading', 'setLoading', true),
  withFormik({
    displayName: 'editVirtualCard',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.match.params.id, values)
    },
    mapPropsToValues: (props) => {
      return {
        ...props.current,
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (props.current) {
        const promise = []

        const getImage = async () => {
          const frontCardUrl = get(props, 'current.frontCardUrl', null)
          if (frontCardUrl) {
            promise.push(
              fetch(frontCardUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue('frontCard', result)
                })
            )
          }

          const backCardUrl = get(props, 'current.backCardUrl', null)
          if (backCardUrl) {
            promise.push(
              fetch(backCardUrl)
                .then((r) => r.blob())
                .then(
                  (blobFile) =>
                    new File([blobFile], 'fileFromServer', {
                      type: 'image/png',
                    })
                )
                .then((result) =>
                  Object.assign(result, {
                    preview: URL.createObjectURL(result),
                  })
                )
                .then((result) => {
                  props.setFieldValue('backCard', result)
                })
            )
          }

          await Promise.all(promise)
        }

        getImage()
      }
    }, [props.current])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    const setConfirmDeleteToCon = useCallback(() => {
      setConfirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onClickconfirmDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push('/virtual_card')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    return {
      loading,
      setLoading,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      onClickconfirmDelete,
      setConfirmDeleteToCon,
      confirmDelete,
    }
  })
)

export default enhancer(VirtualCard)
