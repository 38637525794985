import { findKey } from 'lodash'
import { isClient } from './common/helper'

const config = {
  production: {
    name: 'Production',
    host: 'https://tqm24-backoffice.tqm.co.th',
    apiEndpoint: 'https://tqm24-api.tqm.co.th',
    oldSiteRoot: 'https://backoffice-prod.loyalty.tqld.xyz',
  },
  uat: {
    name: 'UAT',
    host: 'https://tqm24-backoffice-uat.tqm.co.th',
    apiEndpoint: 'https://tqm24-api-uat.tqm.co.th',
    oldSiteRoot: 'https://backoffice-uat.loyalty.tqld.xyz',
  },
  develop: {
    name: 'Develop',
    host: 'https://backoffice-dev.tqm-24.tqld.xyz',
    apiEndpoint: 'https://api-dev.tqm-24.tqld.xyz',
    oldSiteRoot: 'https://backoffice-dev.loyalty.tqld.xyz',
  },
  local: {
    name: 'Local',
    apiEndpoint: 'https://api-dev.tqm-24.tqld.xyz',
    oldSiteRoot: 'https://backoffice-dev.loyalty.tqld.xyz',
    // apiEndpoint: 'http://localhost:3007',
    // oldSiteRoot: 'http://localhost:8080',
  },
}

const getEnvByHost = () =>
  isClient
    ? findKey(config, {
        host: window.location.origin,
      })
    : null

export const env = getEnvByHost() || process.env.APP_ENV || 'local'

export default config[env]
