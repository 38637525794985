import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { Input } from '../../components/index.js'
import { PageSettings } from '../../config/page-settings.js'
import { withHooks, withStores } from '../../enhancers/index.js'

const RegisterSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is Required'),
})
const CustomInput = styled(Input)`
  height: 43px;
  font-size: 0.875rem;
`

const ButtonGroup = styled.div`
  display: flex;
`

const ButtonConfirm = styled.button`
  height: 43px;
  width: 70%;
`

const ButtonBack = styled.button`
  height: 43px;
  width: 30%;
  margin-right: 5px;
`

const Forgot = (props) => (
  <div className="login login-v1">
    <div className="login-container">
      <div className="login-header">
        <div className="brand">
          {/* <span className="logo"></span>  */}
          <b>TQM - 24</b> Forgotten
          <small>the most complete app management system</small>
        </div>
        <div className="icon">
          <i className="fa fa-lock"></i>
        </div>
      </div>
      <div className="login-body">
        <div className="login-content">
          <form className="margin-bottom-0" onSubmit={props.handleSubmit}>
            <div className="form-group m-b-20">
              <Field
                name="email"
                component={CustomInput}
                type="text"
                placeholder="Email Address"
                required
              />
            </div>
            <ButtonGroup>
              <ButtonBack
                type="button"
                className="btn btn-primary"
                disabled={props.loading}
                onClick={props.goToLoginPage}
              >
                Back
              </ButtonBack>
              <ButtonConfirm
                type="submit"
                className="btn btn-success"
                disabled={props.loading}
              >
                Confirm
              </ButtonConfirm>
            </ButtonGroup>
          </form>
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    forgot: stores.appStore.forgot,
  })),
  withFormik({
    displayName: 'forgotAccount',
    validationSchema: RegisterSchema,
    handleSubmit: async (values, props) => {
      values = { ...values, referenceKey: uuidv4() }
      await props.props.forgot(values)
      // props.props.history.push('/login')
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const context = useContext(PageSettings)

    useEffect(() => {
      context.handleSetPageSidebar(false)
      context.handleSetPageHeader(false)
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageSidebar(true)
        context.handleSetPageHeader(true)
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToLoginPage = useCallback(() => {
      props.history.push('/login')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading, goToLoginPage }
  })
)

export default enhancer(Forgot)
