import styled from '@emotion/styled'
import { get } from 'lodash'
import * as moment from 'moment'
import 'moment/locale/th'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { TitlePage } from '../../../components/index.js'
import { Panel, PanelHeader } from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import ModalDetail from '../show/index'
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderMainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`

const Issue = (props) => (
  <>
    <TitlePage text="Issue" textSmall="Can view issue from user" />
    <Panel>
      <PanelHeader noButton={true}>
        <HeaderMainLayout>
          <TextHeader>Issue</TextHeader>
        </HeaderMainLayout>
      </PanelHeader>
      <ReactTable
        filterable
        data={props.all}
        columns={props.tableColumns}
        defaultPageSize={10}
        className="-highlight"
        getTdProps={props.onRowClick}
      />
      <ModalDetail
        open={props.openModalDetail}
        setIsOpenFalse={props.setIsOpenModalDetailFalse}
        issueId={props.issueId}
      />
    </Panel>
  </>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.issueStore.fetch,
    fetchConfig: stores.issueStore.fetchConfig,
    all: stores.issueStore.all,
    configs: stores.issueStore.configs,
  })),
  withHooks((props) => {
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [openModalDetail, setOpenModalDetail] = useState(false)
    const [issueId, setIssueId] = useState('')

    const tableColumns = [
      { Header: 'Issue No', accessor: 'issueNo' },
      {
        Header: 'Issue Type',
        accessor: 'issueType',
        Cell: (row) => (
          <>
            {
              (
                get(props.configs, 'attributes.issueType.options', []).find(
                  (item) => item.value === row.row._original.issueType
                ) || {}
              ).label
            }
          </>
        ),
      },
      {
        Header: 'Device',
        accessor: 'deviceOs',
      },
      {
        Header: 'tel',
        accessor: 'tel',
      },
      {
        Header: 'Create at',
        accessor: 'createdAt',
        Cell: (row) => (
          <>{moment(row.row._original.createdAt).locale('th').format('LL')}</>
        ),
      },
      {
        Header: 'update at',
        accessor: 'updatedAt',
        Cell: (row) => (
          <>{moment(row.row._original.updatedAt).locale('th').format('LL')}</>
        ),
      },
      {
        Header: 'status',
        accessor: 'status',
      },
    ]

    useEffect(() => {
      props.fetch()
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIsOpenFalse = useCallback(() => {
      setOpenModalAdd(false)
    }, [])

    const setIsOpenTrue = useCallback(() => {
      setOpenModalAdd(true)
    }, [])

    const setIsOpenModalDetailFalse = useCallback(() => {
      setOpenModalDetail(false)
    }, [])

    const setIsOpenModalDetailTrue = useCallback(() => {
      setOpenModalDetail(true)
    }, [])

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: (e) => {
          if (rowInfo) {
            setOpenModalDetail(true)
            setIssueId(rowInfo.original.id)
          }
        },
      }
    }
    return {
      setIsOpenFalse,
      setIsOpenTrue,
      openModalAdd,
      onRowClick,
      tableColumns,
      openModalDetail,
      setIsOpenModalDetailFalse,
      setIsOpenModalDetailTrue,
      issueId,
    }
  })
)

export default enhancer(Issue)
