import styled from '@emotion/styled'
import { cloneDeep, get, remove } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { Button } from 'reactstrap'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers/index.js'

const MainLayout = styled.div`
  display: flex;
  justify-content: center;
  margin: unset;
  width: 100%;
  height: 996px;
  margin-top: 20px;
`
const TableMainLayout = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
`
const TableHeaderMainLayout = styled.div`
  background: #1d2226;
  width: 100%;
  height: fit-content;
`
const TextHeader = styled.div`
  font-size: 18px;
  width: 100%;
  height: 50px;
  font-weight: 700;
  color: #c8d1d7;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FilterHeaderLayout = styled.div`
  display: flex;
  padding: 15px;
`
const SelectCaption = styled.div`
  color: #c8d1d7;
  margin-bottom: 5px;
`
const SelectLayout = styled.div`
  width: 100%;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
`
const TableBodyMainLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 850px;
  overflow-y: scroll;
`
const TableBodySubLayout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const TextValue = styled.div`
  text-align: center;
`
const ButtonAdd = styled(Button)`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-content: center;
`
const ButtonAddAll = styled(Button)`
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-content: center;
`
const IconButtonAdd = styled.i``

const Table = (props) => (
  <TableMainLayout>
    <TableHeaderMainLayout>
      <TextHeader>{props.textHeader}</TextHeader>
      <FilterHeaderLayout>
        <SelectLayout>
          <SelectCaption className="text-silver-lighter m-b-5">
            User
          </SelectCaption>
          <Select
            name={`${props.name}.user`}
            options={props.userOptions}
            onChange={(e) => {
              props.setUserOption(e)
            }}
            isClearable
          />
        </SelectLayout>
        {/* <SelectLayout>
          <SelectCaption className="text-silver-lighter m-b-5">
            Operating System
          </SelectCaption>
          <Select name={`${props.name}.os`} options={props.osOptions} />
        </SelectLayout> */}
      </FilterHeaderLayout>

      {!props.readOnly && (
        <FilterHeaderLayout>
          <ButtonAddAll
            color={props.color}
            type="button"
            onClick={props.clickAll}
          >
            Selected All
          </ButtonAddAll>
        </FilterHeaderLayout>
      )}
    </TableHeaderMainLayout>
    <TableBodyMainLayout>
      {get(props, 'itemShow', []).map((item) => {
        const value = props.deviceOptions.find((x) => x.value === item)
        return (
          <TableBodySubLayout key={`${props.textHeader}${value.value}`}>
            {props.textHeader === 'Remaining Device' && (
              <>
                <div />
                <TextValue>{value.label}</TextValue>
                {!props.readOnly && (
                  <ButtonAdd
                    color="info"
                    type="button"
                    onClick={props.addOrRemove(item)}
                  >
                    <IconButtonAdd className="fas fa-arrow-alt-circle-right fa-flip-vertical" />
                  </ButtonAdd>
                )}
                {props.readOnly && <div />}
              </>
            )}

            {props.textHeader === 'Selected Device' && (
              <>
                {!props.readOnly && (
                  <ButtonAdd
                    color="danger"
                    type="button"
                    onClick={props.addOrRemove(item)}
                  >
                    <IconButtonAdd className="fas fa-arrow-alt-circle-right fa-rotate-180" />
                  </ButtonAdd>
                )}
                {props.readOnly && <div />}
                <TextValue>{value.label}</TextValue>
                <div />
              </>
            )}
          </TableBodySubLayout>
        )
      })}
    </TableBodyMainLayout>
  </TableMainLayout>
)

const Device = (props) => (
  <MainLayout>
    <Table
      textHeader="Selected Device"
      name="selected"
      userOptions={props.selectedUserOption}
      setUserOption={props.setSelectedUserFilter}
      itemShow={props.selectedShow}
      deviceOptions={get(props, 'configs.attributes.deivises.options', [])}
      addOrRemove={props.removeIndex}
      clickAll={props.removeAll}
      readOnly={props.readOnly}
      color="danger"
    />
  </MainLayout>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({})),

  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [remainingUserFilter, setRemainingUserFilter] = useState('')
    const [selectedUserFilter, setSelectedUserFilter] = useState('')

    const remainingValues = useMemo(() => {
      let values = cloneDeep(
        get(props, 'configs.attributes.deivises.options', [])
      )
      let formValue = get(props, 'form.values.deviseIds', [])
      values = values.filter((item) => {
        let check = false
        if (!formValue.includes(item.value)) {
          check = true
        }
        return check
      })

      return values.map((item) => item.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values.deviseIds, props.configs])

    const remainingShow = useMemo(() => {
      let newValue = remainingValues.map((item) => {
        const object = get(
          props,
          'configs.attributes.deivises.options',
          []
        ).find((x) => x.value === item)

        return object
      })
      if (remainingUserFilter) {
        newValue = newValue.filter((item) => {
          let check = false

          if (item.userId === remainingUserFilter.value) {
            check = true
          }

          return check
        })
      }

      return newValue.map((item) => item.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values, remainingValues, remainingUserFilter])

    const remainingUserOption = useMemo(() => {
      let options = cloneDeep(
        get(props, 'configs.attributes.userIds.options', [])
      )
      let newValue = remainingValues
        .map((item) => {
          const object = get(
            props,
            'configs.attributes.deivises.options',
            []
          ).find((x) => x.value === item)

          return object
        })
        .map((item) => item.userId)

      options = options.filter((item) => {
        let check = false
        if (newValue.includes(item.value)) {
          check = true
        }
        return check
      })

      return options

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingValues, props.configs])

    const selectedShow = useMemo(() => {
      let newValue = []
      if (get(props, 'configs.attributes.deivises.options', '')) {
        newValue = get(props, 'field.value', []).map((item) => {
          const object = get(
            props,
            'configs.attributes.deivises.options',
            []
          ).find((x) => x.value === item)

          return object
        })
        if (selectedUserFilter) {
          newValue = newValue.filter((item) => {
            let check = false

            if (item.userId === selectedUserFilter.value) {
              check = true
            }

            return check
          })
        }

        newValue = newValue.map((item) => item.value)
      }
      return newValue
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values, selectedUserFilter, props.configs])

    const selectedUserOption = useMemo(() => {
      let options = cloneDeep(
        get(props, 'configs.attributes.userIds.options', [])
      )
      if (get(props, 'configs.attributes.deivises.options', '')) {
        let newValue = get(props, 'field.value', [])
          .map((item) => {
            const object = get(
              props,
              'configs.attributes.deivises.options',
              []
            ).find((x) => x.value === item)

            return object
          })
          .map((item) => item.userId)

        options = options.filter((item) => {
          let check = false
          if (newValue.includes(item.value)) {
            check = true
          }
          return check
        })
      }

      return options

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values, props.configs])

    const add = useCallback(
      (values) => () => {
        let newValues = [values, ...get(props, 'form.values.deviseIds', [])]
        props.form.setFieldValue('deviseIds', newValues)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props]
    )

    const addAll = useCallback(
      () => {
        let newValues = [
          ...remainingShow,
          ...get(props, 'form.values.deviseIds', []),
        ]
        props.form.setFieldValue('deviseIds', newValues)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props, remainingShow]
    )

    const removeIndex = useCallback(
      (value) => () => {
        let newValues = [...get(props, 'form.values.deviseIds', [])]

        remove(newValues, (item) => {
          return item === value
        })

        props.form.setFieldValue('deviseIds', newValues)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.field.value]
    )

    const removeAll = useCallback(
      () => {
        let newValues = [...get(props, 'form.values.deviseIds', [])]

        selectedShow.forEach((value) => {
          remove(newValues, (item) => {
            return item === value
          })
        })

        props.form.setFieldValue('deviseIds', newValues)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.field.value, selectedShow]
    )

    return {
      loading,
      setLoading,
      isEdit,
      setIsEdit,
      remainingValues,
      remainingUserFilter,
      remainingUserOption,
      setRemainingUserFilter,
      remainingShow,
      add,
      setSelectedUserFilter,
      selectedUserOption,
      selectedShow,
      removeIndex,
      addAll,
      removeAll,
    }
  })
)

export default enhancer(Device)
