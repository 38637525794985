import styled from '@emotion/styled'
import { Field } from 'formik'
import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { Button, Input, Select } from '../../../components/index'
import { withHooks, withStores } from '../../../enhancers/index.js'
import Datetime from './Datetime'

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  border: 1px solid #eff1f5;
`

const CustomInput = styled(Input)`
  height: 56px;
  border-radius: 4px;
  width: 100%;
`

const CustomSelect = styled(Select)`
  border-radius: 4px;
  width: 100%;
  background: red;
  > div:first-of-type {
    height: 56px;
    > div:last-of-type {
      > span {
        /* display: none; */
      }
    }
  }

  > :last-of-type {
    z-index: 2;
  }
`

const CustomDateTime = styled(Datetime)`
  > div {
    > div {
      > input {
        height: 56px;
      }
    }
  }
`

const InputLayout = styled.div`
  width: 24%;
  margin-right: 1%;
`

const DatepickerLayout = styled.div`
  width: 24%;
  margin-right: 1%;
`

const ButtonLayout = styled.div`
  width: 100%;
  margin-right: 1%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const CustomButton = styled(Button)`
  margin-left: 8px;
`

const Caption = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 8px;
  width: 150px;
`

const Filter = (props) => (
  <MainLayout>
    <InputLayout>
      <Caption>First Name</Caption>
      <Field name="firstName" component={CustomInput} placeholder="Search" />
    </InputLayout>
    <InputLayout>
      <Caption>Last Name</Caption>
      <Field name="lastName" component={CustomInput} placeholder="Search" />
    </InputLayout>
    <InputLayout>
      <Caption>Tel.</Caption>
      <Field
        name="phoneNumber"
        component={CustomInput}
        options={props.categoryOptions}
        placeholder="Search"
        onKeyDown={props.selectOnKeyDown}
      />
    </InputLayout>
    <InputLayout>
      <Caption>Status</Caption>
      <Field
        name="status"
        component={CustomSelect}
        options={props.statusOptions}
        placeholder="Search"
        onKeyDown={props.selectOnKeyDown}
      />
    </InputLayout>    
    <DatepickerLayout>
      <Caption>Created Start Date</Caption>
      <Field
        name="createdStartDate"
        component={CustomDateTime}
        placeholder="Search"
      />
    </DatepickerLayout>
    <DatepickerLayout>
      <Caption>Created End Date</Caption>
      <Field
        name="createdEndDate"
        component={CustomDateTime}
        placeholder="Search"
      />
    </DatepickerLayout>
    <DatepickerLayout>
      <Caption>Last Active Start Date</Caption>
      <Field
        name="lastActiveStartDate"
        component={CustomDateTime}
        placeholder="Search"
      />
    </DatepickerLayout>
    <DatepickerLayout>
      <Caption>Last Active End Date</Caption>
      <Field
        name="lastActiveEndDate"
        component={CustomDateTime}
        placeholder="Search"
      />
    </DatepickerLayout>
    <InputLayout>
      <Caption>Customer Segment</Caption>
      <Field
        name="customerSegment"
        component={CustomSelect}
        options={props.customerSegmentOptions}
        placeholder="Search"
        onKeyDown={props.selectOnKeyDown}
      />
    </InputLayout>
    <ButtonLayout>
      <CustomButton color="primary" type="submit">
        <i className="fas fa-check" /> Submit
      </CustomButton>
      <CustomButton color="secondary" type="button" onClick={props.resetFilter}>
        <i className="fas fa-sync" /> Clear
      </CustomButton>
      <CustomButton color="success" type="button" onClick={props.exportExcel}>
        <i className="far fa-file-excel" />
      </CustomButton>
    </ButtonLayout>
  </MainLayout>
)

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetch: stores.userReportStore.fetch,
    exportExcel: stores.userReportStore.exportExcel,
  })),
  withHooks((props) => {
    const resetFilter = useCallback(async () => {
      await props.setLoading(true)
      await props.setSort([])
      await props.fetch({ pageSize: '25', page: '1', sort: 'created_at DESC' })
      await props.resetForm()
      await props.setLoading(false)
    }, [props])

    const exportExcel = useCallback(() => {
      props.exportExcel({
        ...props.values,
      })
    }, [props])

    const selectOnKeyDown = useCallback((e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        return false
      }
    }, [])

    return { resetFilter, exportExcel, selectOnKeyDown }
  })
)

export default enhancer(Filter)
