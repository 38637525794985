import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class AppConfigStore extends ModelStore {
  constructor() {
    super('appConfigStore', {
      all: [],
      current: [],
      configs: [],
    })
  }

  fetch = async () => {
    const res = await tqm24Server.get(`/app_configurations/dashboard`)
    this.$setState('current', res.data.data)
  }

  update = async (params) => {
    const res = await tqm24Server.put(`/app_configurations/dashboard`, params)
    this.$setState('current', res.data.data)
  }

  fetchConfig = async () => {
    const res = await tqm24Server.get(`/app_configurations/configs`)
    this.$setState('configs', res.data.data)
  }
}

export default new AppConfigStore()
