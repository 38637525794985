import { css } from '@emotion/core'
import styled from '@emotion/styled'
import classnames from 'classnames'
import { get } from 'lodash'
import React from 'react'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'

const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
`
export const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  padding-top: 8px;
`

const Input = (props) => (
  <MainLayout>
    {props.caption && <Caption>{props.caption}</Caption>}
    <textarea
      {...props.field}
      {...props}
      value={props.field.value || ''}
      className={classnames({
        'form-control': !props.readOnly,
        [props.className]: props.className,
        'form-control-plaintext': props.readOnly,
      })}
      style={{ ...props.style }}
      onKeyUp={(textarea) => {
        if (props.autoHeigth) {
          const el = document.querySelector(
            `textarea[name='${props.field.name}']`
          )
          let numberOfLineBreaks = (textarea.target.value.match(/\n/g) || [])
            .length

          // min-height + lines x line-height + padding + border
          let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2
          el.style.height = newHeight + 'px'
        }
      }}
    />
  </MainLayout>
)

const CustomInput = styled(Input)`
  ${(props) =>
    props.valid &&
    css`
      border-radius: 4px;
      border: 1px solid #dc3545 !important;
    `}
`

const enhancer = compose(
  withHooks((props) => ({
    valid:
      get(props.form.errors, props.field.name) && props.form.submitCount > 0,
  }))
)

export default enhancer(CustomInput)
