import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Input, Textarea } from '../../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader,
} from '../../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../../enhancers/index.js'
import Device from './Device'

const MainLayout = styled.form``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  text-align: center;
  padding-top: 10px;
`

const CustomTextArea = styled(Textarea)``

export const Notification = (props) => (
  <MainLayout onSubmit={props.handleSubmit}>
    <Panel>
      <PanelHeader noButton={true}>
        <MainHeader>
          <TextHeader className="text-center p-t-10">Notification</TextHeader>
        </MainHeader>
      </PanelHeader>
      <PanelBody>
        <Field
          name="message"
          component={CustomTextArea}
          caption="Message"
          readOnly={props.isEdit}
        />
        <Field
          name="sendStatus"
          component={Input}
          caption="Send Status"
          readOnly={props.isEdit}
        />
        <Field
          name="sendTime"
          component={Input}
          caption="Send Time"
          readOnly={props.isEdit}
        />
        <Field
          name="sendType"
          component={Input}
          caption="Send Tpye"
          readOnly={props.isEdit}
        />
        <Field
          name="deviseIds"
          component={Device}
          readOnly={props.isEdit}
          configs={props.configs}
        />
      </PanelBody>
    </Panel>
    {props.confirmSend && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title=""
        onConfirm={props.send}
        onCancel={props.onClickSend}
      >
        ต้องการส่งข้อความใช่หรือไม่ ?
      </SweetAlert>
    )}
  </MainLayout>
)

// export const ValidateSchema = yup.object().shape({
//   name: yup.string().required('Name is Required'),
// })

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchById: stores.notificationStore.fetchHistoryById,
    current: stores.notificationStore.history,
    update: stores.notificationStore.update,
    fetchConfig: stores.notificationStore.fetchConfig,
    configs: stores.notificationStore.configs,
    send: stores.notificationStore.send,
  })),
  withFormik({
    displayName: 'editNotification',
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.match.params.id, values)
    },
    mapPropsToValues: (props) => {
      return { ...props.current }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [confirmSend, setConfirmSend] = useState(false)

    useEffect(() => {
      setIsEdit(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current])

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIsEditFalse = useCallback(() => {
      setIsEdit(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit])

    const setIsEditTrue = useCallback(() => {
      setIsEdit(true)
      props.resetForm()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit])

    const onClickSend = useCallback(() => {
      setConfirmSend(!confirmSend)
    }, [confirmSend])

    const send = useCallback(() => {
      const configsLength = props.configs.attributes.deivises.options.length
      const valueDeviceLength = props.values.deviseIds.length
      let sendType = 'something'

      if (configsLength === valueDeviceLength) {
        sendType = 'boscast'
      }
      props.send(props.match.params.id, { ...props.values, sendType: sendType })
      setConfirmSend(!confirmSend)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values, confirmSend, props.configs])

    return {
      loading,
      setLoading,
      isEdit,
      setIsEdit,
      setIsEditFalse,
      setIsEditTrue,
      confirmSend,
      onClickSend,
      send,
    }
  })
)

export default enhancer(Notification)
