import styled from '@emotion/styled'
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

CustomPagination.defaultProps = {
  size: 'sm',
  pages: 1,
  onClick: () => {},
}

const CustomPaginationItem = styled(PaginationItem)`
  margin-left: unset !important;
`

export default function CustomPagination(props) {
  let counter = 1
  const linkActive = (val) => props.currentPage == val
  const nextPage = () => props.currentPage + 1
  const prevPage = () => props.currentPage - 1
  const prevItemDisable = () => props.currentPage == 1
  const nextItemDisable = () => props.currentPage == props.pages

  const renderLink = (val, previous = false, next = false) => {
    if (previous) {
      return (
        <PaginationLink
          first
          href="javascript:;"
          onClick={props.onClick.bind(this, val)}
        />
      )
    } else if (next) {
      return (
        <PaginationLink
          last
          href="javascript:;"
          onClick={props.onClick.bind(this, val)}
        />
      )
    } else {
      return (
        <PaginationLink
          href="javascript:;"
          onClick={props.onClick.bind(this, val)}
        >
          {val}
        </PaginationLink>
      )
    }
  }

  const renderItem = (page) => {
    if (page == '...') {
      return (
        <CustomPaginationItem key={`dots${counter++}`} disabled={true}>
          <PaginationLink href="javascript:;">{page}</PaginationLink>
        </CustomPaginationItem>
      )
    } else {
      return (
        <CustomPaginationItem key={page} active={linkActive(page)}>
          {renderLink(page)}
        </CustomPaginationItem>
      )
    }
  }

  const renderItems = (pages) => {
    const items = pages.map((p) => renderItem(p))

    items.push(
      <CustomPaginationItem
        key={`${nextPage()}>>`}
        disabled={nextItemDisable()}
      >
        {renderLink(nextPage(), false, true)}
      </CustomPaginationItem>
    )

    items.unshift(
      <CustomPaginationItem
        key={`<<${prevPage()}`}
        disabled={prevItemDisable()}
      >
        {renderLink(prevPage(), true)}
      </CustomPaginationItem>
    )

    return items
  }

  const ranges = range_with_dots(props.currentPage, props.pages)

  return <Pagination size={props.size}>{renderItems(ranges)}</Pagination>
}

const range_with_dots = (currentPage, nrOfPages) => {
  var delta = 6,
    range = [],
    rangeWithDots = [],
    l

  range.push(1)

  if (nrOfPages <= 1) {
    return range
  }

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i < nrOfPages && i > 1) {
      range.push(i)
    }
  }
  range.push(nrOfPages)

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}
