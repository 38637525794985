import styled from '@emotion/styled'
import { Field, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as yup from 'yup'
import {
  Button,
  CheckboxGroup,
  ErrorMessageAlert,
  Input,
  TitlePage,
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader,
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'

const MainLayout = styled.form``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const ButtonGroupHeader = styled.div``

const CustomInput = styled(Input)`
  width: 100%;
`
const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`

const Admin = (props) => (
  <>
    <TitlePage text="Admin" textSmall="Super admin can manage admin" />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Admin</TextHeader>
            <ButtonGroupHeader loading={props.is}>
              <ButtonHeader
                color="primary"
                type="button"
                onClick={props.setConfirmUpdateToCon}
                loading={props.isSubmitting}
              >
                Save
              </ButtonHeader>
              {get(props.info, 'roles', []).includes('super_admin') &&
                !props.isNew && (
                  <ButtonHeader
                    color="danger"
                    type="button"
                    onClick={props.setConfirmDeleteToCon}
                    loading={props.isSubmitting}
                  >
                    Delete
                  </ButtonHeader>
                )}
            </ButtonGroupHeader>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <Field
            name="email"
            component={CustomInput}
            caption="Email"
            readOnly
          />
          {get(props.info, 'roles', []).includes('super_admin') && (
            <Field
              name="roles"
              component={CheckboxGroup}
              caption="Role"
              options={[
                { label: 'Super Admin', value: 'super_admin' },
                { label: 'Admin', value: 'admin' },
              ]}
              required
            />
          )}
        </PanelBody>
      </Panel>
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการบันทึกใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="ต้องการลบใช่หรือไม่ ?"
          onConfirm={props.onClickconfirmDelete}
          onCancel={props.setConfirmDeleteToCon}
        />
      )}
    </MainLayout>
  </>
)

const ValidateSchema = yup.object().shape({
  email: yup.mixed().required(),
  roles: yup.string().required(),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchById: stores.adminStore.fetchById,
    current: stores.adminStore.current,
    update: stores.adminStore.update,
    delete: stores.adminStore.delete,
    info: stores.adminStore.info,
  })),
  withFormik({
    displayName: 'editAdmin',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.match.params.id, values)
    },
    mapPropsToValues: (props) => {
      return { ...props.current }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
      props.fetchById(props.match.params.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    const setConfirmDeleteToCon = useCallback(() => {
      setConfirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onClickconfirmDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push('/admins')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    return {
      loading,
      setLoading,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      onClickconfirmDelete,
      setConfirmDeleteToCon,
      confirmDelete,
    }
  })
)

export default enhancer(Admin)
