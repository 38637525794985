import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class AdminStore extends ModelStore {
  constructor() {
    super('adminStore', {
      all: [],
      current: [],
      info: [],
    })
  }

  fetch = async (params) => {
    const res = await tqm24Server.get('/admins')
    this.$setState('all', res.data.data.admins)
  }

  fetchById = async (params) => {
    const res = await tqm24Server.get(`/admins/${params}`)
    this.$setState('current', res.data.data.admin)
  }

  update = async (id, params) => {
    const res = await tqm24Server.put(`/admins/${id}`, params)
    this.$setState('current', res.data.data.admin)
  }

  fetchInfo = async () => {
    const res = await tqm24Server.get(`/admins/info`)
    this.$setState('info', res.data.data.admin)
  }

  delete = async (id) => {
    await tqm24Server.delete(`/admins/${id}`)
  }
}

export default new AdminStore()
