import styled from '@emotion/styled'
import moment from 'moment'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DateTime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import { compose } from 'recompose'
import { Button } from '.'
import { withHooks } from '../enhancers'
const MainLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`
export const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const RequiredStar = styled.div`
  color: red;
`

const CustomDateTime = styled(DateTime)`
  width: 100%;
  > input {
    height: 40px !important;
    border-radius: 4px;
  }
`
const ButtonNow = styled(Button)`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InputLayout = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  border: unset;
`
const IconLayout = styled.div`
  margin-right: 13px;
`

const RenderView = ({ mode, renderDefault, ...props }) => {
  return (
    <div className="wrapper">
      <ButtonNow
        onClick={() => props.setFieldValue(props.name, moment())}
        color="outline-primary"
      >
        Now
      </ButtonNow>
    </div>
  )
}

const Date = (props) => (
  <MainLayout>
    {props.caption && (
      <Caption>
        {props.caption}
        {props.required && <RequiredStar>*</RequiredStar>}
      </Caption>
    )}
    <CustomDateTime
      {...props}
      {...props.field}
      onChange={(e) => {
        if (typeof e === 'object' && e.isAfter(moment())) {
          props.form.setFieldValue(props.field.name, e._d)
        } else {
          props.form.setFieldValue(props.field.name, e)
        }
      }}
      onClose={(e) => {
        if (typeof e === 'object' && e.isAfter(moment())) {
          props.form.setFieldValue(props.field.name, e._d)
        } else {
          props.form.setFieldValue(props.field.name, moment())
        }
      }}
      isValidDate={(e) => {
        return e.isAfter(moment().subtract(1, 'day'))
      }}
      renderInput={(inputProp, openCalendar, closeCalendar) => (
        <InputLayout onClick={openCalendar}>
          <Input {...inputProp} />
          <IconLayout>
            <i className="far fa-calendar" />
          </IconLayout>
        </InputLayout>
      )}
      renderView={(mode, renderDefault) => (
        <>
          {renderDefault()}
          <RenderView
            setFieldValue={props.form.setFieldValue}
            name={props.field.name}
          />
        </>
      )}
    />
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    useEffect(() => {
      if (!props.field.value) {
        props.form.setFieldValue(props.field.name, moment())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {}
  })
)

export default enhancer(Date)
