import styled from '@emotion/styled'
import { Field, Form, withFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as yup from 'yup'
import {
  Button, CheckboxGroup, DateTime,
  ErrorMessageAlert,
  Image,
  Input,
  Loading,
  Select,
  Textarea,
  TitlePage
} from '../../../components/index.js'
import {
  Panel,
  PanelBody,
  PanelHeader
} from '../../../components/panel/panel.jsx'
import { withHooks, withStores } from '../../../enhancers/index.js'
import MultiTextInput from './MultiTextInput.js'
import IconSelect from './Select'

const MainLayout = styled(Form)``

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextHeader = styled.div`
  display: flex;
  align-items: center;
`
const HeaderRightGruop = styled.div`
  display: flex;
`

const ButtonGroupHeader = styled.div``

const ButtonHeader = styled(Button)`
  width: 60px;
  margin-left: 10px;
`
const BodyMainLayout = styled.div`
  display: flex;
  width: 100%;
`
const BodyLeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`
const BodyRightLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 440px;
  background: rgba(0, 0, 0, 0.04);
  padding: 24px 24px 16px 24px;
  margin-left: 12px;
  text-align: center;
`
const InputTextAreaLayout = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`

const Caption = styled.div`
  min-width: 120px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`
const RequiredStar = styled.div`
  color: red;
`
const CustomImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;

  > section {
    width: 100% !important;
    height: 100% !important;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        > i {
          font-size: 25px;
          margin: unset;
        }
        > div {
          display: none;
        }
        > img {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
`
const AndroidMainLayout = styled.div`
  position: relative;
  display: flex;
  background-image: url('/android.png');
  background-repeat: no-repeat;
  background-position: top;
  height: 100%;
  width: 100%;
`
const IOSMainLayout = styled.div`
  position: relative;
  display: flex;
  background-image: url('/long_ios.png');
  background-repeat: no-repeat;
  background-position: top;
  height: 100%;
  width: 100%;
  margin-top: 24px;
`
const TextOs = styled.div`
  margin-top: 24px;
  font-size: 13px;
`
const AndroidMessageCard = styled.div`
  width: 100%;
  height: 56px;
  background: white;
  position: absolute;
  top: 70px;
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  padding: 8px;
`
const IOSMessageCard = styled.div`
  width: 100%;
  height: 56px;
  background: white;
  position: absolute;
  top: 62px;
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 8px;
  display: flex;
  padding: 8px;
`

const MessageMainLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`

const MessageTitle = styled.div`
  font-size: 10px;
  text-align: left;
`

const MessageDescription = styled.div`
  font-size: 10px;
  text-overflow: ellipsis;
  text-align: left;
  white-space: pre;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
`
const PreviewImage = styled.img`
  width: 40px;
  height: 40px;
`
const CustomTextArea = styled(Textarea)`
  overflow: hidden;
`

const GeneratePayloadParam = (props) => {
  switch (props.page) {
    case 'loyalty_landing':
      return (
        <Field
          name={`${props.name}.categoryId`}
          options={get(props.configs, 'attributes.categories.options')}
          caption="Category Id"
          component={Select}
        />
      )
    case 'loyalty_detail':
      return (
        <Field
          name={`${props.name}.id`}
          options={get(props.configs, 'attributes.loyalties.options')}
          component={Select}
          caption="Loyalty Id"
          required
        />
      )
    case 'web_view':
      return (
        <Field
          name={`${props.name}.url`}
          component={Input}
          caption="URL"
          required
        />
      )
    case 'claim_info':
      return (
        <Field
          name={`${props.name}.referenceId`}
          component={Input}
          caption="Reference Id"
          required
        />
      )

    default:
      return null
  }
}

const GenerateFormByNotificationType = (props) => {
  switch (props.notificationType) {
    case 'phone_number':
      return(
        <Field
          name="phoneNumbers"
          component={MultiTextInput}
          caption="Phone numbers"
          required
        />
      )
    case 'customer_segment':
      return(
        <Field
          name="customerSegments"
          component={CheckboxGroup}
          caption="Customer Segment"
          options={get(props.configs, 'attributes.customerSegment.options', [])}
          required
        />
      )
    default:
      return null
  }
}

const Notification = (props) => (
  <Loading isLoading={props.loading}>
    <TitlePage
      text="Notification Schedule"
      textSmall="Can send notification to mobile"
    />
    <MainLayout onSubmit={props.handleSubmit}>
      <Panel>
        <PanelHeader noButton={true}>
          <MainHeader>
            <TextHeader>Notification Schedule</TextHeader>
            <HeaderRightGruop>
              <ButtonGroupHeader>
                <ButtonHeader
                  color="warning"
                  type="button"
                  onClick={props.setConfirmUpdateToCon}
                  loading={props.isSubmitting}
                >
                  Send
                </ButtonHeader>
              </ButtonGroupHeader>
            </HeaderRightGruop>
          </MainHeader>
        </PanelHeader>
        <PanelBody>
          <BodyMainLayout>
            <BodyLeftLayout>
              <Field
                name="notificationCategoryId"
                caption="Category"
                component={Select}
                onChange={props.onChangeCategory}
                options={get(props.configs, 'attributes.categories.options')}
                required
              />
              <Field
                name="title"
                caption="Title"
                component={Select}
                options={props.titleOptions}
                onChange={props.onChangeTitle}
                required
              />
              <InputTextAreaLayout>
                <Caption>
                  Description (ไม่เกิน 1000 ตัวอักษร)
                  <RequiredStar>*</RequiredStar>
                </Caption>
                <Field
                  name="description"
                  component={CustomTextArea}
                  autoHeigth
                  maxlength="1000"
                />
              </InputTextAreaLayout>
              <Field
                name="action.payload.page"
                component={Select}
                caption="page"
                options={get(props.configs, 'attributes.page.options', [])}
                required
              />
              <GeneratePayloadParam
                name="action.payload"
                page={get(props.values, 'action.payload.page', '')}
                configs={props.configs}
              />
              <Field
                name="platform"
                component={IconSelect}
                caption="Platform"
                options={[
                  {
                    label: 'Android',
                    value: 'android',
                    icon: 'fab fa-android',
                    color: '#00bfa5',
                  },
                  {
                    label: 'IOS',
                    value: 'ios',
                    icon: 'fab fa-apple',
                    color: '#00b8d4',
                  },
                  {
                    label: 'All',
                    value: 'all',
                    icon: 'fas fa-globe-asia',
                    color: '#5AC8FA',
                  },
                ]}
                required
              />
              <Field
                name="notificationType"
                component={IconSelect}
                caption="Notification type"
                options={[
                  
                  {
                    label: 'Phone Number',
                    value: 'phone_number',
                  },
                  {
                    label: 'Customer Segment',
                    value: 'customer_segment',
                  },
                ]}
                onChange={props.onChangeNotificationType}
                required
              />
              <GenerateFormByNotificationType
                notificationType={props.notificationType}
                configs={props.configs}
              />
              <Field
                name="scheduleTime"
                component={DateTime}
                caption="Schedule Time"
                required
              />
              <Field name="image" component={CustomImage} />
            </BodyLeftLayout>
            <BodyRightLayout>
              <AndroidMainLayout>
                <AndroidMessageCard>
                  <MessageMainLayout>
                    <MessageTitle>
                      {get(props, 'values.title', '')}
                    </MessageTitle>
                    <MessageDescription>
                      {get(props, 'values.description', '')}
                    </MessageDescription>
                  </MessageMainLayout>
                  <PreviewImage
                    src={get(props, 'values.image.preview', '/TQM24.png')}
                    alt=""
                  />
                </AndroidMessageCard>
              </AndroidMainLayout>
              <TextOs>Android</TextOs>
              <IOSMainLayout>
                <IOSMessageCard>
                  <MessageMainLayout>
                    <MessageTitle>
                      {get(props, 'values.title', '')}
                    </MessageTitle>
                    <MessageDescription>
                      {get(props, 'values.description', '')}
                    </MessageDescription>
                  </MessageMainLayout>
                  <PreviewImage
                    src={get(props, 'values.image.preview', '/TQM24.png')}
                    alt=""
                  />
                </IOSMessageCard>
              </IOSMainLayout>

              <TextOs>iOS</TextOs>
            </BodyRightLayout>
          </BodyMainLayout>
        </PanelBody>
      </Panel>
      <ErrorMessageAlert
        open={
          !props.isSubmitting &&
          !props.isValid &&
          !isEmpty(props.errors) &&
          !props.isValidating
        }
        message={props.errors}
      />
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText=" Send "
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Really ?"
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        />
      )}
    </MainLayout>
  </Loading>
)
export const ValidateSchema = yup.object().shape({
  notificationCategoryId: yup.string().required('category is a required'),
  title: yup.string().required(),
  description: yup.string().required(),
  platform: yup.string().required(),
  notificationType: yup.string().required(),
  phoneNumbers: yup.array().when('notificationType', {
    is: (notificationType) => notificationType === 'phone_number',
    then: yup.array().min(1).required('ขออภัย กรุณาระบุเบอร์โทรศัพท์')
  }),
  customerSegments: yup.array().when('notificationType', {
    is: (notificationType) => notificationType === 'customer_segment',
    then: yup.array().min(1).required('customer segment is required')
  }),
  scheduleTime: yup.date().required(),
  action: yup.object().shape({
    payload: yup
      .object()
      .shape({
        page: yup.string().required(),
        url: yup.mixed().when('page', {
          is: (value) => value === 'web_view',
          then: yup.string().required(),
        }),
        id: yup.mixed().when('page', {
          is: (value) => value === 'loyalty_detail',
          then: yup.string().required(),
        }),
        referenceId: yup.mixed().when('page', {
          is: (value) => value === 'claim_info',
          then: yup.string().required(),
        }),
      })
      .required(),
  }),
})

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchConfig: stores.notificationScheduleStore.fetchConfig,
    configs: stores.notificationScheduleStore.configs,
    create: stores.notificationScheduleStore.create,
  })),
  withFormik({
    displayName: 'editNotificationSchedule',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      const action = {
        type: 'notification',
        payload: { ...values.action.payload },
      }
      
      if (values.notificationType === 'phone_number') {
        // remove customerSegments key
        delete values.customerSegments
      } else if (values.notificationType === 'customer_segment') {
        // remove phoneNumbers key
        delete values.phoneNumbers
      }
      await props.props.create({ ...values, action: action })
      props.props.history.push('/report/notification_report')
    },
    mapPropsToValues: (props) => {
      return {
        action: {
          payload: { page: 'none' },
        },
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [openMenu, setOpenMenu] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [titleOptions, setTitleOptions] = useState([])
    const [notificationType, setNotificationType] = useState('')

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenFalseMenu = useCallback(() => {
      setOpenMenu(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setOpenTrueMenu = useCallback(() => {
      setOpenMenu(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const onClickconfirmUpdate = useCallback(async () => {
      await props.handleSubmit()
      setConfirmUpdate(false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    const onChangeCategory = useCallback(
      (value) => {
        props.setFieldValue('description', undefined)
        props.setFieldValue('title', undefined)
        if (value) {
          props.setFieldValue('notificationCategoryId', value.value)
          const titleOptions = get(
            props.configs,
            'attributes.categories.options',
            []
          ).find((item) => item.value === value.value).titleOptions
          setTitleOptions(titleOptions)
        } else {
          setTitleOptions([])
          props.setFieldValue('notificationCategoryId', value)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.configs]
    )

    const onChangeTitle = useCallback(
      (value) => {
        props.setFieldValue('description', undefined)
        if (value) {
          props.setFieldValue('title', value.value)
          const description = titleOptions.find(
            (item) => item.value === value.value
          ).description

          props.setFieldValue('description', description)
        } else {
          props.setFieldValue('title', value)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [titleOptions]
    )

    const onChangeNotificationType = useCallback(
      (value) => {
        setNotificationType(value?.value)
        props.setFieldValue('notificationType', value?.value)
      },
      [notificationType]
    )

    return {
      setOpenFalseMenu,
      setOpenTrueMenu,
      openMenu,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      titleOptions,
      onChangeCategory,
      onChangeTitle,
      onChangeNotificationType,
      notificationType,
    }
  })
)

export default enhancer(Notification)
