import { withFormik } from 'formik'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers/index.js'
import { Notification } from '../show/index'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    fetchConfig: stores.notificationStore.fetchConfig,
    configs: stores.notificationStore.configs,
    create: stores.notificationStore.create,
  })),
  withFormik({
    displayName: 'newNotification',
    validateOnBlur: false,
    validateOnChange: false,

    handleSubmit: async (values, props) => {
      await props.props.create(values)
      props.props.history.push('/notification/config')
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      isEdit: false,
      isNew: true,
    }
  })
)

export default enhancer(Notification)
