import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { get } from 'lodash'
import React from 'react'
import Select, { components } from 'react-select'
import { compose } from 'recompose'
import { withHooks } from '../../../enhancers/index.js'

const { Option, SingleValue } = components

const OptionMainLyout = styled.div`
  display: flex;
  align-items: center;
`
const Icon = styled.i`
  font-size: 18px;
  margin-right: 6px;
  color: ${(props) => props.color};
`
const Caption = styled.div`
  min-width: 150px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
`

const RequiredStar = styled.div`
  color: red;
`

const IconOption = (props) => (
  <Option {...props}>
    <OptionMainLyout>
      <Icon className={props.data.icon} color={props.data.color} />
      <div>{props.data.label}</div>
    </OptionMainLyout>
  </Option>
)
const ValueOption = (props) => (
  <SingleValue {...props}>
    <OptionMainLyout>
      <Icon className={props.data.icon} color={props.data.color} />
      <div>{props.data.label}</div>
    </OptionMainLyout>
  </SingleValue>
)

const MainLayout = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

const CustomSelect = styled(Select)`
  width: 100%;

  > div:nth-of-type(1) {
    height: 35px;
  }

  ${(props) =>
    props.valid &&
    css`
      > div {
        border: 1px solid #dc3545 !important;
      }
    `}
`

const ReadOnly = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
`

const RenderSelectInput = (props) => {
  const { field, options } = props
  let value = null
  if (options) {
    value = options.find((x) => x.value === field.value)
  }

  return (
    <MainLayout>
      {props.caption && (
        <Caption className="readOnly">
          {props.caption} {props.required && <RequiredStar>*</RequiredStar>}
        </Caption>
      )}
      {!props.readOnly && (
        <CustomSelect
          {...field}
          name={field.name}
          id={field.name}
          isSearchable
          required={props.required}
          value={value || ''}
          onChange={(value) => {
            if (value) {
              props.form.setFieldValue(field.name, value.value)
            } else {
              props.form.setFieldValue(field.name, value)
            }
          }}
          isClearable
          {...props}
          options={options}
          className={props.className}
          isDisabled={props.disabled}
          defaultValue={props.defaultValue}
          components={{ Option: IconOption, SingleValue: ValueOption }}
        />
      )}
      {props.readOnly && <ReadOnly>{get(value, 'label', '')}</ReadOnly>}
    </MainLayout>
  )
}

const enhancer = compose(
  withHooks((props) => ({
    valid:
      get(props.form.errors, props.field.name) && props.form.submitCount > 0,
  }))
)

export default enhancer(RenderSelectInput)
