import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class NotificationScheduleStore extends ModelStore {
  constructor() {
    super('notificationScheduleStore', {
      all: [],
      current: [],
      configs: [],
      histories: [],
      history: [],
      configFilters: [],
      categories: [],
      category: {},
      categoryConfigs: [],
      titles: [],
      title: {},
      titleConfigs: [],
    })
  }

  fetchConfig = async (params) => {
    const res = await tqm24Server.get('/notification_schedule/configs')
    this.$setState('configs', res.data.data.notificationSchedule)
  }

  fetchConfigFilter = async (params) => {
    const res = await tqm24Server.get('/notification_schedule/config_filter')
    this.$setState(
      'configFilters',
      res.data.data.notificationSchedule.attributes
    )
  }

  fetch = async (params) => {
    const res = await tqm24Server.get(
      '/notification_schedule/get_history',
      params
    )
    this.$setState('all', res.data.data.notificationSchedule)
  }

  fetchById = async (id) => {
    const res = await tqm24Server.get(`/notification_schedule/${id}`)
    this.$setState('current', res.data.data.notificationSchedule)
  }

  create = async (params) => {
    await tqm24Server.post(`/notification_schedule`, params)
  }

  send = async (id, params) => {
    await tqm24Server.post(`/notification_schedule/${id}`, params)
  }

  resetCurrentToNull = async (id, params) => {
    await this.$setState('current', [])
  }

  exportExcel = async (params) => {
    await tqm24Server.download(`/notification_schedule/excel`, params)
  }

  fetchCategory = async (params) => {
    const res = await tqm24Server.get('/notification_category', params)
    this.$setState('categories', res.data.data.notificationCategories)
  }

  createCategory = async (params) => {
    await tqm24Server.post('/notification_category', params)
  }

  fetchCategoryById = async (id) => {
    const res = await tqm24Server.get(`/notification_category/${id}`)
    this.$setState('category', res.data.data.notificationCategory)
  }

  updateCategory = async (id, params) => {
    const res = await tqm24Server.put(`/notification_category/${id}`, params)
    this.$setState('category', res.data.data.notificationCategory)
  }

  deleteCategory = async (id) => {
    await tqm24Server.delete(`/notification_category/${id}`)
  }

  fetchCategoryConfig = async (params) => {
    const res = await tqm24Server.get('/notification_category/config', params)
    this.$setState(
      'categoryConfigs',
      res.data.data.notificationCategories.attributes
    )
  }

  //====================================================================================================================

  fetchTitle = async (params) => {
    const res = await tqm24Server.get('/notification_title', params)
    this.$setState('titles', res.data.data.notificationTitles)
  }

  createTitle = async (params) => {
    await tqm24Server.post('/notification_title', params)
  }

  fetchTitleById = async (id) => {
    const res = await tqm24Server.get(`/notification_title/${id}`)
    this.$setState('title', res.data.data.notificationTitles)
  }

  updateTitle = async (id, params) => {
    const res = await tqm24Server.put(`/notification_title/${id}`, params)
    this.$setState('title', res.data.data.notificationTitles)
  }

  deleteTitle = async (id) => {
    await tqm24Server.delete(`/notification_title/${id}`)
  }

  fetchTitleConfig = async (params) => {
    const res = await tqm24Server.get('/notification_title/config', params)
    this.$setState('titleConfigs', res.data.data.notificationTitles.attributes)
  }

  exportExcelTitle = async (params) => {
    await tqm24Server.download(`/notification_title/excel`, params)
  }
}

export default new NotificationScheduleStore()
