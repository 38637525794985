import styled from '@emotion/styled'
import { Field } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { store } from 'react-notifications-component'
import { compose } from 'recompose'
import { withHooks } from '../../../enhancers'
import Image from './Image'

const SubLayout = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const CustomImage = styled(Image)`
  width: 70px;
  height: 50px;
  align-self: center;
  > section {
    width: 100% !important;
    height: 100% !important;
    background-color: gray;
    border-radius: 3px;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: unset !important;
      > div {
        > div {
          display: none;
        }
        > i {
          margin: unset;
          font-size: 25px !important;
        }
      }

      > img {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`
const IconTrash = styled.i`
  font-size: 16px;
  cursor: pointer;
  align-self: center;
  margin-left: 6px;
  margin-right: 6px;
`
const IconCopy = styled.i`
  font-size: 16px;
  align-self: center;
  margin-left: 6px;
  cursor: pointer;
`
const UrlText = styled.div`
  margin-left: 6px;
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  white-space: nowrap;
`

const ImageLayout = (props) => (
  <SubLayout>
    <Field
      name={`${props.name}[${props.index}].imageFile`}
      component={CustomImage}
      handleSubmit={props.form.handleSubmit}
      push={props.push}
      nameFromIndex={`${props.name}[${props.index}]`}
    />

    {props.index + 1 !== get(props.form, 'values.images.length', 0) &&
      get(props.form, 'values.images.length', 0) !== 1 && (
        <>
          <CopyToClipboard
            onCopy={props.copy}
            text={get(props.current, `imageUrls[${props.index}]`, '')}
          >
            <IconCopy className="fas fa-clone" />
          </CopyToClipboard>
          <UrlText>
            {get(props.current, `imageUrls[${props.index}]`, '')}
          </UrlText>
          <IconTrash
            className="fas fa-trash-alt"
            onClick={props.onClickDelete}
          />
        </>
      )}

    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Confirm"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={props.onDelete}
        onCancel={props.onClickDelete}
      >
        ต้องการลบใช่หรือไม่ ?
      </SweetAlert>
    )}
  </SubLayout>
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    const onClickDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(async () => {
      props.form.setFieldValue(`${props.name}[${props.index}].action`, 'delete')
      await props.form.handleSubmit()

      setconFirmDelete(false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.index])

    const copy = useCallback(() => {
      store.addNotification({
        title: 'Success!',
        message: 'copy success',
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 1000,
          onScreen: true,
        },
      })
    }, [])

    return {
      loading,
      setLoading,
      confirmDelete,
      onClickDelete,
      onDelete,
      copy,
    }
  })
)

export default enhancer(ImageLayout)
