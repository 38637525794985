import { withFormik } from 'formik'
import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../enhancers/index.js'
import { Company, ValidateSchema } from '../show/index'

const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    current: stores.companyStore.current,
    create: stores.companyStore.create,
    fetch: stores.companyStore.fetch,
  })),
  withFormik({
    displayName: 'newCompany',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      await props.props.create(values)
      await props.props.fetch()
      await props.props.setIsOpenFalse()
      props.resetForm()
    },
    mapPropsToValues: (props) => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
      isEdit: false,
      isNew: true,
    }
  })
)

export default enhancer(Company)
