import styled from '@emotion/styled'
import { Field } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import { compose } from 'recompose'
import { Input, MultiSelect, Select } from '../../components/index.js'
import { withHooks } from '../../enhancers/index.js'

const CustomSelect = styled(Select)`
  width: 100%;
`
const InputLayout = styled.div`
  display: flex;
  justify-items: center;
`
const Caption = styled.div`
  min-width: 120px;
  color: #666666;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`
const RequiredStar = styled.div`
  color: red;
`

const GeneratePayload = (props) => {
  switch (props.type) {
    case 'navigate':
      return (
        <InputLayout>
          <Caption>
            Page
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field
            name={`${props.name}.page`}
            component={Select}
            options={get(props.configs, 'dashboard.attributes.page.options')}
          />
        </InputLayout>
      )
    case 'open_app':
      return (
        <>
          <InputLayout>
            <Caption>
              Deeplink
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field name={`${props.name}.deeplink`} component={Input} />
          </InputLayout>
          <InputLayout>
            <Caption>
              App Name
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field name={`${props.name}.appName`} component={Input} />
          </InputLayout>
          <InputLayout>
            <Caption>
              App Store Id
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field name={`${props.name}.appStoreId`} component={Input} />
          </InputLayout>
          <InputLayout>
            <Caption>
              Play Store Id
              <RequiredStar>*</RequiredStar>
            </Caption>
            <Field name={`${props.name}.playStoreId`} component={Input} />
          </InputLayout>
        </>
      )
    case 'open_external_url':
      return (
        <InputLayout>
          <Caption>
            Url
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field
            name={`${props.name}.externalUrl`}
            component={Input}
          />
        </InputLayout>
      ) 

    default:
      return null
  }
}

const GeneratePayloadParam = (props) => {
  switch (props.page) {
    case 'loyalty_landing':
      return (
        <InputLayout>
          <Caption>
            Category
          </Caption>
          <Field
            name={`${props.name}.categoryId`}
            options={get(
              props.configs,
              'dashboard.attributes.categories.options'
            )}
            component={Select}
          />
        </InputLayout>
      )
    case 'loyalty_detail':
      return (
        <InputLayout>
          <Caption>
            Loyalty
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field
            name={`${props.name}.id`}
            options={get(
              props.configs,
              'dashboard.attributes.loyalties.options'
            )}
            component={Select}
            required
          />
        </InputLayout>
      )
    case 'web_view':
      return (
        <InputLayout>
          <Caption>
            URL
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name={`${props.name}.url`} component={Input} required />
        </InputLayout>
      )
    case "claim_info":
      return (
        <InputLayout>
          <Caption>
            Reference Id
            <RequiredStar>*</RequiredStar>
          </Caption>
          <Field name={`${props.name}.referenceId`} component={Input} required />
        </InputLayout>
      )
    default:
      return null
  }
}

const Action = (props) => (
  <>
    <InputLayout>
      <Caption>
        Authorized
        {props.caption}
        <RequiredStar>*</RequiredStar>
      </Caption>
      <Field
        name={`${props.field.name}.authorizedLevel`}
        component={CustomSelect}
        options={get(
          props.configs,
          'dashboard.attributes.authorizedLevel.options'
        )}
        required
      />
    </InputLayout>
    <InputLayout>
      <Caption>
        Visibility
        {props.caption}
        <RequiredStar>*</RequiredStar>
      </Caption>
      <Field
        name={`${props.field.name}.visibilityLevels`}
        component={MultiSelect}
        options={get(
          props.configs,
          'dashboard.attributes.authorizedLevel.options'
        )}
        required
      />
    </InputLayout>

    <InputLayout>
      <Caption>
        Type
        {props.caption}
        <RequiredStar>*</RequiredStar>
      </Caption>
      <Field
        name={`${props.field.name}.type`}
        component={CustomSelect}
        options={get(props.configs, 'dashboard.attributes.type.options')}
        onChange={props.onChangeType}
      />
    </InputLayout>
    <GeneratePayload
      name={`${props.field.name}.payload`}
      type={get(props.field.value, 'type', '')}
      configs={props.configs}
    />
    <GeneratePayloadParam
      name={`${props.field.name}.payload`}
      page={get(props.field.value, 'payload.page', '')}
      configs={props.configs}
    />
  </>
)

const enhancer = compose(
  withHooks((props) => {
    const [loading, setLoading] = useState(false)

    const onChangeType = useCallback(
      (value) => {
        if (value) {
          props.form.setFieldValue(`${props.field.name}.type`, value.value)
          props.form.setFieldValue(`${props.field.name}.payload`, {})
        } else {
          props.form.setFieldValue(`${props.field.name}.type`, value)
        }
      },
      [props.field.name, props.form]
    )

    return {
      loading,
      setLoading,
      onChangeType,
    }
  })
)

export default enhancer(Action)
