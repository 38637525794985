import deepmerge from 'deepmerge'
import { connect } from 'react-redux'
import adminStore from '../stores/adminStore'
import appConfigStore from '../stores/appConfigStore'
import appStore from '../stores/appStore'
import companyStore from '../stores/companyStore'
import consentStore from '../stores/consentStore'
import iconCarStore from '../stores/iconCarStore'
import issueStore from '../stores/issueStore'
import loyaltyConfigStore from '../stores/loyaltyConfigStore'
import loyaltyReportStore from '../stores/loyaltyReportStore'
import notificationScheduleStore from '../stores/notificationScheduleStore'
import notificationStore from '../stores/notificationStore'
import otherStore from '../stores/otherStore'
import reportProblemConfigStore from '../stores/reportProblemConfigStore'
import specialEventStore from '../stores/specialEventStore'
import telemedicineStore from '../stores/telemedicineStore'
import termAndConditionStore from '../stores/termAndConditionStore'
import userReportStore from '../stores/userReportStore'
import userStore from '../stores/userStore'
import virtualCardStore from '../stores/virtualCardStore'

const storeMapping = {
  appStore,
  adminStore,
  userStore,
  companyStore,
  virtualCardStore,
  appConfigStore,
  loyaltyConfigStore,
  notificationStore,
  specialEventStore,
  otherStore,
  notificationScheduleStore,
  issueStore,
  iconCarStore,
  telemedicineStore,
  reportProblemConfigStore,
  loyaltyReportStore,
  userReportStore,
  consentStore,
  termAndConditionStore,
}

const withStores = (mapStoresToProps) =>
  connect((state) => {
    const stores = deepmerge(storeMapping, state.model)
    return mapStoresToProps(stores)
  })

export default withStores
