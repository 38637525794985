import { withFormik } from 'formik'
import { useCallback, useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../enhancers/index.js'
import { ModalAdd, ValidateSchema } from './ModalAddMenu'

const enhancer = compose(
  withFormik({
    displayName: 'editTopMenu',
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      let customPayload = values.action.payload
      if (values.action.payload.page === 'loyalty_landing') {
        customPayload = {
          page: values.action.payload.page,
          categoryId: values.action.payload.categoryId,
        }
      } else if (values.action.payload.page === 'profile') {
        customPayload = { page: values.action.payload.page }
      } else if (values.action.payload.page === 'loyalty_detail') {
        customPayload = {
          page: values.action.payload.page,
          id: values.action.payload.id,
        }
      } else if (values.action.payload.page === 'web_view') {
        customPayload = {
          page: values.action.payload.page,
          url: values.action.payload.url,
        }
      } else if (values.action.payload.page === 'claim_info') {
        customPayload = {
          page: values.action.payload.page,
          referenceId: values.action.payload.referenceId,
        }
      }
      props.props.setFieldValue(props.props.name, {
        ...values,
        action: { ...values.action, payload: customPayload },
      })
      props.props.setIsOpenFalse()
      props.resetForm()
    },
    mapPropsToValues: (props) => {
      return { ...props.item }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    const onClickDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(async () => {
      props.remove(props.index)
      props.setIsOpenFalse()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIsOpenFalse = useCallback(() => {
      props.setOpen(false)
      setconFirmDelete(false)
      props.resetForm()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeIndexArray = useCallback(
      async (e) => {
        await props.move(props.index, e.value)
        props.setIsOpenFalse()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      confirmDelete,
      onClickDelete,
      onDelete,
      setIsOpenFalse,
      isEdit: true,
      changeIndexArray,
    }
  })
)

export default enhancer(ModalAdd)
