import { tqm24Server } from '../common/api'
import ModelStore from './ModelStore'

class IconCarStore extends ModelStore {
  constructor() {
    super('iconCarStore', {
      all: [],
      current: [],
      configs: [],
    })
  }

  fetch = async () => {
    const res = await tqm24Server.get(`/app_configurations/icon`)
    this.$setState('current', res.data.data)
  }

  update = async (params) => {
    const res = await tqm24Server.put(`/app_configurations/icon`, params)
    this.$setState('current', res.data.data)
  }
}

export default new IconCarStore()
