import styled from '@emotion/styled'
import { FieldArray, withFormik } from 'formik'
import { cloneDeep, get } from 'lodash'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../enhancers'
import ImageLayout from './ImageLayout'

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
`

const UploadImage = (props) => (
  <MainLayout>
    <FieldArray
      name="images"
      render={(fieldArrayProps) => (
        <>
          {get(fieldArrayProps, 'form.values.images', []).map((item, index) => (
            <ImageLayout
              key={index}
              {...fieldArrayProps}
              item={item}
              index={index}
              current={props.current}
            />
          ))}
        </>
      )}
    />
  </MainLayout>
)
const enhancer = compose(
  withRouter,
  withStores((stores) => ({
    current: stores.virtualCardStore.current,
    update: stores.virtualCardStore.updateImage,
  })),

  withFormik({
    displayName: 'uploadImage',
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: ValidateSchema,
    handleSubmit: async (values, props) => {
      props.props.setLoading(true)
      const images = cloneDeep(get(values, 'images', []))
      images.pop()
      await props.props.update(props.props.match.params.id, { images: images })

      props.props.setLoading(false)
    },
    mapPropsToValues: (props) => {
      return {
        images: (props.current.imageUrls || []).concat(['']) || {
          images: [''],
        },
      }
    },
    enableReinitialize: true,
  }),
  withHooks((props) => {
    useEffect(() => {
      props.setLoading(true)
      const promise = []
      if (props.current) {
        const getIamge = async () => {
          await (get(props, 'current.imageUrls', []) || []).forEach(
            (item, index) => {
              promise.push(
                fetch(item)
                  .then((r) => r.blob())
                  .then(
                    (blobFile) =>
                      new File([blobFile], 'fileFromServer', {
                        type: 'image/png',
                      })
                  )
                  .then((result) =>
                    Object.assign(result, {
                      preview: URL.createObjectURL(result),
                    })
                  )
                  .then((result) => {
                    props.setFieldValue(`images[${index}].imageFile`, result)
                    props.setFieldValue(`images[${index}].imageUrl`, item)
                  })
              )
            }
          )

          await Promise.all(promise)
          props.setLoading(false)
        }
        getIamge()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.current])

    return {}
  })
)

export default enhancer(UploadImage)
